import uuid from "react-uuid"
import {
  GiGlassShot,
  GiPirateCaptain,
  GiBrandyBottle
} from "react-icons/gi"
import {
  FaGlassWhiskey
} from "react-icons/fa"
import {
  MdLiquor
} from "react-icons/md"
import {
  FaLemon
} from "react-icons/fa"

export const alko = [
  //Vodka
{
  type: "title",
  blockTitle: "Vodka",
  block: true,
  blockId: "VODKA_BLOCK_ID",
  icon: GiGlassShot,
  iconSize: "45px",
  item_uuid: uuid(),
  title: "Vodka",
  descr: "",
  volume: "",
  price: "",
  divideAfter: true,
  lastElement: false
},
{
  item_uuid: uuid(),
  title: "Absolut",
  descr: "40 %",
  volume: "0,04 l",
  price: "2,20 €",
  divideAfter: false,
  lastElement: false
},
/* {
  item_uuid: uuid(),
  title: "Absolut 100",
  descr: "50 %",
  volume: "0,04 l",
  price: "2,50 €",
  divideAfter: false,
  lastElement: false
}, */
{
  item_uuid: uuid(),
  title: "Absolut blackcurrant",
  descr: "40 %",
  volume: "0,04 l",
  price: "2,70 €",
  divideAfter: false,
  lastElement: false
},
{
  item_uuid: uuid(),
  title: "Absolut raspberry",
  descr: "40 %",
  volume: "0,04 l",
  price: "2,70 €",
  divideAfter: false,
  lastElement: false
},
{
  item_uuid: uuid(),
  title: "Absolut lemon",
  descr: "40 %",
  volume: "0,04 l",
  price: "2,70 €",
  divideAfter: false,
  lastElement: false
},
{
  item_uuid: uuid(),
  title: "Czechoslovakia vodka",
  descr: "40 %",
  volume: "0,04 l",
  price: "2,70 €",
  divideAfter: false,
  lastElement: false
},
{
  item_uuid: uuid(),
  title: "Belvedere",
  descr: "40 %",
  volume: "0,04 l",
  price: "5,50 €",
  divideAfter: false,
  lastElement: false
},
{
  item_uuid: uuid(),
  title: "Grey Goose Original",
  descr: "40 %",
  volume: "0,04 l",
  price: "6,50 €",
  divideAfter: true,
  lastElement: false
},
// RUM
{
  type: "title",
  blockTitle: "Rum",
  blockId: "RUM_BLOCK_ID",
  block: true,
  icon: GiPirateCaptain,
  iconSize: "45px",
  item_uuid: uuid(),
  title: "Rum",
  descr: "",
  volume: "",
  price: "",
  divideAfter: true,
  lastElement: false
},
{
  item_uuid: uuid(),
  title: "Brugal 1888",
  descr: "40 %",
  volume: "0,04 l",
  price: "5,90 €",
  divideAfter: false,
  lastElement: false
},
{
  item_uuid: uuid(),
  title: "Pirát XO Reserve",
  descr: "40 %",
  volume: "0,04 l",
  price: "4,50 €",
  divideAfter: false,
  lastElement: false
},
{
  item_uuid: uuid(),
  title: "Zacapa Centenario",
  descr: "(23y) 40 %",
  volume: "0,04 l",
  price: "6,50 €",
  divideAfter: false,
  lastElement: false
},
{
  item_uuid: uuid(),
  title: "Zacapa Centenario XO",
  descr: "40 %",
  volume: "0,04 l",
  price: "15,00 €",
  divideAfter: false,
  lastElement: false
},
{
  item_uuid: uuid(),
  title: "La Hechicera",
  descr: "40 %",
  volume: "0,04 l",
  price: "5,30 €",
  divideAfter: false,
  lastElement: false
},
{
  item_uuid: uuid(),
  title: "Bumbu Originál",
  descr: "40 %",
  volume: "0,04 l",
  price: "4,00 €",
  divideAfter: false,
  lastElement: false
},
{
  item_uuid: uuid(),
  title: "Bumbu XO",
  descr: "40 %",
  volume: "0,04 l",
  price: "4,50 €",
  divideAfter: false,
  lastElement: false
},
{
  item_uuid: uuid(),
  title: "Diplomático Reserva Exclusiva",
  descr: "(12y) 40 %",
  volume: "0,04 l",
  price: "5,00 €",
  divideAfter: false,
  lastElement: false
},
{
  item_uuid: uuid(),
  title: "Barcelo Imperial",
  descr: "38 %",
  volume: "0,04 l",
  price: "3,50 €",
  divideAfter: false,
  lastElement: false
},
/* {
  item_uuid: uuid(),
  title: "Old Bert Spiced rum",
  descr: "40 %",
  volume: "0,04 l",
  price: "2,90 €",
  divideAfter: false,
  lastElement: false
},
{
  item_uuid: uuid(),
  title: "Sailor Jerry",
  descr: "40 %",
  volume: "0,04 l",
  price: "3,00 €",
  divideAfter: false,
  lastElement: false
}, */
{
  item_uuid: uuid(),
  title: "Legendario Elixir de Cuba",
  descr: "34 %",
  volume: "0,04 l",
  price: "2,70 €",
  divideAfter: false,
  lastElement: false
},
{
  item_uuid: uuid(),
  title: "Božkov Republica",
  descr: "38 %",
  volume: "0,04 l",
  price: "2,20 €",
  divideAfter: false,
  lastElement: false
},
/* {
  item_uuid: uuid(),
  title: "Božkov Solera",
  descr: "38 %",
  volume: "0,04 l",
  price: "2,00 €",
  divideAfter: false,
  lastElement: false
}, */
{
  item_uuid: uuid(),
  title: "Božkov Republica Reserva",
  descr: "40 %",
  volume: "0,04 l",
  price: "2,70 €",
  divideAfter: false,
  lastElement: false
},
/* {
  item_uuid: uuid(),
  title: "Božkov Republica Espresso",
  descr: "35 %",
  volume: "0,04 l",
  price: "1,90 €",
  divideAfter: false,
  lastElement: false
}, */
{
  item_uuid: uuid(),
  title: "Espero Caribbean Orange",
  descr: "40 %",
  volume: "0,04 l",
  price: "3,00 €",
  divideAfter: false,
  lastElement: false
},
{
  item_uuid: uuid(),
  title: "Espero Coconut / Coffee",
  descr: "40 %",
  volume: "0,04 l",
  price: "3,00 €",
  divideAfter: true,
  lastElement: false
},
/* {
  item_uuid: uuid(),
  title: "Espero Coffee & Rum",
  descr: "40 %",
  volume: "0,04 l",
  price: "2,90 €",
  divideAfter: true,
  lastElement: false
}, */
/* {
  item_uuid: uuid(),
  title: "Captain Morgan",
  descr: "35 %",
  volume: "0,04 l",
  price: "1,80 €",
  divideAfter: false,
  lastElement: false
},
{
  item_uuid: uuid(),
  title: "Havana club",
  descr: "40 %",
  volume: "0,04 l",
  price: "2,00 €",
  divideAfter: false,
  lastElement: false
},
{
  item_uuid: uuid(),
  title: "Havana club blanco",
  descr: "40 %",
  volume: "0,04 l",
  price: "2,00 €",
  divideAfter: true,
  lastElement: false
}, */
// WHISKEY
{
  type: "title",
  blockTitle: "Whiskey",
  blockId: "WHISKEY_BLOCK_ID",
  block: true,
  icon: FaGlassWhiskey,
  iconSize: "45px",
  item_uuid: uuid(),
  title: "Whiskey",
  descr: "",
  volume: "",
  price: "",
  divideAfter: true,
  lastElement: false
},
{
  item_uuid: uuid(),
  title: "Jameson",
  descr: "40 %",
  volume: "0,04 l",
  price: "2,70 €",
  divideAfter: false,
  lastElement: false
},
{
  item_uuid: uuid(),
  title: "Jameson Caskmates IPA Edition",
  descr: "40 %",
  volume: "0,04 l",
  price: "3,30 €",
  divideAfter: false,
  lastElement: false
},
{
  item_uuid: uuid(),
  title: "Jameson Caskmates Stout Edition",
  descr: "40 %",
  volume: "0,04 l",
  price: "3,30 €",
  divideAfter: false,
  lastElement: false
},
{
  item_uuid: uuid(),
  title: "Jameson Black Barrel",
  descr: "40 %",
  volume: "0,04 l",
  price: "4,00 €",
  divideAfter: false,
  lastElement: false
},
{
  item_uuid: uuid(),
  title: "Jack Daniel's Tennessee",
  descr: "35 %",
  volume: "0,04 l",
  price: "3,00 €",
  divideAfter: false,
  lastElement: false
},
{
  item_uuid: uuid(),
  title: "Jack Daniel's Honey",
  descr: "35 %",
  volume: "0,04 l",
  price: "3,00 €",
  divideAfter: false,
  lastElement: false
},
{
  item_uuid: uuid(),
  title: "Jack Daniel's Apple",
  descr: "35 %",
  volume: "0,04 l",
  price: "3,00 €",
  divideAfter: false,
  lastElement: false
},
{
  item_uuid: uuid(),
  title: "Jack Daniel's Fire",
  descr: "40 %",
  volume: "0,04 l",
  price: "3,00 €",
  divideAfter: false,
  lastElement: false
},
{
  item_uuid: uuid(),
  title: "Jack Daniel's Gentleman Jack",
  descr: "40 %",
  volume: "0,04 l",
  price: "3,70 €",
  divideAfter: false,
  lastElement: false
},
{
  item_uuid: uuid(),
  title: "Tullamore Dew",
  descr: "40 %",
  volume: "0,04 l",
  price: "2,70 €",
  divideAfter: false,
  lastElement: false
},
/* {
  item_uuid: uuid(),
  title: "Glenlivet Single Malt",
  descr: "(12y) 40 %",
  volume: "0,04 l",
  price: "4,20 €",
  divideAfter: false,
  lastElement: false
}, */
{
  item_uuid: uuid(),
  title: "Chivas Regal",
  descr: "40 %",
  volume: "0,04 l",
  price: "3,30 €",
  divideAfter: true,
  lastElement: false
},
/* {
  item_uuid: uuid(),
  title: "Hyde NO.6 Special Sherry cask",
  descr: "46 %",
  volume: "0,04 l",
  price: "5,50 €",
  divideAfter: false,
  lastElement: false
},
{
  item_uuid: uuid(),
  title: "Hyde cask Strenght Single Grain 8r.",
  descr: "59 %",
  volume: "0,04 l",
  price: "6,00 €",
  divideAfter: true,
  lastElement: false
}, */
// R. JELINEK DESTILATY
{
  type: "title",
  block: true,
  blockId: "JELINEK_BLOCK_ID",
  icon: GiGlassShot,
  iconSize: "45px",
  item_uuid: uuid(),
  title: "R. Jelínek Destiláty",
  blockTitle: "R. Jelínek",
  descr: "",
  volume: "",
  price: "",
  divideAfter: true,
  lastElement: false
},
{
  item_uuid: uuid(),
  title: "Slivovica Zlatá",
  descr: "50 %",
  volume: "0,04 l",
  price: "2,70 €",
  divideAfter: false,
  lastElement: false
},
{
  item_uuid: uuid(),
  title: "Hruškovica",
  descr: "42 %",
  volume: "0,04 l",
  price: "2,50 €",
  divideAfter: false,
  lastElement: false
},
{
  item_uuid: uuid(),
  title: "Marhuľovica",
  descr: "42 %",
  volume: "0,04 l",
  price: "2,80 €",
  divideAfter: false,
  lastElement: false
},
{
  item_uuid: uuid(),
  title: "Čerešňovica",
  descr: "42 %",
  volume: "0,04 l",
  price: "2,80 €",
  divideAfter: false,
  lastElement: false
},
{
  item_uuid: uuid(),
  title: "Malinovica",
  descr: "42 %",
  volume: "0,04 l",
  price: "3,10 €",
  divideAfter: false,
  lastElement: false
},
{
  item_uuid: uuid(),
  title: "Trnkovica",
  descr: "42 %",
  volume: "0,04 l",
  price: "2,70 €",
  divideAfter: false,
  lastElement: false
},
{
  item_uuid: uuid(),
  title: "Pivní pálenka",
  descr: "42 %",
  volume: "0,04 l",
  price: "2,70 €",
  divideAfter: false,
  lastElement: false
},
{
  item_uuid: uuid(),
  title: "Bazový elixír",
  descr: "14,7 %",
  volume: "0,04 l",
  price: "2,20 €",
  divideAfter: false,
  lastElement: false
},
{
  item_uuid: uuid(),
  title: "Oskorušovica",
  descr: "42 %",
  volume: "0,04 l",
  price: "5,50 €",
  divideAfter: true,
  lastElement: false
},
// COGNAC, BRANDY
{
  type: "title",
  block: true,
  blockId: "COGNAC_BLOCK_ID",
  icon: GiBrandyBottle,
  iconSize: "45px",
  item_uuid: uuid(),
  title: "Cognac a Brandy",
  blockTitle: "Cognac a Brandy",
  descr: "",
  volume: "",
  price: "",
  divideAfter: true,
  lastElement: false
},
{
  item_uuid: uuid(),
  title: "Pliska X.O.",
  descr: "40,0 %",
  volume: "0,04 l",
  price: "3,00 €",
  divideAfter: false,
  lastElement: false
},
{
  item_uuid: uuid(),
  title: "Karpatské Brandy Špeciál VSOP",
  descr: "40,0 %",
  volume: "0,04 l",
  price: "4,00 €",
  divideAfter: false,
  lastElement: false
},
{
  item_uuid: uuid(),
  title: "Karpatské Brandy",
  descr: "40,0 %",
  volume: "0,04 l",
  price: "2,00 €",
  divideAfter: false,
  lastElement: false
},
{
  item_uuid: uuid(),
  title: "Metaxa 5*",
  descr: "38,0 %",
  volume: "0,04 l",
  price: "2,50 €",
  divideAfter: false,
  lastElement: false
},
{
  item_uuid: uuid(),
  title: "Hennessy VS",
  descr: "40,0 %",
  volume: "0,04 l",
  price: "4,00 €",
  divideAfter: true,
  lastElement: false
},
// LIKERY
{
  type: "title",
  block: true,
  blockId: "LIKER_BLOCK_ID",
  icon: MdLiquor,
  iconSize: "45px",
  item_uuid: uuid(),
  title: "Likéry",
  blockTitle: "Likéry",
  descr: "",
  volume: "",
  price: "",
  divideAfter: true,
  lastElement: false
},
{
  item_uuid: uuid(),
  title: "Jägermeister",
  descr: "35,0 %",
  volume: "0,04 l",
  price: "2,50 €",
  divideAfter: false,
  lastElement: false
},
{
  item_uuid: uuid(),
  title: "Jägermeister Manifest",
  descr: "38,0 %",
  volume: "0,04 l",
  price: "3,60 €",
  divideAfter: false,
  lastElement: false
},
{
  item_uuid: uuid(),
  title: "Jägermeister Orange",
  descr: "33,0 %",
  volume: "0,04 l",
  price: "2,50 €",
  divideAfter: false,
  lastElement: false
},
{
  item_uuid: uuid(),
  title: "Fernet Stock / Citrus",
  descr: "38,0 % / 27,0 %",
  volume: "0,04 l",
  price: "2,20 €",
  divideAfter: false,
  lastElement: false
},
{
  item_uuid: uuid(),
  title: "Becherovka",
  descr: "38,0 %",
  volume: "0,04 l",
  price: "2,20 €",
  divideAfter: false,
  lastElement: false
},
/* {
  item_uuid: uuid(),
  title: "Becherovka nefiltrovaná",
  descr: "38,0 %",
  volume: "0,04 l",
  price: "2,10 €",
  divideAfter: false,
  lastElement: false
}, */
{
  item_uuid: uuid(),
  title: "Stará myslivecká",
  descr: "38,0 %",
  volume: "0,04 l",
  price: "2,00 €",
  divideAfter: false,
  lastElement: false
},
/* {
  item_uuid: uuid(),
  title: "Baileys Irish Cream",
  descr: "17,0 %",
  volume: "0,04 l",
  price: "2,20 €",
  divideAfter: false,
  lastElement: false
}, */
/* {
  item_uuid: uuid(),
  title: "Cointreau",
  descr: "40,0 %",
  volume: "0,04 l",
  price: "2,70 €",
  divideAfter: false,
  lastElement: false
}, */
{
  item_uuid: uuid(),
  title: "Tatranský čaj Coconut",
  descr: "22,0 %",
  volume: "0,04 l",
  price: "2,20 €",
  divideAfter: false,
  lastElement: false
},
{
  item_uuid: uuid(),
  title: "Tatranský čaj Hibiscus & Red Tea",
  descr: "37,0 %",
  volume: "0,04 l",
  price: "2,40 €",
  divideAfter: false,
  lastElement: false
},
{
  item_uuid: uuid(),
  title: "Tatranský čaj Flower",
  descr: "47,0 %",
  volume: "0,04 l",
  price: "2,40 €",
  divideAfter: false,
  lastElement: false
},
{
  item_uuid: uuid(),
  title: "Tatranský čaj",
  descr: "52,0 %",
  volume: "0,04 l",
  price: "2,70 €",
  divideAfter: false,
  lastElement: false
},
{
  item_uuid: uuid(),
  title: "Tatranský čaj Apple & Pear",
  descr: "67,0 %",
  volume: "0,04 l",
  price: "2,90 €",
  divideAfter: false,
  lastElement: false
},
{
  item_uuid: uuid(),
  title: "Tatranský čaj Forest Fruit",
  descr: "62,0 %",
  volume: "0,04 l",
  price: "2,90 €",
  divideAfter: true,
  lastElement: false
},
/* {
  item_uuid: uuid(),
  title: "Tatranský čaj Zbojnícky",
  descr: "72,0 %",
  volume: "0,04 l",
  price: "2,90 €",
  divideAfter: true,
  lastElement: false
}, */
//OSTATNE DESTILATY A LIEHOVINY
{
  type: "title",
  block: true,
  blockId: "OSTATNE_BLOCK_ID",
  icon: GiGlassShot,
  iconSize: "45px",
  item_uuid: uuid(),
  title: "Ostatné destiláty a liehoviny",
  blockTitle: "Ostatné",
  descr: "",
  volume: "",
  price: "",
  divideAfter: true,
  lastElement: false
},
{
  item_uuid: uuid(),
  title: "Absinth",
  descr: "70,0 %",
  volume: "0,04 l",
  price: "2,90 €",
  divideAfter: false,
  lastElement: false
},
{
  item_uuid: uuid(),
  title: "Borovička Borec",
  descr: "38,0 %",
  volume: "0,04 l",
  price: "2,00 €",
  divideAfter: false,
  lastElement: false
},
{
  item_uuid: uuid(),
  title: "Bošácka slivovica",
  descr: "52,0 %",
  volume: "0,04 l",
  price: "2,70 €",
  divideAfter: false,
  lastElement: false
},
{
  item_uuid: uuid(),
  title: "Tequila Olmeca Silver",
  descr: "35,0 %",
  volume: "0,04 l",
  price: "3,30 €",
  divideAfter: false,
  lastElement: false
},
{
  item_uuid: uuid(),
  title: "Tequila Olmeca Gold",
  descr: "35,0 %",
  volume: "0,04 l",
  price: "3,30 €",
  divideAfter: true,
  lastElement: false
},
/* {
  item_uuid: uuid(),
  title: "Marsen Dulovica",
  descr: "42,3 %",
  volume: "0,04 l",
  price: "4,20 €",
  divideAfter: false,
  lastElement: false
},
{
  item_uuid: uuid(),
  title: "Marsen Višňovica",
  descr: "42,3 %",
  volume: "0,04 l",
  price: "4,20 €",
  divideAfter: false,
  lastElement: false
},
{
  item_uuid: uuid(),
  title: "Pivovica Predná Hora",
  descr: "42,0 %",
  volume: "0,04 l",
  price: "2,70 €",
  divideAfter: true,
  lastElement: false
}, */
// GIN
{
  type: "title",
  block: true,
  blockId: "GIN_BLOCK_ID",
  icon: FaLemon,
  iconSize: "45px",
  item_uuid: uuid(),
  title: "Gin",
  blockTitle: "Gin",
  descr: "",
  volume: "",
  price: "",
  divideAfter: true,
  lastElement: false
},
{
  item_uuid: uuid(),
  title: "Beefeater Gin",
  descr: "40,0 %",
  volume: "0,04 l",
  price: "2,30 €",
  divideAfter: false,
  lastElement: false
},
{
  item_uuid: uuid(),
  title: "Beefeater Pink",
  descr: "37,50 %",
  volume: "0,04 l",
  price: "2,50 €",
  divideAfter: false,
  lastElement: false
},
{
  item_uuid: uuid(),
  title: "Beefeater Blood Orange",
  descr: "37,5 %",
  volume: "0,04 l",
  price: "2,50 €",
  divideAfter: false,
  lastElement: false
},
/* {
  item_uuid: uuid(),
  title: "Beefeater Peach & Raspberry",
  descr: "37,5 %",
  volume: "0,04 l",
  price: "2,30 €",
  divideAfter: false,
  lastElement: false
}, */
{
  item_uuid: uuid(),
  title: "Malfy Rosa Gin",
  descr: "41,0 %",
  volume: "0,04 l",
  price: "3,00 €",
  divideAfter: false,
  lastElement: false
},
{
  item_uuid: uuid(),
  title: "Gin Lúčky",
  descr: "40,0 %",
  volume: "0,04 l",
  price: "2,50 €",
  divideAfter: false,
  lastElement: false
},
/* {
  item_uuid: uuid(),
  title: "Borec Dry Gin",
  descr: "37,5 %",
  volume: "0,04 l",
  price: "2,00 €",
  divideAfter: false,
  lastElement: false
}, */
{
  item_uuid: uuid(),
  title: "Bombay Sapphire Gin",
  descr: "40,0 %",
  volume: "0,04 l",
  price: "2,70 €",
  divideAfter: false,
  lastElement: false
},
/* {
  item_uuid: uuid(),
  title: "Albert Michler Gin Genuine",
  descr: "44,0 %",
  volume: "0,04 l",
  price: "3,00 €",
  divideAfter: false,
  lastElement: false
},
{
  item_uuid: uuid(),
  title: "Albert Michler Gin Green",
  descr: "44,0 %",
  volume: "0,04 l",
  price: "3,00 €",
  divideAfter: false,
  lastElement: false
},
{
  item_uuid: uuid(),
  title: "Albert Michler Gin Orange",
  descr: "44,0 %",
  volume: "0,04 l",
  price: "3,00 €",
  divideAfter: false,
  lastElement: false
}, */
/* {
  item_uuid: uuid(),
  title: "Tanqueray Gin",
  descr: "47,3 %",
  volume: "0,04 l",
  price: "2,50 €",
  divideAfter: false,
  lastElement: false
}, */
{
  item_uuid: uuid(),
  title: "Monkey 47 Dry Gin",
  descr: "47,0 %",
  volume: "0,04 l",
  price: "8,50 €",
  divideAfter: false,
  lastElement: false
},]