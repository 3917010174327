import { BrowserRouter, Routes, Route, useNavigate  } from "react-router-dom";
import HomePage from "./pages/NewHomePage"
/* import HomePage from "./pages/HomePage" */
import ContactPage from "./pages/ContactPage"
import CookiesPage from "./pages/CookiesPage";
import LoginPage from "./pages/LoginPage"
import DashboardPage from "./pages/Admin/DashboardPage"
import DefaultLayout from "./layouts/DefaultLayout"
import AdminPanelLayout from "./layouts/AdminPanelLayout"
import PrednaHoraPage from "./pages/Clients/PrednaHoraPage";
import { IoCloseCircleSharp } from "react-icons/io5"
//@ts-ignore
import TagManager from "react-gtm-module";
import { useEffect, useState } from "react";
import React from 'react';

import NewClientPage from "./pages/Clients/NewClientPage";
import NewClientPageOnline from "./pages/Clients/NewClientPageOnline";

//@ts-ignore
import blogContent from "./content/Blog/blog.js"

// PIVAREN PREDNA HORA HUMENNE
//@ts-ignore
/* import prednaHoraLogo from './assets/images/clients/logos/predna_hora.jpg'
import { prednaHoraConfig } from "./config/clients/prednaHora";
import { prednaHoraMenu } from "./content/PrednaHora/menu"; */

// PANORAMA CAFFE & DRINK BAR
import { panoramaConfig } from "./config/clients/panorama";

// JAZZ CAFE HUMENNE
import { jazzCafeConfig } from "./config/clients/jazzCafe";

// OBYVACKA BAR HUMENNE
import { obyvackaBarConfig } from "./config/clients/obyvackaBar";

// PIVOVAR U MEDVEDA
import { mercyCoffeeConfig } from "./config/clients/mercyCoffee";

// PREVIEW
/* import { previewConfig } from "./config/clients/preview"; */

import { AuthProvider} from "./context/AuthContext"
import { justLovelyCafeConfig } from "./config/clients/justLovelyCafe";
import CookiesModal from "./components/cards/CookiesModal";

import { useCookies } from "react-cookie";
import BlogMain from "./components/blog/BlogMain";
function App() {

  const [cookie, setCookie] = useCookies(['mojeqr_allow_cookies']);
  const tagManagerArgs = {
    gtmId: 'GTM-WWBSMZL'
  }



  const handleForbidCookies = () => {
    const expirationDate = new Date();
    expirationDate.setFullYear(expirationDate.getFullYear() - 1);

    setCookie("mojeqr_allow_cookies", undefined, { path: '/', expires: expirationDate });
    window.location.href = "/"

  }
  const handleAllowCookies = () => {
    const expirationDate = new Date();
    expirationDate.setFullYear(expirationDate.getFullYear() + 10);

    setCookie("mojeqr_allow_cookies", "allowed", { path: '/', expires: expirationDate });
  }


  useEffect(() => {
    console.log(cookie)
    if (cookie.mojeqr_allow_cookies === "allowed") {
      TagManager.initialize(tagManagerArgs);
    }
  }, [cookie.mojeqr_allow_cookies]);

  return (
    <AuthProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<DefaultLayout />}>
            <Route index element={<HomePage />} />
            <Route path="sluzby" element={<HomePage />} />
            <Route path="klienti" element={<HomePage />} />
            <Route path="cennik" element={<HomePage />} />
            <Route path="kontakt" element={<ContactPage />} />
            <Route path="cookies" element={<CookiesPage forbidCookies={() => { handleForbidCookies() }} />} />
          </Route>

          <Route path="/blog" element={<DefaultLayout />}>
            <Route index element={<BlogMain blogContent={blogContent} />} />
            {
              blogContent.map((blog: {
                path: string,
                component: any,
              }) =>
                <Route path={blog.path} element={blog.component} />
              )
            }
          </Route>

          <Route path="/admin/" element={<AdminPanelLayout />}>
            <Route path="prihlasenie" element={<LoginPage/>} />
            <Route path="prehlad" element={<DashboardPage/>} />
          </Route>

          <Route path="predna_hora" element={<PrednaHoraPage />} />

          <Route
            element={
              <NewClientPage
                clientName="Panorama Caffe & Drink Bar"
                localConfig={panoramaConfig}
                localMenu={panoramaConfig.menu}
                localBackgroundColor="#1f2a2e"
                localClientLogo={panoramaConfig.logo}
                path="panorama"
              />
            }
            path="panorama"
          />


          <Route
            element={
              <NewClientPage
                clientName="Jazz Café Humenné"
                localConfig={jazzCafeConfig}
                localMenu={jazzCafeConfig.menu}
                localBackgroundColor="#1a1a18"
                localClientLogo={jazzCafeConfig.logo}
                path="jazz_cafe"
              />
            }
            path="jazz_cafe"
          />


          <Route
            element={
              <NewClientPage
                clientName="Obývačka Bar Humenné"
                localConfig={obyvackaBarConfig}
                localMenu={obyvackaBarConfig.menu}
                localBackgroundColor="#ffffff"
                localClientLogo={obyvackaBarConfig.logo}
                path="obyvacka_bar"
              />
            }
            path="obyvacka_bar"
          />

          <Route
            element={
              <NewClientPage
                clientName="Mercy Coffee"
                localConfig={mercyCoffeeConfig}
                localMenu={mercyCoffeeConfig.menu}
                localBackgroundColor="#1C1A1F"
                localClientLogo={mercyCoffeeConfig.logo}
                path="mercy_coffee"
              />
            }
            path="mercy_coffee"
          />


          <Route
            element={
              <NewClientPage
                clientName="Just Lovely Cafe"
                localConfig={justLovelyCafeConfig}
                localMenu={justLovelyCafeConfig.menu}
                localBackgroundColor="#ffffff"
                localClientLogo={justLovelyCafeConfig.logo}
                path="just_lovely_cofe"
              />
            }
            path="just_lovely_cafe"
          />



          <Route
            element={
              <NewClientPageOnline
                clientName="Panorama Caffe & Drink Bar"
                localConfig={panoramaConfig}
                localMenu={panoramaConfig.menu}
                localBackgroundColor="#1f2a2e"
                localClientLogo={panoramaConfig.logo}
                path="panorama_new"
              />
            }
            path="panorama_new"
          />

          {/* <Route
            element={
              <NewClientPage
                path="pilsner_factory"
                clientName="Pilsner Factory"
                localConfig={mercyCoffeeConfig}
                localMenu={mercyCoffeeConfig.menu}
                localBackgroundColor="#1C1A1F"
                localClientLogo={mercyCoffeeConfig.logo}
              />
            }
            path="pilsner_factory"
          /> */}

          {/*
          <Route
            element={
              <NewClientPage
                clientName="Preview"
                localConfig={previewConfig}
                localMenu={previewConfig.menu}
                localBackgroundColor="#ffffff"
                localClientLogo={previewConfig.logo}
              />
            }
            path="preview"
          />
          */}
        </Routes>
        { cookie.mojeqr_allow_cookies !== "allowed" &&
          <CookiesModal
            allowCookies={() => {handleAllowCookies()}}
          />
        }
        <div
          id="IMAGE_MODAL"
          className="imageModal"
        >
          <div id="IMAGE_MODAL_CLOSE_BUTTON_WRAP">
            <IoCloseCircleSharp
              id="IMAGE_MODAL_CLOSE_BUTTON"
              size={"35px"}
            />
          </div>
          <div className="imageModal_image">
            {/* eslint-disable-next-line */}
            <h2 id="IMAGE_MODAL_TITLE"></h2>
            <img
              id="IMAGE_MODAL_IMAGE"
              alt="MojeQr"
            />
            <div id="IMAGE_MODAL_EXPANDABLE_CONTENT" />
            <div id="IMAGE_MODAL_LEFT"></div>
            <div id="IMAGE_MODAL_RIGHT"></div>
          </div>
        </div>
      </BrowserRouter>
    </AuthProvider>
  );
}

export default App;
