//@ts-ignore
import logo from "../../assets/images/logo.jpg"

const LoadingScreen = () => {
  return (
    <div className="loading_screen">
      <img
        src={logo}
        alt="MojeQr | Online menu tvoj podnik"
      />
    </div>
  )
}

export default LoadingScreen