//@ts-ignore
import cookieIcon from "../../assets/images/cookies.svg"
import { useNavigate } from "react-router-dom";
import { useState } from "react";
const CookiesModal = (props: {
  allowCookies: any
}) => {
  const navigate = useNavigate()

  const [customClass, setCustomClass] = useState("")

  const handleAgreeWithCookies = () => {
    setCustomClass("cookies_modal_hide")
    setTimeout(() => {
      props.allowCookies()
    }, 300);
  }


  return (
    <div className={`cookies_modal ${customClass}`}>
      <div className="title">
        Cookies
        <img src={cookieIcon} alt="MojeQR - Cookies" />
      </div>
      <div className="text">
        Tento web používa cookies na analýzu návštevnosti a zlepšenie užívateľskej skúsenosti.
        Pokračovaním súhlasíte s ich používaním.
      </div>
      <div className="action">
        <div className="cookies_redirect" onClick={() => {navigate("/cookies")}}>Prejsť na nastavenia</div>
        <div className="agree_button" onClick={handleAgreeWithCookies}>Súhlasím</div>
      </div>
    </div>
  )
}

export default CookiesModal