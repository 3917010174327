import { useTranslation } from "react-i18next"
import PricingElement from "../elements/PricingElement"
import { useState } from "react"

interface LocalProps {
  id: string
}

const PricingCard = (props: LocalProps) => {

  const [hoverIndex, setHoverIndex] = useState(0) // 0, 1, 2

  const { t } = useTranslation()

  const handleMouseOver = (index: number) => {
    setHoverIndex(index)
  }

  return (
    <div
      id={ props.id }
      className="pricingCard"
    >
      <h1
        id="PRICING_TITLE_ID"
      >
        { t("pricingCard.title") }
      </h1>
      {/* <h3>
        { t("pricingCard.pricesMayChange") }
      </h3> */}
      <div className="pricingCard__wrap">
        <PricingElement
          id="PRICING_ELEMENT_EXTENDED"
          price={t("pricingCard.extended.price")}
          title={t("pricingCard.extended.title")}
          copy
          actualization
          design
          admin
          inDevelopment
          style={{
            transform: (hoverIndex === 1) ? "scale(1.00)" : "scale(0.9)"
          }}
          onMouseOver={() => handleMouseOver(1)}
        />
        <PricingElement
          id="PRICING_ELEMENT_BASIC"
          price={t("pricingCard.basic.price")}
          title={t("pricingCard.basic.title")}
          copy
          actualization
          design
          admin
          seasons
          style={{
            transform: (hoverIndex === 0) ? "scale(1.00)" : "scale(0.9)"
          }}
          onMouseOver={() => handleMouseOver(0)}
        />
        <PricingElement
          id="PRICING_ELEMENT_PRO"
          price={t("pricingCard.pro.price")}
          title={t("pricingCard.pro.title")}
          copy
          actualization
          design
          admin
          seasons
          inDevelopment
          style={{
            transform: (hoverIndex === 2) ? "scale(1.00)" : "scale(0.9)"
          }}
          onMouseOver={() => handleMouseOver(2)}
        />

      </div>
    </div>
  )
}

export default PricingCard