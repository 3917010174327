import uuid from "react-uuid"
import { BsCreditCard2BackFill } from "react-icons/bs"

export const pochutiny = [
  {
    item_uuid: uuid(),
    title: "Pistácie",
    descr: "",
    volume: "1 bal",
    price: "2,30 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Arašidy",
    descr: "",
    volume: "1 bal",
    price: "2,30 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Tyčinky",
    descr: "",
    volume: "1 bal",
    price: "1,20 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Chipsy",
    descr: "",
    volume: "1 bal",
    price: "1,80 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Dupetky",
    descr: "",
    volume: "1 bal",
    price: "1,80 €",
    divideAfter: true
  },
  {
    type: "extra",
    icon: BsCreditCard2BackFill,
    iconSize: "25px",
    item_uuid: uuid(),
    title: "Možná platba kartou",
    descr: "",
    volume: "",
    price: "",
    noDescr: true,
    divideAfter: false,
    lastElement: true
  },
]