import uuid from "react-uuid"
import { FaInfoCircle } from "react-icons/fa";

export const caj = [
  {
    item_uuid: uuid(),
    type: "extra",
    title: "Čaj sypaný",
    descr: "sypaný",
    volume: "450 ml / 380 ml",
    price: "3,90 € / 2,90 €",
    divideAfter: true
  },
  {
    item_uuid: uuid(),
    title: "Biely čaj s čučoriedkov a bazou",
    descr: "ľahký, príjemne voňavý a osviežujúci",
    volume: "",
    price: "",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Zelený čaj",
    descr: "delikátny zelený cejlónsky čaj vypestovaný vo výške 1400 m.n.m.",
    volume: "",
    price: "",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Zelený čaj s granátovým jablkom",
    descr: "lahodné a svieže spojenie plné antioxidantov",
    volume: "",
    price: "",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "English breakfast",
    descr: "povzbudzujúci čierny cejlónsky čaj s plnou chuťou",
    volume: "",
    price: "",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Earl Grey",
    descr: "kombinácia čierneho cejlónskeho čaju a bergamotovej silice",
    volume: "",
    price: "",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Zmes lesného ovocia",
    descr: "osviežujúca, voňavá zmes jahôd, malín, čučoriedok a ibišteka s vysokým obsahom vitamínu C",
    volume: "",
    price: "",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Brusnica, ibištek a šípka",
    descr: "osviežujúci ovocmný, bezkofeínový čaj",
    volume: "",
    price: "",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Kamilka",
    descr: "bezkofeínový bylinný čaj z kvetov z kvetov rumančeka s ukokojujúcimi účinkami",
    volume: "",
    price: "",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Citrónová tráva, zázvor a citrusy",
    descr: "voňavá a veľmi svieža bezkofeínová kombinácia",
    volume: "",
    price: "",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Harmónia",
    descr: "Bylinka Tulsi, zelený rooibox, kúsky zázvoru, škorica, železník",
    volume: "",
    price: "",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Večerná harmónia",
    descr: "bylinkový čaj bez teínu",
    volume: "",
    price: "",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Hrnček plný byliniek",
    descr: "medovka, šípka, ibištek, mäta",
    volume: "",
    price: "",
    divideAfter: true
  },
  {
    item_uuid: uuid(),
    title: "Čaj z čerstvej mäty",
    descr: "",
    volume: "400 ml",
    price: "3,85 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Matcha čaj",
    descr: "",
    volume: "300 ml",
    price: "3,90 €",
    divideAfter: true
  },
  /* {
    item_uuid: uuid(),
    title: "Zmes lesného ovocia",
    descr: "sypaný",
    volume: "400 ml",
    price: "3,60 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Brusnica, ibištek a šípka",
    descr: "sypaný",
    volume: "400 ml",
    price: "3,60 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Zelený čaj s granátovým jablkom",
    descr: "sypaný",
    volume: "400 ml",
    price: "3,60 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Ajurvedska zmes Harmónia",
    descr: "sypaný - tulsi, roobios, zázvor, škorica, harmanček, lísky ruže,kvety levandule",
    volume: "400 ml",
    price: "3,60 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Večerná Harmónia",
    descr: "sypaný - šípková kôra, kousky jabĺčka, ibištek, medovka, lipový kvet (bez teínu, bez korenia)",
    volume: "400 ml",
    price: "3,60 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Hrnček plný byliniek",
    descr: "sypaný - medovka, šípka, ostružník, ibištek, mäta,lipa, harmanček, bobuľové ovocie",
    volume: "400 ml",
    price: "3,60 €",
    divideAfter: false
  },



  {
    item_uuid: uuid(),
    title: "Zázvor & citrón",
    descr: "sypaný",
    volume: "400 ml",
    price: "3,60 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Kamilka & levanduľa",
    descr: "sypaný",
    volume: "400 ml",
    price: "3,60 €",
    divideAfter: false
  }, */
  /* {
    item_uuid: uuid(),
    title: "Lipa & mäta & medovka",
    descr: "sypaný",
    volume: "400 ml",
    price: "3,60 €",
    divideAfter: false
  }, */
  /* {
    item_uuid: uuid(),
    title: "Malina & záhradné ovocie",
    descr: "sypaný",
    volume: "400 ml",
    price: "3,60 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Zelený čaj s ananásom a mangom",
    descr: "sypaný",
    volume: "400 ml",
    price: "3,60 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Zelený čaj",
    descr: "sypaný",
    volume: "400 ml",
    price: "3,60 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "English breakfast",
    descr: "sypaný",
    volume: "400 ml",
    price: "3,60 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Earl Grey",
    descr: "sypaný",
    volume: "400 ml",
    price: "3,60 €",
    divideAfter: true
  },
  {
    item_uuid: uuid(),
    title: "Čaj z čerstvej mäty",
    descr: "",
    volume: "400 ml",
    price: "3,50 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Chai Latte",
    descr: "",
    volume: "300 ml",
    price: "3,80 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Matcha čaj",
    descr: "",
    volume: "300 ml",
    price: "3,50 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Matcha Latte",
    descr: "",
    volume: "300 ml",
    price: "3,80 €",
    divideAfter: true
  },
  {
    item_uuid: uuid(),
    title: "Horúca čokoláda - mliečna",
    descr: "",
    volume: "150 ml",
    price: "3,60 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Horúca čokoláda - horká",
    descr: "",
    volume: "150 ml",
    price: "3,60 €",
    divideAfter: true
  },
  {
    item_uuid: uuid(),
    title: "Med",
    descr: "",
    volume: "20 g",
    price: "0,50 €",
    divideAfter: false
  }, */
  /* {
    item_uuid: uuid(),
    title: "Lipový sirup",
    descr: "",
    volume: "20 ml",
    price: "0,50 €",
    divideAfter: true
  }, */
  {
    type: "extra",
    icon: FaInfoCircle,
    iconColor: "#201F1D",
    iconSize: "25px",
    item_uuid: uuid(),
    title: "Pri objednávke so sebou",
    descr: `
    Účtujeme si poplatok za obaly v sume<br/>
    - 0,10 € - pohár<br/>
    - 0,60 € - malá krabička<br/>
    - 1,10 € - veĺká krabica<br/>`,
    volume: "",
    price: "",
    noDescr: false,
    divideAfter: false,
    lastElement: false
  },
]