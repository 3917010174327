import { FaQuestionCircle } from "react-icons/fa";
import { IoMdAdd } from "react-icons/io";

const HowToCreateBlock = () => {
  return (
    <div className="how_to_element_wrap">
      <div className="transparent_card ">
        <div className="subtitle align-center">
          <FaQuestionCircle size={"30px"} style={{marginRight: "10px"}} />
          Ako vytvoriť sekciu ?
        </div>
      </div>
      <div className="card how_to">
        <div className="text">
          1. Na ľavej strane v menu sa prepnite do <b>Editácia ponuky</b>
        </div>
        <div className="text align-center">
          2. V pravom hornom rohu uvidíte okrúhle tlačidlo <IoMdAdd size={"24px"} style={{marginLeft: "5px"}} />
        </div>
        <div className="text">
          3. Vypĺňte formulár a zakliknite tlačidlo <b>Potvrdiť</b>
        </div>
      </div>
    </div>
  )
}

export default HowToCreateBlock