import uuid from "react-uuid"
import { BsCreditCard2BackFill } from "react-icons/bs"
import sk_flag from "../../../assets/images/language_flags/sk.png"
import new_flag from "../../../assets/images/clients/icons/panorama/new_flag.png"

export const vino = [
  /* {
    item_uuid: uuid(),
    title: "Pivnica Orechová-Müller Thurgau",
    descr: "biele suché",
    flag: sk_flag,
    flagHeight: "12px",
    volume: "0,10 l",
    price: "1,90 €",
    divideAfter: false
  }, */
  {
    item_uuid: uuid(),
    title: "Staško. - Pálava",
    descr: "biele polosuché",
    /* flagBehindTitle: true, */
    flag: new_flag,
    flagHeight: "18px",
    volume: "0,10 l",
    price: "2,20 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Staško. - Rizling Rýnsky",
    descr: "biele suché",
    flag: new_flag,
    flagHeight: "18px",
    /* flagBehindTitle: true, */
    volume: "0,10 l",
    price: "2,30 €",
    divideAfter: false
  },
  /* {
    item_uuid: uuid(),
    title: "Staško. - Solaris",
    descr: "biele suché",
    flag: new_flag,
    flagHeight: "18px",
    volume: "0,10 l",
    price: "1,90 €",
    divideAfter: false
  }, */
  {
    item_uuid: uuid(),
    title: "Château Topoľčianky-Cabernet Sauvignon",
    descr: "červené suché",
    flag: sk_flag,
    flagHeight: "12px",
    volume: "0,10 l",
    price: "2,20 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Víno Verita - Cabernet Sauvignon",
    descr: "červené suché",
    flag: new_flag,
    flagHeight: "18px",
    volume: "0,10 l",
    price: "2,20 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Prosecco - nalievané",
    descr: " ",
    volume: "0,10 l",
    price: "2,30 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Víno / Prosecco",
    descr: "fľaša",
    volume: "0,75 l",
    price: "12,50 €",
    divideAfter: true
  },
  {
    type: "extra",
    icon: BsCreditCard2BackFill,
    iconSize: "25px",
    item_uuid: uuid(),
    title: "Možná platba kartou",
    descr: "",
    volume: "",
    price: "",
    noDescr: true,
    divideAfter: false,
    lastElement: true
  },
]