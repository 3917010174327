
import { useEffect } from "react"
import React from "react"
import Dashboard from "../../components/pages/Dashboard"

const DashboardPage = () => {


  useEffect(() => {
    document.title = `MojeQr - Ovládací panel`
    document.getElementsByTagName('meta')[3].content = `V prípade akýchkoľvek otázok nás neváhaj kontaktovať na +421 940 850 530 alebo na info@mojeqr.sk!`
  },[])

  return (
    <Dashboard />
  )
}

export default DashboardPage







