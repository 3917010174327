import uuid from "react-uuid"
import { FaInfoCircle } from "react-icons/fa";
import { domace_natierky } from "./domace_natierky"

export const snack = [
  ...domace_natierky,
  {
    item_uuid: uuid(),
    title: "Maslový Croissant",
    descr: "",
    volume: "95 g",
    price: "1,70 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Maslový Croissant s džemom",
    descr: "",
    volume: "95 g",
    price: "2,20 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Maslový Croissant s nutellou",
    descr: "",
    volume: "107 g",
    price: "2,20 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Panini",
    descr: "rôzne druhy - zapekané, podávané s prílohovým šalátom",
    volume: "",
    price: "6,50 €",
    divideAfter: true
  },
  /* {
    item_uuid: uuid(),
    title: "Quiche - slaný francúzky koláč",
    descr: "rôzne plnky (špenát / losos / brokolica)",
    volume: "",
    price: "6,50 €",
    divideAfter: true
  }, */
  {
    item_uuid: uuid(),
    title: "Mandle",
    descr: "",
    volume: "60 g",
    price: "2,60 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Pistácie",
    descr: "",
    volume: "60 g",
    price: "2,60 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Arašidy",
    descr: "",
    volume: "60 g",
    price: "2,60 €",
    divideAfter: true
  },
  {
    type: "extra",
    icon: FaInfoCircle,
    iconColor: "#201F1D",
    iconSize: "25px",
    item_uuid: uuid(),
    title: "Pri objednávke so sebou",
    descr: `
    Účtujeme si poplatok za obaly v sume<br/>
    - 0,10 € - pohár<br/>
    - 0,60 € - malá krabička<br/>
    - 1,10 € - veĺká krabica<br/>`,
    volume: "",
    price: "",
    noDescr: false,
    divideAfter: false,
    lastElement: false
  }
]