//@ts-nocheck
import uuid from "react-uuid"

/* import leafIcon from "../../assets/images/clients/icons/panorama/leafIcon.svg" */
/* import spreadIcon from "../../assets/images/clients/icons/just_lovely_cafe/spread.svg" */
import babyIcon from "../../assets/images/clients/icons/just_lovely_cafe/baby.svg"
import extraIcon from "../../assets/images/clients/icons/just_lovely_cafe/extra.svg"
import coffeeIcon from "../../assets/images/clients/icons/panorama/coffee-cup.svg"
import teaIcon from "../../assets/images/clients/icons/panorama/tea.svg"
/* import feastIcon from "../../assets/images/clients/icons/panorama/feast.svg" */
import nonalcoIcon from "../../assets/images/clients/icons/panorama/drink.svg"
import beerIcon from "../../assets/images/clients/icons/panorama/beer.svg"
import snacksIcon from "../../assets/images/clients/icons/panorama/snack.svg"
import wineIcon from "../../assets/images/clients/icons/panorama/glass-with-wine.svg"
import specialginIcon from "../../assets/images/clients/icons/panorama/ginspecial.svg"
import alcoIcon from "../../assets/images/clients/icons/panorama/shots.svg"
import hotChocolateIcon from "../../assets/images/clients/icons/jazz_cafe/hot_chocolate.svg"
import frappeIcon from "../../assets/images/clients/icons/jazz_cafe/frappe.svg"


/* import { horucaCokolada } from "./elements/horucaCokolada" */
import { kava } from "./elements/kava"
import { caj } from "./elements/caj"
/* import { jesenneMenu } from "./elements/jesenneMenu" */
/* import { domace_natierky } from "./elements/domace_natierky" */
import { limonady } from "./elements/limonady"
import { alkohol } from "./elements/alkohol"
import { nealko } from "./elements/nealko"
import { vino } from "./elements/vino"
import { pivo } from "./elements/pivo"
import { snack } from "./elements/snack"
/* import { feast } from "./elements/feast" */





export const justLovelyCafeMenu =  [
  [
    {
      id: "KAVA_ID",
      icon: coffeeIcon,
      iconWidth: "50px",
      iconMarginRight: "-8px",
      page_uuid: uuid(),
      titleShort: "Káva",
      title: "Káva",
      list: kava,
      isOpen: false,
    },
    {
      id: "COFFEE_IN_GOOD_SPIRITS_ID",
      icon: coffeeIcon,
      iconWidth: "50px",
      iconMarginRight: "-8px",
      page_uuid: uuid(),
      titleShort: "Coffee in good</br> spirits",
      title: "Coffee in good spirits",
      list: [
        {
          item_uuid: uuid(),
          title: "Írska káva",
          descr: "espresso + írska whiskey + šľahačka - 8 g",
          volume: "170 ml",
          price: "5,50 €",
          divideAfter: false
        },
        {
          item_uuid: uuid(),
          title: "Cappuccino s Baileys",
          descr: "espresso + napenené mlieko + baileys - 8 g",
          volume: "170 ml",
          price: "4,40 €",
          divideAfter: false
        },
      ],
      isOpen: false,
    },
  ],
  [
    {
      id: "MLIECNE_DEZERTY_ID",
      icon: frappeIcon,
      iconWidth: "50px",
      iconMarginRight: "-8px",
      page_uuid: uuid(),
      titleShort: "Lahodné mliečne dezerty </br>s príchuťou kávy",
      title: "Lahodné mliečne dezerty s príchuťou kávy",
      list: [
        {
          item_uuid: uuid(),
          title: "Espresso Macchiato",
          descr: "espresso + mliečna pena",
          volume: "80 ml",
          price: "2,60 €",
          divideAfter: false
        },
        {
          item_uuid: uuid(),
          title: "Cappuccino",
          descr: "espresso + napenené mlieko ",
          /* expandableDescr: [
            "Kávový nápoj z espressa a našľahaného mlieka",
          ], */
          volume: "180 ml",
          price: "2,90 €",
          divideAfter: false
        },
        {
          item_uuid: uuid(),
          title: "Cafe Latte",
          descr: "espresso + napenené mlieko",
          /* expandableDescr: [
            "Mliečna káva, ktorá sa pripravuje z espressa a našľahaného mlieka vo väčšom objeme.",
          ], */
          volume: "300 ml",
          price: "3,80 €",
          divideAfter: false
        },
        {
          item_uuid: uuid(),
          title: "Flat White",
          descr: "dvojité espresso + napenené mlieko",
          /* expandableDescr: [
            "To je výrazná chuť kávy v našľahanom mlieku.",
            "Táto kombinácia vytvára silnú a bohatú chuť.",
            "Základom je 16 gramov čerstvo namletej kávy.",
            "Nápoj sa podáva v 200 ml šálke."
          ], */
          volume: "125 ml",
          price: "3,80 €",
          divideAfter: false
        },
        {
          item_uuid: uuid(),
          title: "Viedenská káva",
          descr: "espresso lungo + šľahačka",
          volume: "150 ml",
          price: "3,50 €",
          divideAfter: false
        },
      ],
      isOpen: false,
    },
  ],
  [
    {
      id: "DETSKE_MLIECNE_DEZERTY_ID",
      icon: babyIcon,
      iconWidth: "50px",
      iconMarginRight: "-8px",
      page_uuid: uuid(),
      titleShort: "Detské mliečne </br>dezerty",
      title: "Detské mliečne dezerty",
      list: [
        {
          item_uuid: uuid(),
          title: "Babyccino",
          descr: "napenené mlieko",
          volume: "150 ml",
          price: "2,10 €",
          divideAfter: false
        },
        {
          item_uuid: uuid(),
          title: "Babyccino s marshmallows a čoko topingom",
          descr: "",
          volume: "150 ml",
          price: "2,60 €",
          divideAfter: false
        },
      ],
      isOpen: false,
    },
  ],
  [
    {
      id: "SPECIALNE_MLIECNE_HORUCE_DEZERTY_ID",
      icon: hotChocolateIcon,
      iconWidth: "50px",
      iconMarginRight: "-8px",
      page_uuid: uuid(),
      titleShort: "Špeciálne mliečne </br>horúce dezerty",
      title: "Špeciálne mliečne horúce dezerty",
      list: [
        {
          item_uuid: uuid(),
          title: "Matcha latte klasik",
          descr: "Zelená matcha prášok, mlieko",
          volume: "300 ml",
          price: "3,80 €",
          img: require("../../assets/images/clients/items/JustLovelyCafe/matcha_latte_classic.jpg"),
          divideAfter: false
        },
        {
          item_uuid: uuid(),
          title: "Matcha latte modré",
          descr: "Blue matcha butterfly pea flower prášok, mlieko, sirup, biela čokoláda",
          volume: "300 ml",
          price: "4,20 €",
          img: require("../../assets/images/clients/items/JustLovelyCafe/matcha_latte_blue.jpg"),
          divideAfter: false
        },
        {
          item_uuid: uuid(),
          title: "Kurkuma latte",
          descr: "Turmenic prášok, mlieko, sirup pražený oriešok",
          volume: "300 ml",
          price: "4,20 €",
          img: require("../../assets/images/clients/items/JustLovelyCafe/matcha_latte_kurkuma.jpg"),
          divideAfter: false
        },
        {
          type: "image",
          item_uuid: uuid(),
          offerImg: require("../../assets/images/clients/items/JustLovelyCafe/matcha_latte.jpg"),
          descr: "",
          volume: "",
          price: "",
          divideAfter: true,
          lastElement: false
        },
        {
          item_uuid: uuid(),
          title: "Cviklové latte",
          descr: "jemný vegánsky cviklový prášok + orientálne koreniny + ovsené mlieko + sirup vanilka",
          volume: "300 ml",
          price: "4,20 €",
          divideAfter: false
        },
        {
          item_uuid: uuid(),
          title: "Chai Latte",
          descr: "orientálna zmes korenín a čierneho čaju + napenené mlieko",
          volume: "300 ml",
          price: "3,80 €",
          divideAfter: false
        },
        {
          item_uuid: uuid(),
          title: "Horúca čokoláda",
          descr: "mliečna, horká",
          volume: "150 ml",
          price: "3,60 €",
          divideAfter: false
        },
        {
          item_uuid: uuid(),
          title: "Mochaccino",
          descr: "horúca čokoláda + pomarančový sirup + espresso + mlieko",
          volume: "200 ml",
          price: "4,20 €",
          divideAfter: false
        },
      ],
      isOpen: false,
    },
  ],
  [
    {
      id: "NIECO_EXTRA_ID",
      icon: extraIcon,
      iconWidth: "50px",
      iconMarginRight: "-8px",
      page_uuid: uuid(),
      titleShort: "Niečo extra",
      title: "Niečo extra",
      list: [
        {
          item_uuid: uuid(),
          title: "Mlieko ku káve",
          descr: "",
          volume: "",
          price: "0,40 €",
          divideAfter: false
        },
        {
          item_uuid: uuid(),
          title: "Alternatívne mlieko ku káve",
          descr: "",
          volume: "",
          price: "0,50 €",
          divideAfter: false
        },
        {
          item_uuid: uuid(),
          title: "Šľahačka",
          descr: "",
          volume: "",
          price: "0,40 €",
          divideAfter: false
        },
        {
          item_uuid: uuid(),
          title: "Príchuť do kávy",
          descr: "",
          volume: "",
          price: "0,40 €",
          divideAfter: false
        },
        {
          item_uuid: uuid(),
          title: "Med",
          descr: "",
          volume: "",
          price: "0,50 €",
          divideAfter: false
        },
        {
          item_uuid: uuid(),
          title: "Marshmallows",
          descr: "",
          volume: "",
          price: "0,50 €",
          divideAfter: false
        },
      ],
      isOpen: false,
    },
  ],
  [
    {
      id: "CAJ_ID",
      icon: teaIcon,
      iconWidth: "50px",
      page_uuid: uuid(),
      titleShort: "Čaj",
      title: "Čaj",
      list: caj,
      isOpen: false,
    },
    {
      id: "LIMONADY_ID",
      icon: specialginIcon,
      iconWidth: "50px",
      page_uuid: uuid(),
      titleShort: "Limonády",
      title: "Limonády",
      list: limonady,
      isOpen: false,
    }
  ],
  [
    {
      id: "VINO_ID",
      icon: wineIcon,
      iconWidth: "50px",
      page_uuid: uuid(),
      titleShort: "Víno",
      title: "Víno",
      list: vino,
      isOpen: false,
    },
    {
      id: "PIVO_ID",
      icon: beerIcon,
      iconWidth: "50px",
      page_uuid: uuid(),
      titleShort: "Pivo",
      title: "Pivo",
      list: pivo,
      isOpen: false,
    },
  ],
  [
    {
      id: "ALKOHOL_ID",
      icon: alcoIcon,
      iconWidth: "50px",
      page_uuid: uuid(),
      titleShort: "Alkohol",
      title: "Alkohol",
      list: alkohol,
      isOpen: false,
    },
    {
      id: "NEALKO_ID",
      icon: nonalcoIcon,
      iconWidth: "50px",
      page_uuid: uuid(),
      titleShort: "Nealkoholické nápoje",
      title: "Nealkoholické nápoje",
      list: nealko,
      isOpen: false,
    },
  ],
  [
    {
      id: "SNACKY_A_POCHUTINY_ID",
      icon: snacksIcon,
      iconWidth: "50px",
      page_uuid: uuid(),
      titleShort: "Snacky & pochutiny",
      title: "Snacky & pochutiny",
      list: snack,
      isOpen: false,
    },
  ],
  /* [
    {
      id: "HODY_ID",
      icon: leafIcon,
      iconWidth: "60px",
      page_uuid: uuid(),
      titleShort: "Jesenné menu",
      title: "Jesenné menu",
      list: jesenneMenu,
      isOpen: false,
    },
  ], */
  [
   /*   */
    /* {
      id: "NATIERKY_ID",
      icon: spreadIcon,
      iconWidth: "50px",
      page_uuid: uuid(),
      titleShort: "Domáce nátierky",
      title: "Domáce nátierky",
      list: domace_natierky,
      isOpen: false,
    }, */
  ],
  [
    /* {
      id: "CAJ_ID",
      icon: teaIcon,
      iconWidth: "50px",
      page_uuid: uuid(),
      titleShort: "Čaj & čokoláda",
      title: "Čaj & čokoláda",
      list: caj,
      isOpen: false,
    }, */

  ],
  [
    /* {
      id: "LIMONADY_ID",
      icon: specialginIcon,
      iconWidth: "50px",
      page_uuid: uuid(),
      titleShort: "Limonády",
      title: "Limonády",
      list: limonady,
      isOpen: false,
    },
    {
      id: "ALKOHOL_ID",
      icon: alcoIcon,
      iconWidth: "50px",
      page_uuid: uuid(),
      titleShort: "Alkohol",
      title: "Alkohol",
      list: alkohol,
      isOpen: false,
    }, */
  ],
  [
    /* {
      id: "NEALKO_ID",
      icon: nonalcoIcon,
      iconWidth: "50px",
      page_uuid: uuid(),
      titleShort: "Nealkoholické nápoje",
      title: "Nealkoholické nápoje",
      list: nealko,
      isOpen: false,
    }, */

  ],
  [
   /*  {
      id: "VINO_ID",
      icon: wineIcon,
      iconWidth: "50px",
      page_uuid: uuid(),
      titleShort: "Víno",
      title: "Víno",
      list: vino,
      isOpen: false,
    },
    {
      id: "PIVO_ID",
      icon: beerIcon,
      iconWidth: "50px",
      page_uuid: uuid(),
      titleShort: "Pivo",
      title: "Pivo",
      list: pivo,
      isOpen: false,
    }, */
  ],
  [
    /* {
      id: "SNACKY_A_POCHUTINY_ID",
      icon: snacksIcon,
      iconWidth: "50px",
      page_uuid: uuid(),
      titleShort: "Snacky & pochutiny",
      title: "Snacky & pochutiny",
      list: snack,
      isOpen: false,
    }, */
  ]
]

