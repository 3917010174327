import { useEffect } from "react"
import Cookies from "../components/pages/Cookies"


const CookiesPage = (props: {
  forbidCookies: any
}) => {

  useEffect(() => {
    document.title = `MojeQr - Cookies`
    document.getElementsByTagName('meta')[3].content = `V prípade záujmu nás neváhaj kontaktovať na +421 940 850 530 alebo emailom na info@mojeqr.sk. Čakáme na teba!`
    document.getElementsByTagName('link')[2].href = "https://www.mojeqr.sk/cookies"
  },[])

  return (
    <Cookies forbidCookies={props.forbidCookies}/>
  )
}

export default CookiesPage

