//@ts-nocheck
import uuid from "react-uuid"

/* import snowGlovesIcon from "../../assets/images/clients/icons/predna_hora/snow_gloves_50_off.svg" */
import snowGlovesIcon from "../../assets/images/clients/icons/predna_hora/snow_gloves.svg"
/* import leafIcon from "../../assets/images/clients/icons/panorama/leafIcon.svg" */
import pieIcon from "../../assets/images/clients/icons/panorama/pie.svg"
import coffeeIcon from "../../assets/images/clients/icons/panorama/coffee-cup.svg"
import teaIcon from "../../assets/images/clients/icons/panorama/tea.svg"
import lemonadeIcon from "../../assets/images/clients/icons/panorama/lemonade.svg"
import driversDrinksIcon from "../../assets/images/clients/icons/panorama/drivers-drink.svg"
import nonalcoIcon from "../../assets/images/clients/icons/panorama/drink.svg"
import beerIcon from "../../assets/images/clients/icons/panorama/beer.svg"
import snacksIcon from "../../assets/images/clients/icons/panorama/snack.svg"
import wineIcon from "../../assets/images/clients/icons/panorama/glass-with-wine.svg"
import drinkIcon from "../../assets/images/clients/icons/panorama/cocktail.svg"
/* import ginIcon from "../../assets/images/clients/icons/panorama/gin.svg" */
import specialginIcon from "../../assets/images/clients/icons/panorama/ginspecial.svg"
import bottleIcon from "../../assets/images/clients/icons/panorama/bottle.svg"
import tabaqueIcon from "../../assets/images/clients/icons/panorama/tabaque.svg"
import alcoIcon from "../../assets/images/clients/icons/panorama/shots.svg"

import { zimnaPonuka } from "./elements/zimna_ponuka"
import { dezerty } from "./elements/dezerty"

import { kava } from "./elements/kava"
import { caj } from "./elements/caj"

import { limonady } from "./elements/limonady"
import { nealko } from "./elements/nealko"

import { pivo } from "./elements/pivo"
import { pochutiny } from "./elements/pochutiny"

import { drivers_drinks } from "./elements/drivers_drinks"

import { vino } from "./elements/vino"
import { miesane_napoje } from "./elements/miesane_napoje"

import { gin } from "./elements/gin"
import { gin_special } from "./elements/gin_special"

import { bottle_combo } from "./elements/bottle_combo"
import { alkoholicke_napoje } from "./elements/alkoholicke_napoje"

import { tabak } from "./elements/tabak"

export const panoramaMenu =  [
  [
    {
      id: "ZIMNA_PONUKA_ID",
      icon: snowGlovesIcon,
      iconWidth: "70px",
      page_uuid: uuid(),
      titleShort: "Zimná ponuka",
      title: "Zimná ponuka",
      list: zimnaPonuka,
      isOpen: false,
    },
  ],
  [
    {
      id: "KAVA_ID",
      icon: coffeeIcon,
      iconWidth: "50px",
      page_uuid: uuid(),
      titleShort: "Káva",
      title: "Káva",
      list: kava,
      isOpen: false,
    },
    {
      id: "CAJ_ID",
      icon: teaIcon,
      iconWidth: "50px",
      page_uuid: uuid(),
      titleShort: "Čaj",
      title: "Čaj",
      list: caj,
      isOpen: false,
    },
  ],
  [
    {
      id: "DEZERTY_PONUKA_ID",
      icon: pieIcon,
      iconWidth: "50px",
      page_uuid: uuid(),
      titleShort: "Dezerty",
      title: "Dezerty",
      list: dezerty,
      isOpen: false,
    },
  ],
  [
    {
      id: "LIMONADY_ID",
      icon: lemonadeIcon,
      iconWidth: "50px",
      page_uuid: uuid(),
      titleShort: "Limonády<br/>Smoothie",
      title: "Limonády / Smoothie",
      list: limonady,
      isOpen: false,
    },
    {
      id: "NEALKO_ID",
      icon: nonalcoIcon,
      iconWidth: "50px",
      iconMarginRight: "10px",
      page_uuid: uuid(),
      titleShort: "Nealko",
      title: "Nealko",
      list: nealko,
      isOpen: false,
    },
  ],
  [
    {
      id: "PIVO_ID",
      icon: beerIcon,
      iconWidth: "50px",
      page_uuid: uuid(),
      titleShort: "Pivo",
      title: "Pivo",
      list: pivo,
      isOpen: false,
    },
    {
      id: "VINO_ID",
      icon: wineIcon,
      iconWidth: "50px",
      page_uuid: uuid(),
      titleShort: "Víno",
      title: "Víno",
      list: vino,
      isOpen: false,
    },
  ],
 /*  [
    {
      id: "PRE_SOFEROV_ID",
      icon: driversDrinksIcon,
      iconWidth: "60px",
      page_uuid: uuid(),
      titleShort: ",Drivers‘ Drinks",
      title: ",Drivers‘ Drinks",
      list: drivers_drinks,
      isOpen: false,
    },
  ], */
  [
    {
      id: "MIESANE_NAPOJE_ID",
      icon: drinkIcon,
      iconWidth: "50px",
      page_uuid: uuid(),
      titleShort: "Miešané nápoje",
      title: "Miešané nápoje",
      list: miesane_napoje,
      isOpen: false,
    },
    {
      id: "MIESANE_NAPOJE_NEALKO_ID",
      icon: driversDrinksIcon,
      iconWidth: "50px",
      page_uuid: uuid(),
      titleShort: "Miešané nápoje NEALKO",
      title: "Miešané nápoje<br />NEALKO",
      list: drivers_drinks,
      isOpen: false,
    },
  ],
  [
    {
      id: "GIN_ID",
      icon: alcoIcon,
      iconWidth: "50px",
      /* iconMarginRight: "-15px", */
      page_uuid: uuid(),
      titleShort: "Gin",
      title: "Gin",
      list: gin,
      isOpen: false,
    },
    {
      id: "ALKOHOLICKE_NAPOJE_ID",
      icon: alcoIcon,
      iconWidth: "50px",
      page_uuid: uuid(),
      titleShort: "Alkoholické<br/>nápoje",
      title: "Alkoholické nápoje",
      list: alkoholicke_napoje,
      isOpen: false,
    },
  ],
  [
    {
      id: "BOTTLE_COMBO_ID",
      icon: bottleIcon,
      iconWidth: "50px",
      page_uuid: uuid(),
      titleShort: "Bottle COMBO",
      title: "Bottle COMBO",
      list: bottle_combo,
      isOpen: false,
    },
  ],
  [
    {
      id: "POCHUTINY_ID",
      icon: snacksIcon,
      iconWidth: "30px",
      page_uuid: uuid(),
      titleShort: "Pochutiny",
      title: "Pochutiny",
      list: pochutiny,
      isOpen: false,
    },
    {
      id: "TABAK_ID",
      icon: tabaqueIcon,
      iconWidth: "60px",
      page_uuid: uuid(),
      titleShort: "Tabak",
      title: "Tabak",
      list: tabak,
      isOpen: false,
    },
  ]

]

