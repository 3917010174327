import { OfferItemElementProps } from "../../../PropTypes/AdminDashboardProps"
import { TbTrash } from "react-icons/tb";
import { FaStar, FaImage  } from "react-icons/fa";
import { IoEyeOutline, IoEyeOffOutline  } from "react-icons/io5";
import { CgFormatSeparator } from "react-icons/cg";
import { IoDocumentText } from "react-icons/io5";
import { invokeAlertComponent } from "../../../services/userInteractionService";
import { useState } from "react";
import { RiSettings4Fill } from "react-icons/ri";
import { IoReorderFour } from "react-icons/io5";
import { RxDragHandleDots1 } from "react-icons/rx";
import { MdDragIndicator } from "react-icons/md";
import { FaQuestionCircle } from "react-icons/fa";
import { FaLocationArrow } from "react-icons/fa";

import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities"


const ItemElementRowDragble = (props: OfferItemElementProps) => {

  //@ts-ignore
  const {attributes, listeners, setNodeRef, transform, transition} = useSortable({id: props.offerElement?.uuid})

  const style = {
    transition,
    transform: CSS.Transform.toString(transform)
  }

  /* const [isDragging, setIsDragging] = useState(false) */

  const [isDraggedOver, setIsDraggedOver] = useState(false);
  const [showDescr, setShowDescr] = useState(false);

  const formatType = (type: any) => {

    switch (type) {
      case "ITEM_REGULAR":
        return "Podsekcia"
      case "ITEM_IMAGE":
        return "Obrázok"
      case "ITEM_SEPARATOR":
        return "Podsekcia"

      case "HEADLINE_REGULAR":
        return "Podsekcia"
      case "HEADLINE_ICON":
        return "Podsekcia s ikonou"
      case "HEADLINE_DESCRIPTION":
        return "Podsekcia s popisom"
      case "HEADLINE_ICON_DESCRIPTION":
        return "Podsekcia s ikonou a popisom"
      default:
        return type
    }
  }

  const handleSetRecommended = () => {
    props.onUpdateElement({
      uuid: props.offerElement?.uuid,
      name: props.offerElement?.item_name,
      alert_text:
        !props.offerElement!.item_is_recommended
          ? `Odporučili ste položku ${props.offerElement?.item_name}`
          : `Zrušili ste odporučenie pre položku položku ${props.offerElement?.item_name}`,
      data: {
        item_is_recommended: !props.offerElement!.item_is_recommended
      }
    })
  }
  const handleAddImage = () => {
    /* props.onUpdateElement({is_recommended: true}) */
    /* invokeAlertComponent("INFORMATION_ALERT_COMPONENT", "Ak chcete pridať obrázok, tak ma prosím kontaktujte - Tomáš 🌴") */
  }
  const handleHideElement = () => {
    props.onUpdateElement({
      uuid: props.offerElement?.uuid,
      name: props.offerElement?.item_name,
      alert_text:
      props.offerElement?.item_is_visible
        ? `Schovali ste položku ${props.offerElement?.item_name}`
        : `Zobrazili ste položku ${props.offerElement?.item_name}`,
      data: {item_is_visible: !props.offerElement?.item_is_visible}
    })
  }
  const handleAddSeparator = () => {
    props.onUpdateElement({
      uuid: props.offerElement?.uuid,
      name: props.offerElement?.item_name,
      alert_text:
      !props.offerElement?.divide_after
        ? `Pridali ste rozdeľovaciu čiaru pod položku ${props.offerElement?.item_name}`
        : `Odstránili ste rozdeľovaciu čiaru pod položkou ${props.offerElement?.item_name}`,
      data: {divide_after: !props.offerElement?.divide_after}
    })
  }


  return(
    <>
      {/* {
        (props.movingMode && props.index === 0) &&
        <div
          className="droppable_space droppable_space_ontop"

        >
          Umiestniť položku sem hore
        </div>
      } */}

      <div
        {...attributes}
        style={style}
        className={`item_element_row card_element`}
        onDoubleClick={(event) => {
          event.stopPropagation()
          props.onOpen(props.offerElement!)
        }}
      >
        <div className="table_class_id">
        {props.index + 1}.
        <span className="debug_order">{props.offerElement?.item_order}</span>

        </div>
        <div className="table_class_name">
          { props.offerElement?.item_name }

        </div>
        <div className="table_class_description">
          {
            props.offerElement?.item_description &&
            <div>
              <IoDocumentText
                className={`icon`}
                size={"20px"}
                color={"#35BF64"}
              />
              <div className="content">
                {props.offerElement?.item_description}
              </div>
            </div>

          }
          {
            props.offerElement?.item_expanded_description &&
            <>
              <FaQuestionCircle
                className={`icon`}
                size={"18px"}
                color={"#FF4500"}
              />
            </>

          }
          {/* {
            props.offerElement?.item_redirect_target &&
            <FaLocationArrow
              className={`icon`}
              size={"15px"}
              color="#CF966E"
            />
          } */}
        </div>
        <div className="table_class_price">
          { props.offerElement?.item_price }
        </div>
        <div className="table_class_volume">
          { props.offerElement?.item_volume }
        </div>
        <div className="table_class_flag">
          <FaStar
            className="flag_icon"
            size={"20px"}
            color={ props.offerElement!.item_is_recommended ? "#F7C808" : "#A4A4A4" }
            onClick={(e) => {
              handleSetRecommended()
              e.stopPropagation()
            }}
          />
          <FaImage
            className="flag_icon not_clickable_icon"
            size={"20px"}
            color={ props.offerElement?.item_expanded_image ? "#18A0FB" :  "#A4A4A4"}
          />
          <CgFormatSeparator
            className="flag_icon"
            size={"20px"}
            color={ props.offerElement?.divide_after ? "#ff4500" : "#A4A4A4"}
            onClick={(e) => {
              handleAddSeparator()
              e.stopPropagation()
            }}
          />
        </div>
        <div className="table_class_type">
          {
            (props.offerElement?.item_type && props.offerElement?.item_type !== "ITEM_REGULAR") &&
            <div
              className={`status_type ${props.offerElement?.item_type === "ITEM_IMAGE" && "image_status_type"}`}
            >
              { formatType(props.offerElement?.item_type) }
            </div>
          }
        </div>
        <div className="table_class_status">
          {
            !props.offerElement?.item_is_visible
            ?
              <IoEyeOffOutline className="icon" size={"18px"} color="#2C2C2C" onClick={(e) => {
                handleHideElement()
                e.stopPropagation()
              }}/>
            :
              <IoEyeOutline className="icon" size={"18px"} color="#2C2C2C" onClick={(e) => {
                handleHideElement()
                e.stopPropagation()
              }}/>
          }
        </div>
        <div className="table_class_remove">
          <RiSettings4Fill
            className="icon"
            size={"22px"}
            color="#A4A4A4"
            onClick={(event) => {
              event.stopPropagation()
              props.onOpen(props.offerElement!)
            }}
          />
          <TbTrash
            className="icon"
            size={"22px"}
            color="#FF2D55"
            onClick={(event) => {
              event.stopPropagation()
              props.onDelete(props.offerElement!)
            }}
          />



        </div>
        <div className="table_class_move">
          <div
            className="icon_wrap drabbable"
            ref={setNodeRef}
            {...listeners}
          >
            <MdDragIndicator
              className="icon"
              /* */
              size={"22px"}
              color="#2C2C2C"
              onClick={(event) => {
                event.stopPropagation()
              }}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default ItemElementRowDragble