//@ts-ignore
import cookieIcon from "../../assets/images/cookies.svg"

const Cookies = (props:{
  forbidCookies: any
}) => {

  return (
    <div className="cookies_page">
      <div className="card">
        <h2>Zásady ochrany osobných údajov</h2>

        <p>Vaša bezpečnosť a ochrana osobných údajov je pre nás dôležitá. Tieto zásady ochrany osobných údajov vysvetľujú, ako zhromažďujeme, spracúvame a chránime Vaše údaje pri používaní našich služieb.</p>

        <h3>1. Aké údaje zhromažďujeme?</h3>
        <p>Na našom webe používame <b>Google Analytics</b>, nástroj na analýzu návštevnosti, ktorý využíva <b>cookies</b> na sledovanie správania návštevníkov. Google Analytics zbiera anonymné údaje o návštevnosti, ako sú napríklad dĺžka návštevy, stránky, ktoré navštívite, a technické informácie o zariadení, ktoré používate (napríklad typ prehliadača, operačný systém alebo zariadenie).</p>

        <h3>2. Ako používame Vaše údaje?</h3>
        <p>Zhromaždené údaje používame výhradne na <b>zlepšenie našich služieb</b> a analýzu návštevnosti našich stránok. Tieto údaje nám pomáhajú lepšie pochopiť, ako návštevníci používajú naše stránky a ako môžeme zlepšiť ich užívateľskú skúsenosť.</p>

        <h3>3. Zdieľanie údajov</h3>
        <p>Vaše údaje <b>nie sú zdieľané s tretími stranami</b>. Google Analytics sa využíva výhradne na analýzu návštevnosti našich stránok.</p>
      </div>

      <div className="card">
        <div className="title">
        <h2>Zásady používania cookies</h2>
          <img src={cookieIcon} alt="MojeQR - Cookies" />
        </div>
        <p>Na našom webe používame cookies, aby sme zlepšili Vašu užívateľskú skúsenosť a analyzovali návštevnosť pomocou nástroja Google Analytics. Cookies sú malé textové súbory, ktoré sa ukladajú do Vášho zariadenia pri návšteve našich stránok.</p>

        <h3>1. Aké cookies používame?</h3>
        <p>Na našom webe používame analytické cookies prostredníctvom Google Analytics, ktoré nám pomáhajú pochopiť, ako návštevníci používajú naše stránky. Tieto cookies sa používajú na anonymné sledovanie správania návštevníkov (napríklad, ktoré stránky si prezerajú, ako dlho zostávajú na stránkach a aké zariadenia používajú).</p>

        <h3>2. Súhlas s používaním cookies</h3>
        <p>Pri prvej návšteve našich stránok sa Vás spýtame, či súhlasíte s používaním cookies. Ak s nimi súhlasíte, cookies budú aktivované. Môžete sa rozhodnúť, že cookies odmietnete alebo upravíte svoje preferencie kedykoľvek.</p>

        <h3>3. Ako spravovať cookies</h3>
        <p>Cookies môžete spravovať alebo odstrániť prostredníctvom nastavení vo Vašom prehliadači. Viac informácií o tom, ako nastaviť preferencie cookies, nájdete v nastaveniach Vášho prehliadača.</p>

        <h3>4. Zmeny v týchto zásadách</h3>
        <p>V prípade, že sa rozhodneme zmeniť naše zásady používania cookies, tieto zmeny budú zverejnené na tejto stránke. Ak máte akékoľvek otázky, neváhajte nás kontaktovať na <a href="mailto:info@mojeqr.sk">info@mojeqr.sk</a>.</p>
        <div
          className="cancel_cookies_wrap"
        >
          <div onClick={() => {props.forbidCookies()}}>
            Vymazať cookies
          </div>
        </div>
      </div>
    </div>
  );
};

export default Cookies;
