import uuid from "react-uuid"
import { BsCreditCard2BackFill } from "react-icons/bs"
import recommended_flag from "../../../assets/images/clients/icons/panorama/recommended_flag.png"
import new_flag from "../../../assets/images/clients/icons/panorama/new_flag.png"

export const miesane_napoje = [
  /* {
    item_uuid: uuid(),
    title: "VIRGIN MOJITO",
    descr: "/&nbspčerstvé limetky a mäta, mint sirup, trstinový cukor, sóda&nbsp/",
    volume: "",
    price: "4,10 €",
    divideAfter: false
  }, */
  /* {
    item_uuid: uuid(),
    title: "MOJITO",
    descr: "/&nbsp0,04 l Havana Club Añejo 3 Años, limetka, mäta, trstinový cukor, sóda&nbsp/",
    volume: "",
    price: "4,50 €",
    divideAfter: false
  }, */
  {
    type: "extra",
    icon: null,
    iconSize: "45px",
    item_uuid: uuid(),
    title: "Spritz",
    descr: "",
    volume: "",
    price: "",
    noDescr: true,
    divideAfter: true,
  },
  {
    item_uuid: uuid(),
    title: "Hugitto",
    descr: "/&nbspbaza-citrón sirup, Lúčky Gin, prosecco&nbsp/",
    volume: "",
    price: "5,60 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Grosecco",
    descr: "/&nbspgrep-bazalka sirup, Lúčky Gin, prosecco&nbsp/",
    volume: "",
    flagBehindTitle: true,
    flag: recommended_flag,
    price: "5,60 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Aperol spritz",
    descr: "/&nbspAperol, prosecco, sóda&nbsp/",
    volume: "",
    price: "5,50 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Lavender",
    descr: "/&nbsplevanduľa-arónia sirup, Beefeater Pink Strawberry Gin, prosecco&nbsp/",
    volume: "",
    price: "5,60 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Sexi RUBY",
    descr: "/&nbspNochino Ruby (nealko aperitív), prosecco&nbsp/",
    volume: "",
    price: "5,50 €",
    flagBehindTitle: true,
    flag: new_flag,
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "French 75",
    descr: "/&nbspTanqueray Blackcurrant Royale, simple sirup, citrónová šťava, prossecco&nbsp/",
    volume: "",
    price: "5,70 €",
    flagBehindTitle: true,
    flag: new_flag,
    divideAfter: true
  },
  {
    type: "extra",
    icon: null,
    iconSize: "45px",
    item_uuid: uuid(),
    title: "COCTAILS SELECTION",
    descr: "",
    volume: "",
    price: "",
    noDescr: true,
    divideAfter: true,
  },
  {
    item_uuid: uuid(),
    title: "JASMINE",
    descr: "/&nbspLúčky Gin, jazmínový cordial, sóda&nbsp/",
    volume: "",
    price: "5,50 €",
    img: require("../../../assets/images/clients/items/Panorama/miesane_napoje/jasmine.jpg"),
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "NEGRONI",
    descr: "/&nbspLúčky Gin, Cinzano Rosso, Campari&nbsp/",
    volume: "",
    price: "5,50 €",
    flagBehindTitle: true,
    flag: new_flag,
    divideAfter: false
  },
  /* {
    item_uuid: uuid(),
    title: "BASIL SMASH",
    descr: "/&nbspLúčky Gin, bazalka, simple sirup&nbsp/",
    volume: "",
    price: "4,50 €",
    img: require("../../../assets/images/clients/items/Panorama/miesane_napoje/basil-smash.jpg"),
    divideAfter: false
  }, */
  {
    item_uuid: uuid(),
    title: "SUGAR DADDY",
    descr: "/&nbspLúčky Gin, ríbezľový sirup, vaječný bielok&nbsp/",
    volume: "",
    price: "7,40 €",
    img: require("../../../assets/images/clients/items/Panorama/miesane_napoje/sugar-daddy.jpg"),
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "ROSSA",
    descr: "/&nbspMalfy Gin Rosa, San Pellegrino Aranciata Rossa, grep&nbsp/",
    volume: "",
    flagBehindTitle: true,
    flag: recommended_flag,
    price: "6,80 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "MOJITO",
    descr: "/&nbspHavana Club Añejo  3 Años, čerstvé limetky a mäta, trstinový cukor, sóda&nbsp/",
    volume: "",
    price: "5,90 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "APEROL DAIQUIRI",
    descr: "/&nbspHavana Club Añejo 3 Años, ananásová šťava, simple sirup&nbsp/",
    volume: "",
    flagBehindTitle: true,
    flag: recommended_flag,
    price: "6,40 €",
    img: require("../../../assets/images/clients/items/Panorama/miesane_napoje/aperol-daiquiri.jpg"),
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "SUNSHINE",
    descr: "/&nbspAbsolut vodka, mango pyré, vanilla sirup, vinea biela, sóda&nbsp/",
    volume: "",
    price: "6,80 €",
    img: require("../../../assets/images/clients/items/Panorama/miesane_napoje/sunshine.jpg"),
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "PORNSTAR MARTINI",
    descr: "/&nbspAbsolut Vanilla vodka, vanilla sirup, passionfruit pyré&nbsp/",
    volume: "",
    price: "7,00 €",
    img: require("../../../assets/images/clients/items/Panorama/miesane_napoje/pornstar-martini.jpg"),
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "VERY BERRY",
    descr: "/&nbspAbsolut vodka, berry cordial, sóda&nbsp/",
    volume: "",
    price: "5,50 €",
    img: require("../../../assets/images/clients/items/Panorama/miesane_napoje/very-berry.jpg"),
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "ESPRESSO MARTINI",
    descr: "/&nbspAbsolut vodka, Kahlúa, espresso, simple sirup&nbsp/",
    volume: "",
    flagBehindTitle: true,
    flag: recommended_flag,
    price: "6,80 €",
    img: require("../../../assets/images/clients/items/Panorama/miesane_napoje/espresso-martini.jpg"),
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "JAMESIE",
    descr: "/&nbspJameson, levanduľa-arónia sirup, červené víno, citrónová šťava&nbsp/",
    volume: "",
    price: "5,50 €",
    flagBehindTitle: true,
    flag: new_flag,
    divideAfter: true
  },



  {
    type: "extra",
    icon: null,
    iconSize: "45px",
    item_uuid: uuid(),
    title: "GIN & TONIC",
    descr: "",
    volume: "",
    price: "",
    noDescr: true,
    divideAfter: true,
  },

  {
    item_uuid: uuid(),
    title: "Mediterranean rosemary",
    descr: "/&nbsp0,05 l Gin Mare, Fever Tree Mediterranean tonic, rozmarín&nbsp/",
    volume: "",
    price: "7,80 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Mediterranean citrus",
    descr: "/&nbsp0,05 l Gin Mare, Fentimans Valencian Orange tonic, citrus&nbsp/",
    volume: "",
    price: "7,80 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Classy Hendricks",
    descr: "/&nbsp0,05 l Hendricks Gin, Fentimans Connoisseurs tonic, uhorka&nbsp/",
    volume: "",
    price: "7,20 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "P!nky glitter",
    descr: "/&nbsp0,05 l Beefeater Pink Strawberry Gin, Fentimans Rhubarb tonic, bobuľové ovocie&nbsp/",
    volume: "",
    price: "7,00 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Pink grep",
    descr: "/&nbsp0,05 l Malfy Gin Rosa, Fentimans Pink Grapefruit tonic, citrus&nbsp/",
    volume: "",/*
    flagBehindTitle: false,
    flagHeight: "18px", */
    flagBehindTitle: true,
    flag: recommended_flag,
    price: "7,00 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Limone",
    descr: "/&nbsp0,05 l Malfy Gin Con Limone, Fentimans Oriental Yuzu tonic, citrus&nbsp/",
    volume: "",
    price: "7,00 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Arancia",
    descr: "/&nbsp0,05 l Malfy Gin Con Arancia, Fentimans Valencian Orange tonic, citrus&nbsp/",
    volume: "",/*
    flagBehindTitle: true,
    flagHeight: "18px", */
    flagBehindTitle: true,
    flag: recommended_flag,
    price: "7,00 €",
    divideAfter: true
  },

  {
    type: "extra",
    icon: BsCreditCard2BackFill,
    iconSize: "25px",
    item_uuid: uuid(),
    title: "Možná platba kartou",
    descr: "",
    volume: "",
    price: "",
    noDescr: true,
    divideAfter: false,
    lastElement: true
  },
]