import { IoMdAdd } from "react-icons/io";
import { FaQuestionCircle } from "react-icons/fa";
import { FaInfo } from "react-icons/fa";

const HowToMoveLastItem = () => {
  return (
    <div className="how_to_element_wrap">
      <div className="transparent_card">
        <div className="subtitle align-center">
          <FaInfo  size={"30px"} style={{marginRight: "10px"}} />
          Zavadzia vám tlačidlo "Vytvoriť položku" v sekcii Editácia ponuky ?
        </div>
      </div>
      <div className="card how_to">
        <div className="text align-center">
          Pri presúvaní poslednej položky v zozname sa môže stať, že vám bude zavadzať tlačidlo <IoMdAdd size={"24px"} style={{marginLeft: "5px"}} /> v pravom dolnom rohu.
        </div>
        <div className="text">
          Dočasne ho môžete skryť tým, že podržíte klávesu ctrl a medzerník.
        </div>
      </div>
    </div>
  )
}

export default HowToMoveLastItem