import { OfferSectionElement, PriceEditHeaderCardProps } from "../../../PropTypes/AdminDashboardProps"
import { IoSearch } from "react-icons/io5";
import { IoIosArrowDown } from "react-icons/io";
import { useEffect, useState } from "react";
import { MdClear } from "react-icons/md";
import { IoMdAdd, IoMdMove } from "react-icons/io";


const PriceEditHeaderCard = (props: PriceEditHeaderCardProps) => {
  const [lastScrollTop, setLastScrollTop] = useState(0)

  const [open, setOpen] = useState(false)
  const [selectedSection, setSelectedSection] = useState<OfferSectionElement | null>(null)
  const [searchTerm, setSearchTerm] = useState("")
  const handleSelect = (section: OfferSectionElement) => {
    setOpen(false)
    setSelectedSection(section)
  }
  useEffect(()=> {
    if (props.sectionList && !selectedSection) {
      setSelectedSection(props.sectionList[0])
    } else {
      props.setSelectedSection(selectedSection)
    }
  }, [props.sectionList, selectedSection])



  useEffect(() => {
    const handleScroll = () => {
      const indicator = document.getElementById("SCROLL_INDICATOR_OFFER_BLOCK_LIST");
      const element = document.getElementById("OFFER_BLOCK_LIST");

      if (element && indicator) {
        const currentScrollTop = element.scrollTop;
        const elementHeight = element.clientHeight; // Height of the scrollable element
        const scrollableHeight = element.scrollHeight - elementHeight + 15; // Total scrollable height

        // Log the number of pixels scrolled

        // Move the indicator by 10px on each scroll move

        // Calculate the proportional top position for the indicator across the entire scrollable area
        const scrollPercentage = currentScrollTop / scrollableHeight;
        const indicatorTop = scrollPercentage * (elementHeight - indicator.clientHeight);

        // Update the indicator's top position based on the scroll position and fixed increment
        indicator.style.top = `${(indicatorTop + currentScrollTop)}px`;
        if (indicatorTop + currentScrollTop < 15) {
          indicator.style.top = `${15}px`
        }
        if ((scrollableHeight - currentScrollTop) === 0 && currentScrollTop !== 0) {
          indicator.style.top = `${scrollableHeight}px`
        }
        // Update the lastScrollTop for the next scroll event
        setLastScrollTop(currentScrollTop);
      }
    };

    const element = document.getElementById("OFFER_BLOCK_LIST");
    if (element) {
      element.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (element) {
        element.removeEventListener("scroll", handleScroll);
      }
    };
  }, [open, lastScrollTop]);

  return (
      <div className="header_row" id="EDIT_PRICE_HEADER_ROW">
        <div className="title">
          Editácia ponuky
        </div>
        <div className="input_wrap">
        {props.sectionList.length !== 0 ?
          <>
            <div className="search_bar card_element">
              <input
                value={searchTerm}
                type="text"
                placeholder="Vyhľadávať"
                onChange={(e) => {
                  props.onSearch(e.target.value)
                  setSearchTerm(e.target.value)
                }}
              />
              {
                searchTerm
                ? <MdClear className="icon clear_icon" size={"25px"} onClick={() => {props.clearSearch(); setSearchTerm("")}}/>
                : <IoSearch className="icon" size={"25px"} />
              }


            </div>
            <div
              className="section_selector card_element"
            >
              <div
                className="selector"
                onClick={() => {setOpen(prev => !prev)}}
              >
                <span key={selectedSection?.block_title_short}>
                {
                  props.sectionList && selectedSection?.block_title_short
                }
                </span>
              </div>
              <IoIosArrowDown
                className="icon"
                size={"20px"}
                style={{
                  transform: `${open ? "rotate(180deg)" : "rotate(0deg)"}`,
                  transition: "0.3s"
                }}
                onClick={() => {setOpen(prev => !prev)}}
              />
              <div
                id="OFFER_BLOCK_LIST"
                className={`selector_list ${open ? "selector_list_open" : ""}`}
              >
                {/* <div
                  className={`selector_element selector_element_active}`}
                  onClick={() => {
                      props.onCreateElementBlock(true)
                      setOpen(prev => !prev)
                    }
                  }
                >
                  Vytvoriť novú sekciu
                </div> */}
                {
                  props.sectionList.map((section, index) =>
                    <div
                      key={index}
                      className={`selector_element ${section.block_title_short === selectedSection?.block_title_short ? "selector_element_active" : ""}`}
                      onClick={() => handleSelect(section)}
                    >
                      {section.block_title_short}
                    </div>
                  )
                }
                <div id="SCROLL_INDICATOR_OFFER_BLOCK_LIST" className="scroll_indicator"></div>
              </div>
            </div>
            <div
              className={`create_offer_block_button_wrap`}
              id="CREATE_OFFER_BLOCK_BUTTON_ID"
            >
              <span>
                Vytvoriť sekciu
              </span>
              <div className="create_element_button">
                <IoMdAdd
                  onClick={() => {
                    props.onCreateElementBlock(true)
                    /* setOpen(prev => !prev) */
                  }}
                  className="icon"
                  size={"35px"}
                />
              </div>
            </div>

          </>
          :
          <div
            className={`create_offer_block_button_wrap`}
            id="CREATE_OFFER_BLOCK_BUTTON_ID"
          >
            <span>
              Vytvoriť sekciu
            </span>
            <div className="create_element_button">
              <IoMdAdd
                onClick={() => {
                  props.onCreateElementBlock(true)
                  /* setOpen(prev => !prev) */
                }}
                className="icon"
                size={"35px"}
              />
            </div>
          </div>
        }
        {/* <div
          className="move_items_wrap"
          onClick={() => {props.onMoveItems()}}
        >
          <span>
            Presnúť položky
          </span>
          <IoMdMove
            className="icon"
            size={"35px"}
          />
        </div> */}
        </div>
      </div>
  )
}

export default PriceEditHeaderCard