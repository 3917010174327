import ItemElement from "./ItemElement"
import { useEffect, useState } from "react"
import HtmlParser from "../../services/htmlParser"

interface localProps {
  id: string
  icon: string,
  iconWidth: string,
  iconMarginRight?: string,
  page_uuid: string,
  title: string,
  list: Array<any>,
  isOpen: boolean,
  rowIndex: number,
  blockIndex: number,
  closePage: (current_page_uuid: string, rowIndex: number, blockIndex: number) => void,
  style: object,
  localConfig: any

}

const PageElement = (props: localProps) => {

  const [blocks] = useState(props.list.filter(element => element.block))
  const [blockAnimationClass, setBlockAnimationClass] = useState("")
  const handlePageOpen = (page_uuid: string) => {
    if (props.localConfig.vibrateOnClick && 'vibrate' in navigator) {
      navigator.vibrate([0, 0, 100])
    }
    props.closePage(page_uuid, props.rowIndex, props.blockIndex)
  }

  const removeBreak = (title: string) => {
    return props.localConfig.alignTitleToLeft ? title.replace(/<.*>/, ' ') : title.replace(/<.*>/, ' / ')
  }

  const handleBlockClick = (id: string) => {
    setTimeout(() => {
      const element = document.getElementById(id)!
      var headerOffset = 10
      var elementPosition = element.getBoundingClientRect().top
      var offsetPosition = elementPosition + window.pageYOffset - headerOffset

      window.scrollTo({
          top: offsetPosition,
          behavior: "smooth"
      })

    }, 200)
  }

  useEffect(() => {
    setTimeout(() => {
      setTimeout(() => {
        setBlockAnimationClass("loaded_animation")
      }, 200*props.rowIndex);
      /* intro screen load time */
    }, 1300);
  }, [])
  return (
    <div
      className={
        `panorama_page_element ${props.isOpen
          ? "panorama_page_element_open"
          : props.localConfig.alignTitleToLeft ? "panorama_page_element_close_title_in_row" : "panorama_page_element_close"} ${blockAnimationClass}`}
      id={props.id}
      style={
        {
          ...props.style,
          /* animationDelay: `${0.2*props.rowIndex}s` */
        }
      }
    >
      <div
        className="title_wrap"
        onClick={() => handlePageOpen(props.page_uuid)}
      >
        <div className="img_wrap">
          <img
            src={props.icon}
            alt=""
            style={{
              width: props.iconWidth,
              marginBottom: (props.title.includes("<br") && !props.isOpen) ? "-5px" : "0px",
              marginRight: props.iconMarginRight || "0px"
            }}
          />
        </div>
        <h1
          style={{
            marginBottom: (props.title.includes("<br") && !props.isOpen) ? "5px" : "15px",
            textAlign: (props.isOpen) ? "left" : "center"
          }}
        >
          <HtmlParser>
            {
              ((props.title.includes("<br") && props.isOpen) || props.localConfig.alignTitleToLeft)
                ? removeBreak(props.title)
                : props.title
            }
          </HtmlParser>
        </h1>
      </div>
      <div
        className="items_wrap"
        style={{
          maxHeight: (props.list && props.isOpen) ? "500000px" : "0px",
          maxWidth: (props.list && props.isOpen) ? "500000px" : "0px"
        }}
      >

        <div className="blocks_wrap">
          {
            blocks && blocks.map((element, index) =>
              <div
                className="block_item"
                key={index}
                onClick={() => {
                  handleBlockClick(element.blockId)
                }}
              >
                <element.icon
                  className="icon"
                  size={element.iconSize || "30px"}
                />
                { element.blockTitle }
              </div>
            )

          }
        </div>
        {
          props.list && props.list.map((element, index) =>
          element.type === "image"
              ? <img className="imgOffer" src={element.offerImg} alt="" />
              : <ItemElement
                key={index}
                element={element}
                localConfig={props.localConfig}
              />
          )
        }
      </div>
    </div>
  )
}

export default PageElement
