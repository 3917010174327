import uuid from "react-uuid"
import new_flag from "../../../assets/images/clients/icons/panorama/new_flag.png"
import recommended_flag from "../../../assets/images/clients/icons/panorama/recommended_flag.png"
import { BsCreditCard2BackFill } from "react-icons/bs"

export const drivers_drinks = [
 /*  {
    type: "extra",
    icon: null,
    iconSize: "45px",
    item_uuid: uuid(),
    title: ",Drivers‘ Drinks",
    descr: "",
    volume: "",
    price: "",
    noDescr: true,
    divideAfter: true,
  },
 */
  {
    item_uuid: uuid(),
    title: "VIRGIN MOJITO",
    descr: "/&nbspčerstvé limetky a mäta, Mint sirup, trstinový cukor, sóda&nbsp/",
    volume: "",
    price: "5,10 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "VIRGIN SUNSHINE",
    descr: "/&nbspmango pyré, vanilla sirup, vinea biela, sóda&nbsp/",
    volume: "",
    price: "5,20 €",
    flagBehindTitle: true,
    flag: recommended_flag,
    img: require("../../../assets/images/clients/items/Panorama/miesane_napoje/sunshine.jpg"),
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "OCEAN",
    descr: "/&nbspCuracao Blue sirup, vinea biela, motýlí čaj&nbsp/",
    volume: "",
    price: "5,20 €",
    img: require("../../../assets/images/clients/items/Panorama/miesane_napoje/ocean.jpg"),
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "PASSIONSTAR MARTINI",
    descr: "/&nbspvanilla sirup, passionfruit pyré, ananásová šťava, sóda&nbsp/",
    volume: "",
    flagBehindTitle: true,
    flagHeight: "16px",
    flag: new_flag,
    price: "5,30 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "RUBY",
    descr: "/&nbspNochino Ruby (nealko aperitív), tonic&nbsp/",
    volume: "",
    flagBehindTitle: true,
    flag: new_flag,
    flagHeight: "16px",
    price: "5,10 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "BOTANICAL",
    descr: "/&nbspNochino Botanic (nealko aperitív), tonic&nbsp/",
    volume: "",
    flagBehindTitle: true,
    flagHeight: "16px",
    flag: new_flag,
    price: "5,10 €",
    divideAfter: true
  },
  {
    type: "extra",
    icon: BsCreditCard2BackFill,
    iconSize: "25px",
    item_uuid: uuid(),
    title: "Možná platba kartou",
    descr: "",
    volume: "",
    price: "",
    noDescr: true,
    divideAfter: false,
    lastElement: true
  },
]