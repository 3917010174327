import uuid from "react-uuid"
import { FaInfoCircle } from "react-icons/fa";
export const kava = [

  {
    item_uuid: uuid(),
    title: "Ristretto",
    descr: "krátke espresso - 8 g",
    expandableDescr: [
      "Pripravuje sa z 8 g kvalitnej kávy.",
      "Doba extrakcie je len 15 sekúnd.",
      "Objem nápoja je 20 ml."
    ],
    volume: "15 ml",
    price: "2,50 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Espresso",
    descr: "8 g",
    expandableDescr: [
      "Pripravuje sa z 8 g kávy a 25 ml vody.",
      "Doba extrakcie je okolo 30 sekúnd.",
      "Ide vlastne o malú šálku silnej kávy."
    ],
    volume: "25 ml",
    price: "2,65 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Espresso Lungo",
    descr: "espresso + horúca voda - 8 g",
    expandableDescr: [
      "Označované aj ako espresso predĺžené teplou vodou.",
      "Pripravuje sa z 8 g mletých kávových zŕn s dvojnásobným objemom vody.",
    ],
    volume: "25 ml + 50 ml",
    price: "2,65 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Double Espresso",
    descr: "dvojité espresso - 16 g",
    volume: "50 ml",
    price: "3,40 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Batch brew",
    descr: "filtrovaná káva",
    volume: "150 ml",
    price: "3,45 €",
    expandableDescr: [
      "Rýchly kávový \"dopping\" v pomalom zážitku pitia kávy",
      "Tiež skvelé, keď sa ponáhľate a chcete kávu so sebou.",
      "Batch máme väčšinu času už pre vás pripravený, stačí len naliať do šálky.",
    ],
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Filtrovaná káva pre 1",
    descr: "20 g",
    volume: "220 ml",
    price: "4,95 €",
    expandableDescr: [
      "Keď espresso je majster intenzity na pár hltov, filter je jeho pomalý brat.",
      "Vie si život vychutnávať v jemných dúškoch a ako káva pomaly chladne, otvárajú sa nové vône a chuťové profily",
      "Na oddychové popoludnie alebo lenivé víkendové ráno je tento druh pitia kávy váš najlepší spoločník."

    ],
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Filtrovaná káva pre 2",
    descr: "30 g",
    volume: "330 ml",
    price: "8,25 €",
    divideAfter: true
  },
  /*  */
  /*  */
  /*
  {
    item_uuid: uuid(),
    title: "Írska káva",
    descr: "espresso + whiskey + šľahačka - 8 g",
    volume: "170 ml",
    price: "3,50 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Babyccino",
    descr: "napenené mlieko",
    volume: "150 ml",
    price: "2,10 €",
    divideAfter: false
  },
   */
  {
    type: "extra",
    icon: FaInfoCircle,
    iconColor: "#201F1D",
    iconSize: "25px",
    item_uuid: uuid(),
    title: "Pri objednávke so sebou",
    descr: `
    Účtujeme si poplatok za obaly v sume<br/>
    - 0,10 € - pohár<br/>
    - 0,60 € - malá krabička<br/>
    - 1,10 € - veĺká krabica<br/>`,
    volume: "",
    price: "",
    noDescr: false,
    divideAfter: false,
    lastElement: false
  },
]