import { IoIosArrowDown } from "react-icons/io";
import { useEffect, useState } from "react"
import { SelectorProps } from "../../PropTypes/AdminDashboardProps";

const SelectorElement = (props: SelectorProps) => {


  const [open, setOpen] = useState(false)
  const [selectedType, setSelectedType] = useState({
    title: "props.default_title",
    value: "props.default_value",
    order: 0
  })

  const handleSelect = (selection: any) => {
    setSelectedType((prev) => prev = selection)
    setOpen(false)
  }

  useEffect(() => {
    props.onSelect(selectedType.value)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedType])

  useEffect(() => {
    setSelectedType({
      title: props.list[0]?.title,
      value: props.list[0]?.value,
      order: 0
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setSelectedType({
      title: props.list.filter(element => element.value === props.selectedElement)[0]?.title,
      value: props.list.filter(element => element.value === props.selectedElement)[0]?.value,
      order: 0
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.selectedElement])

  return (
    <>
      <div className="selector_element_wrap">
        <div
          className="selector"
          onClick={() => {setOpen(prev => !prev)}}
        >
          <span  key={selectedType?.title}>
          {
            selectedType.title ? selectedType.title : props.list[0]?.title
          }

          </span>
        </div>
        <IoIosArrowDown
          className="icon"
          size={"20px"}
          style={{
            transform: `${open ? "rotate(180deg)" : "rotate(0deg)"}`,
            transition: "0.3s"
          }}
          onClick={() => {setOpen(prev => !prev)}}
        />
        <div
          className={`selector_list ${open ? "selector_list_open" : ""}`}
        >
          {
            props.list.map((type, index) =>
              <div
                key={index}
                className={`selector_element ${type.value === selectedType?.value ? "selector_element_active" : ""}`}
                onClick={() => handleSelect(type)}
              >
                {type.title}
              </div>
            )
          }

        </div>
      </div>
    </>

  )
}

export default SelectorElement