import { useAuth } from "../../context/AuthContext";
import { useEffect, useState } from "react";
import axios from 'axios';
import { useCookies } from "react-cookie";
import MobileViewCard from "../adminComponents/MobileViewCard";
import PriceEditHeaderCard from "../adminComponents/ItemElements/PriceEditHeaderCard";
import { ConfirmationModalType, OfferElement, OfferSectionElement, OpenedSettingsTabIndexType, SupplierOffer } from "../../PropTypes/AdminDashboardProps";
import NavigationCard from "../adminComponents/NavigationCard";
import LogoutCard from "../adminComponents/LogoutCard";
/* import StatsCard from "../adminComponents/StatsCard"; */
import ItemElementRowDragable from "../adminComponents/ItemElements/ItemElementRowDragable";
import ItemElementHeader from "../adminComponents/ItemElements/ItemElementHeader";
import { copyToClipboard, dynamicListHeightSetup, elementValidation, invokeAlertComponent } from "../../services/userInteractionService";
import ConfirmationModal from "../adminComponents/ConfirmationModal";
/* import AlertComponent from "../adminComponents/AlertComponent"; */
import { TbAlertTriangle } from "react-icons/tb";
import CreateElementButton from "../adminComponents/ItemElements/CreateElementButton";
import CreateElementModal from "../adminComponents/ItemElements/CreateElementModal";
import CreateElementBlockModal from "../adminComponents/OfferBlockElements/CreateElementBlockModal";
import { IoMdAdd } from "react-icons/io";
import NotAvailableComponent from "../adminComponents/NotAvailableComponent";
import InvoiceAndSubscriptionCard from "../adminComponents/InvoiceElements/InvoiceAndSubscriptionCard";
import HtmlParser from "../../services/htmlParser";
import { RiSettings4Fill } from "react-icons/ri";
import { TbTrash } from "react-icons/tb";
import { FiRefreshCcw } from "react-icons/fi";
import { FaEdit, FaFacebookSquare, FaSave, FaImage, FaCheckCircle } from "react-icons/fa";
import { FaPhone } from "react-icons/fa";
import { MdEmail, MdAddBox } from "react-icons/md";
import { FaRegCopy } from "react-icons/fa";
import { RiInstagramFill } from "react-icons/ri";
import { TbWorld } from "react-icons/tb";
import { TbReplaceFilled } from "react-icons/tb";
import uuid from "react-uuid"
import { RxSwitch } from "react-icons/rx";


import { AiOutlineLoading } from "react-icons/ai";

import {
  closestCorners,
  DndContext,
  useDroppable,
  useDraggable,
  pointerWithin,
  DragOverlay
} from "@dnd-kit/core"
import {CSS} from '@dnd-kit/utilities';

import { arrayMove, SortableContext, verticalListSortingStrategy } from "@dnd-kit/sortable";
import HowToCreateElement from "../adminComponents/HowTo/HowToCreateElement";
import HowToCreateBlock from "../adminComponents/HowTo/HowToCreateBlock";
import HowToMoveLastItem from "../adminComponents/HowTo/HowToMoveLastItem";
import HowToKnowIcons from "../adminComponents/HowTo/HowToKnowIcons";

const AdminDashboardCard = () => {



  /* Layout change */
  const defaultLayoutObject_element = {
    uuid: null,
    block_title_long: null,
    block_position_row: null,
    block_position_column: null,
  }
  const defaultLayoutObject_location = {
    row: null,
    column: null
  }
  const defaultLayoutObject = {
    selected_element: defaultLayoutObject_element,
    location: defaultLayoutObject_location
  }
  const [layoutUpdate, setLayoutUpdate] = useState(defaultLayoutObject)

  const [draggedItemIndex/* , setDraggedItemIndex */] = useState(-1)
  const [draggingOverItemIndex/* , setDraggingOverItemIndex */] = useState(-1)
  const [clients, setClients] = useState([])
  const [invoices, setInvoices] = useState([])
  const [unpaidInvoice, setUnpaidInvoice] = useState(false)
  const [cookie] = useCookies(['mojeqr_access_token', 'mojeqr_refresh_token']);
  const [openedTabIndex, setOpenedTabIndex] = useState<OpenedSettingsTabIndexType>(0)
  const [latestOrderNumber, setLatestOrderNumber] = useState(0)
  const [openedTabIndexAnimationInvoker, setOpenedTabIndexAnimationInvoker] = useState<OpenedSettingsTabIndexType>(0)
  const [sectionList, setSectionList] = useState([])
  const [elementList, setElementList] = useState<OfferElement[]>([])
  const [elementListLoading/* , setElementListLoading */] = useState(false)
  const [supplierOffers, setSupplierOffers] = useState<SupplierOffer[]>([])
  const [filteredElementList, setFilteredElementList] = useState<OfferElement[]>([])
  const [temporaryReorderedElementList, setTemporaryReorderedElementList] = useState<OfferElement[]>([])
  const [selectedSection, setSelectedSection] = useState<OfferSectionElement | null>()

  const [selectedSectionToRemove, setSelectedSectionToRemove] = useState<OfferSectionElement | null>(null)

  const [offerBlockImages, setOfferBlockImages] = useState([])

  const [confirmationModal, setConfirmationModal] = useState<ConfirmationModalType>({isOpen: false, modalText: "", type: "Item"})
  const [createElementModal, setCreateElementModal] = useState(false)
  const [createElementBlockModal, setCreateElementBlockModal] = useState(false)
  const [updateElementBlockModal, setUpdateElementBlockModal] = useState(false)
  const [movingMode, setMovingMode] = useState(false)
  const [updateElementModal, setUpdateElementModal] = useState(false)
  const [selectedItemElement, setSelectedItemElement] = useState<OfferElement | null>(null)

  const [offerBlockRemoveMode, setOfferBlockRemoveMode] = useState<boolean>(false)
  const [offerBlockEditMode, setOfferBlockEditMode] = useState<boolean>(false)

  //@ts-ignore
  const { logout, setUser/* , user */ } = useAuth()


  const layoutEditCol:OfferSectionElement = {
    uuid: uuid(),
    block_image: "null",
    block_title_short: "Vložte sekciu",
    block_position_row: 1,
    block_position_column: "BOTH_COLUMNS",
  }

  const layoutEditRowTemplate: any[] = [
    [layoutEditCol/* , layoutEditCol],
    [layoutEditCol */],
  ]

  const [layoutEditRows, setLayoutEditRows] = useState(layoutEditRowTemplate)




  const DraggableBlock = (props: any) => {
    const { attributes, listeners, setNodeRef, transform, active } = useDraggable({
      id: props.block.uuid,
      disabled: props.disabled
    });


    const style = {
      zIndex: 999,
      transform: CSS.Translate.toString(transform),
    };
    const styleD = {
      opacity: 0
    };
    return (
      <>
        <div
          ref={setNodeRef}
          /* {...attributes} */
          {...listeners}
          style={style}
          id={`DROPPABLE_COL-${props.block.uuid}`}
          className="drabbable"
        >
          {props.children}
        </div>
      {active && (
        <DragOverlay>
          <div
            ref={setNodeRef}
            {...attributes}
            {...listeners}
            style={styleD}
            id={`DROPPABLE_COL-${props.block.uuid}`}
            className="drabbable"
          >
            {props.children}
          </div>
        </DragOverlay>
      )}
      </>
    );
  };







  const DroppableCol = (props: any) => {

    const { setNodeRef, isOver } = useDroppable({id: props.id,});

    // Dynamické štýly na základe stavu "isOver" alebo "disabled"
    const style = {
      pointerEvents: props.disabled ? 'none' : 'auto', // Zamedzi interakciám, ak je disabled
      outline: isOver ? '1px dashed #6134c2' : 'none',
      transform: isOver ? "scale(1.02)" : "scale(1)",
      transition: '0.3s ease',
  };

    return (
      <div
        ref={setNodeRef}
        //@ts-ignore
        style={style}
        className="mobile_layout_col"
        onClick={props.onClick}
      >
        {props.children}
      </div>
    );
  };

  const getElementPosition = (id: any) => {
    return filteredElementList.findIndex(element => element.uuid === id)
  }

const prepareLayoutForUpdate = (offerBlocksList:OfferSectionElement[]) => {


  const grouped = offerBlocksList.reduce((acc, block) => {
    // Find the position row group
    //@ts-ignore
    const rowGroup = acc.find(group => group[0].block_position_row === block.block_position_row);

    if (rowGroup) {
      //@ts-ignore
      rowGroup.push(block); // Add the entire object to the existing group
    } else {
      //@ts-ignore
      acc.push([block]); // Create a new group with the current block if not found
    }

    return acc;
  }, []);

  setLayoutEditRows(grouped)
}


  const handleLayoutSave = () => {


    const flattenedArray = layoutEditRows.flatMap(group =>
      //@ts-ignore
      group.map(block => ({
        uuid: block.uuid,
        block_position_row: block.block_position_row,
        block_position_column: block.block_position_column
      }))
    )

    if (flattenedArray.length !== sectionList.length) {
      invokeAlertComponent("INFORMATION_ALERT_COMPONENT",`Umiestnite prosím všetky sekcie!`, "ALERT")
      return
    }

    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${cookie.mojeqr_access_token}`
    }

    handleLoading("start")
    // Make the POST request to create a new item
    axios.put(`${process.env.REACT_APP_MOJEQR_API_URL}/api/offer-blocks/`, flattenedArray, { headers })
      .then(response => {
        handleLoading("end")
        invokeAlertComponent("INFORMATION_ALERT_COMPONENT",`Upravili ste rozloženie ponuky!`)
        // getSectionList(setSectionList)
        // get one list element
        console.log(response.data)

      })
      .catch(error => {
        handleLoading("end")
        const errors = Object.keys(error.response.data)
          invokeAlertComponent("INFORMATION_ALERT_COMPONENT",`Pri upravovaní rozloženia sa niečo pokazilo. Skúste to znovu!`, "ALERT")
      })

  }

  const handleDragLayoutEnd = (event: any) => {
    console.log(event)
    const { active, over } = event;

    if (!over) return;

    const sourceId = active.id;
    const targetId = over.id;

    if (targetId) {
      // Remove from sectionList
      //@ts-ignore
      const draggedBlock = sectionList.find((block) => block.uuid === sourceId);

      console.log(draggedBlock)

      const targetBlockArray = layoutEditRows.filter((innerArray) => innerArray.filter((innerElement: any) => innerElement.uuid === targetId)[0])[0]
      const targetBlock = targetBlockArray.find((block: any) => block.uuid === targetId)
      console.log("target")
      console.log(targetBlock)
      //@ts-ignore
      draggedBlock.block_position_row = targetBlock.block_position_row
      //@ts-ignore
      draggedBlock.block_position_column = targetBlock.block_position_column


      setLayoutEditRows((prevLayoutEditRows) => {
        let found = false;

        // Vytvoríme nový stav (kopiu predchádzajúceho stavu)
        const updatedLayoutEditRows = prevLayoutEditRows.map((subArray) =>
          subArray.map((targetObject: any) => {
            if (
              targetObject.uuid === targetBlock.uuid
            ) {
              found = true;
              // Vrátime aktualizovaný objekt
              return {
                ...targetObject,
                //@ts-ignore
                ...draggedBlock
                // here do not update row and col
              };
            }
            // Ak nie je zhodný, ponecháme pôvodný objekt
            return targetObject;
          })
        );

        if (found) {
          console.log("Cieľový objekt bol aktualizovaný.");
        } else {
          console.log("Objekt s daným UUID nebol v cieľovom zozname nájdený.");
        }

        return updatedLayoutEditRows;
      });
    }
  };

  const handleDragEnd = (event: any) => {
    const {active, over} = event
    if (active.id === over.id) return

    setFilteredElementList((element) => {
      const originalPos = getElementPosition(active.id)
      const newPos = getElementPosition(over.id)
      handleSwitchElements(active.id, over.id, filteredElementList[originalPos].item_name)
      return arrayMove(filteredElementList, originalPos, newPos)
    })

  }


  useEffect(() => {
    console.log(layoutEditRows)
  }, [layoutEditRows])



  const handleLogout = () => {
    logout()
  }

  const handleLoading = (type: "start" | "end") => {
    const body = document.getElementById("root")
    const indicator = document.getElementById("ADMIN_PANEL_LOADING_INDICATOR")
    //@ts-ignore
    if (body) {
      if (type === "start") {
        body.style.pointerEvents = "none"
        if (indicator) {
          indicator.style.visibility = "visible"
        }
      } else {
        body.style.pointerEvents = "auto"
        if (indicator) {
          setTimeout(() => {
            indicator.style.visibility = "hidden"
          }, 300);
        }
      }
    }

  }

  const handleOfferBlockSelect = (selectedOfferBlock: OfferSectionElement) => {
    if (selectedOfferBlock.uuid === layoutUpdate.selected_element.uuid) {
      setLayoutUpdate({...layoutUpdate, selected_element: defaultLayoutObject_element})
    } else {
      //@ts-ignore
      setLayoutUpdate({...layoutUpdate, selected_element: selectedOfferBlock})
    }
  }



  const handleRequestDeleteItemConfirmation = (selectedElement: OfferElement) => {
    setConfirmationModal({
      type: "Item",
      isOpen: true,
      modalText: `Naozaj chcete zmazať položku </br> <b>${selectedElement.item_name}</b> ?`
    })
    setSelectedItemElement(selectedElement)

  }

  const handleRequestDeleteOfferBlockConfirmation = (selectedElement: OfferSectionElement) => {
    setConfirmationModal({
      type: "OfferBlock",
      isOpen: true,
      modalText: `Naozaj chcete zmazať sekciu </br> <b>${selectedElement.block_title_short}</b> ?`
    })
    setSelectedSectionToRemove(selectedElement)
    /* setSelectedItemElement(selectedElement) */

  }



  const animationTimer = 100
  const handlePriceEditClick = () => {
    const index = 0
    setOpenedTabIndexAnimationInvoker(index)
    setTimeout(() => {
      setOpenedTabIndex(index)
    }, animationTimer);
  }
  const handleLayoutEditClick = () => {
    const index = 1
    setOpenedTabIndexAnimationInvoker(index)
    setTimeout(() => {
      setOpenedTabIndex(index)
    }, animationTimer);
  }
  const handleImageGalleryClick = () => {
    const index = 2
    setOpenedTabIndexAnimationInvoker(index)
    setTimeout(() => {
      setOpenedTabIndex(index)
    }, animationTimer);
  }
  const handleContactEditClick = () => {
    const index = 3
    setOpenedTabIndexAnimationInvoker(index)
    setTimeout(() => {
      setOpenedTabIndex(index)
    }, animationTimer);
  }
  const handleInvoicesSubscriptionClick = () => {
    const index = 4
    setOpenedTabIndexAnimationInvoker(index)
    setTimeout(() => {
      setOpenedTabIndex(index)
    }, animationTimer);
  }
  const handleHowToClick = () => {
    const index = 5
    setOpenedTabIndexAnimationInvoker(index)
    setTimeout(() => {
      setOpenedTabIndex(index)
    }, animationTimer);
  }

  const handleSearch = (element_name: string) => {
    setFilteredElementList(elementList
      .filter(element => element.item_name?.toLowerCase().includes(element_name.toLowerCase()))
      .sort((a, b) => a.item_order - b.item_order))
  }


  const handleSwitchElements = async (original_element_uuid: string, new_element_uuid:string, item_name: string) => {
    handleLoading("start")
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${cookie.mojeqr_access_token}`
    }
    axios.patch(`${process.env.REACT_APP_MOJEQR_API_URL}/api/items/${original_element_uuid}/?switch_with_new_uuid=${new_element_uuid}&offer_block_uuid=${selectedSection?.uuid}`, {}, { headers })
    .then((response) => {
      handleLoading("end")
      invokeAlertComponent("INFORMATION_ALERT_COMPONENT", `Presunuli ste položku ${item_name}`)
      getSectionList(selectedSection?.uuid)
    }).catch((error) => {
      handleLoading("end")
      console.log(error)
    })
  }

  const handleUpdateElement = async (data: any) => {
    console.log("handle update element")
    console.log(data.data.item_expanded_image)
    handleLoading("start")
    const headers = {
      /* "Content-Type": "application/json", */
      Authorization: `Bearer ${cookie.mojeqr_access_token}`, // No need for Content-Type; Axios handles it for FormData
    };

    try {
      const formData = new FormData()

      // Append all fields from data.data to FormData
      Object.keys(data.data).forEach((key) => {
        const value = data.data[key];
        if (value !== undefined && value !== null) {
          // If the key is 'item_expanded_image' and the value is a File, append it as a file
          if (key === 'item_expanded_image' && value instanceof File) {
            formData.append(key, value);  // Append file
          } else {
            formData.append(key, value)
          }
        }
      });

      // Make the PATCH request with FormData
      await axios.patch(`${process.env.REACT_APP_MOJEQR_API_URL}/api/items/${data.uuid}/?offer_block_uuid=${selectedSection?.uuid}`, formData, { headers })
      handleLoading("end")
      // Success alert and refresh section
      invokeAlertComponent("INFORMATION_ALERT_COMPONENT", data.alert_text)
      setUpdateElementModal(false)
      /* getSectionList(selectedSection?.uuid) */
      getSectionById(selectedSection?.uuid)
      /* getSectionList(setSectionList) */
    } catch (error) {
      handleLoading("end")
      console.error("Error updating element:", error);
      invokeAlertComponent(
        "INFORMATION_ALERT_COMPONENT",
        "Chyba pri aktualizácii položky. Skúste to znovu!",
        "ALERT"
      )
    }
  }


  const getSupplierOffers = (belongs_to: string) => {
    handleLoading("start")
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${cookie.mojeqr_access_token}`
    }
    axios.get(`${process.env.REACT_APP_MOJEQR_API_URL}/api/supplier-offers/?belongs_to=${belongs_to}`, { headers }).then((response: any) => {
      setSupplierOffers(response.data)
      console.log(response.data)
      handleLoading("end")
    }).catch((error) => {
      handleLoading("end")
      console.log(error)
    })
  }


  const getOfferBlockImages = () => {
    handleLoading("start")
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${cookie.mojeqr_access_token}`
    }
    axios.get(`${process.env.REACT_APP_MOJEQR_API_URL}/api/offer-block-images/`, { headers }).then((response: any) => {
      setOfferBlockImages(response.data)
      handleLoading("end")
    }).catch((error) => {
      handleLoading("end")
      console.log(error)
    })
  }


  const handleCreateElement = async (element: OfferElement) => {
    const headers = {
      Authorization: `Bearer ${cookie.mojeqr_access_token}`,
    };

    // Use FormData to construct your payload
    const formData = new FormData();

    formData.append("belongs_to", selectedSection?.belongs_to || "");
    /* formData.append("item_order", selectedSection?.offer_list.length?.toString() || "0"); */
    formData.append("item_order", selectedSection?.offer_list.length?.toString() || "1");
    formData.append("item_type", element.item_type || "");
    formData.append("item_name", element.item_name || "");
    formData.append("item_description", element.item_description || "");
    formData.append("item_price", element.item_price?.toString() || "");
    formData.append("item_volume", element.item_volume || "");
    formData.append("item_is_recommended", element.item_is_recommended?.toString() || "false");
    formData.append("item_without_description", element.item_without_description?.toString() || "false");
    /* formData.append("flagBehindTitle", "false"); */
    formData.append("item_is_visible", element.item_is_visible?.toString() || "true");
    formData.append("item_expanded_description", element.item_expanded_description || "");
    formData.append("divide_after", element.divide_after?.toString() || "false");
    formData.append("use_flag_1", element.use_flag_1?.toString() || "false");
    formData.append("use_flag_2", element.use_flag_2?.toString() || "false");
    formData.append("use_flag_3", element.use_flag_3?.toString() || "false");
    formData.append("flag_behind_title", element.flag_behind_title?.toString() || "false");
    formData.append("item_icon", element.item_icon || "");

    // Append the image file (selectedFile) properly
    //@ts-ignore
    if (element.item_expanded_image instanceof File) {
      formData.append("item_expanded_image", element.item_expanded_image);
    } else {
      console.warn("item_expanded_image is not a file");
    }

    handleLoading("start")
    // Make the POST request
    axios
      .post(`${process.env.REACT_APP_MOJEQR_API_URL}/api/items/?offerblock_uuid=${selectedSection?.uuid}`, formData, { headers })
      .then((response) => {
        handleLoading("end")
        invokeAlertComponent(
          "INFORMATION_ALERT_COMPONENT",
          `Vytvorili ste položku ${element?.item_name}!`
        );
        // Fetch updated section
        getSectionById(selectedSection?.uuid);
      })
      .catch((error) => {
        handleLoading("end")
        console.error("Error uploading item:", error);
        invokeAlertComponent(
          "INFORMATION_ALERT_COMPONENT",
          `Pri vytváraní položky sa niečo pokazilo. Skúste to znovu!`,
          "ALERT"
        );
      });
  };

  const getSectionByIdForSelector = (section_uuid: any) => {
    const local_section = sectionList.filter((section: any, index: number) => section.uuid === section_uuid)[0]
    if (local_section) {
      //@ts-ignore
      setElementList(local_section.offer_list)
      setSelectedSection((prevState: any) => ({
        ...prevState,
        //@ts-ignore
        offer_list: local_section.offer_list
      }))
    }
  }

  const getSectionById = (section_uuid: any) => {
    /* setSelectedSection(null) */
    /* setElementListLoading(true) */
    handleLoading("start")
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${cookie.mojeqr_access_token}`
    }
    if (section_uuid) {
      axios.get(`${process.env.REACT_APP_MOJEQR_API_URL}/api/offer-blocks/?uuid=${section_uuid}`, { headers }).then((response: any) => {
        /* setElementListLoading(false) */
        handleLoading("end")
        setElementList(response.data.offer_list)
        setSelectedSection((prevState: any) => ({
          ...prevState,
          offer_list: response.data.offer_list
        }))
      }).catch((error) => {
        handleLoading("end")
        /* setElementListLoading(false) */
        console.log(error)
      })
    } else {
      handleLoading("end")
      /* setElementListLoading(false) */
    }
  }


  const handleDeleteItem = async () => {
    handleLoading("start")
    setConfirmationModal({...confirmationModal, isOpen: false, type: "Item"})
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${cookie.mojeqr_access_token}`
    }
    const item_uuid = selectedItemElement?.uuid

    axios.delete(`${process.env.REACT_APP_MOJEQR_API_URL}/api/items/?uuid=${item_uuid}`, { headers }).then((resp) => {
      handleLoading("end")
      invokeAlertComponent("INFORMATION_ALERT_COMPONENT",`Zmazali ste položku ${selectedItemElement?.item_name}!`)
      setSelectedItemElement(null)
      console.log(selectedSection)
      getSectionById(selectedSection?.uuid)
      /* getSectionList(setSectionList) */
      // update list
    }).catch((error) => {
      handleLoading("end")
      console.log(error);
      setSelectedItemElement(null)
    })



  }

  const handleDeleteOfferBlock = async () => {
    setConfirmationModal({...confirmationModal, isOpen: false, type: "Item"})
    handleLoading("start")
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${cookie.mojeqr_access_token}`
    }
    //@ts-ignore
    const item_uuid = selectedSectionToRemove?.uuid
    axios.delete(`${process.env.REACT_APP_MOJEQR_API_URL}/api/offer-blocks/?uuid=${item_uuid}`, { headers }).then((resp) => {
      handleLoading("end")
      invokeAlertComponent("INFORMATION_ALERT_COMPONENT",`Zmazali ste položku ${selectedSectionToRemove?.block_title_short}!`)
      setSelectedSectionToRemove(null)
      getSectionList(setSectionList)
    }).catch((error) => {
      handleLoading("end")
      console.log(error);
      invokeAlertComponent("INFORMATION_ALERT_COMPONENT",`Pri mazaní položky ${selectedSectionToRemove?.block_title_short} sa niečo pokazilo!`)
      setSelectedSectionToRemove(null)
    })



  }


  const getBusinessEstablishments = async (setClients: any) => {
    handleLoading("start")
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${cookie.mojeqr_access_token}`
    }
    try {
      const response = await axios.get(`${process.env.REACT_APP_MOJEQR_API_URL}/api/business-establishment/`, { headers })
      handleLoading("end")
      setClients(response.data);
      getInvoices(response.data[0].uuid)
      getSupplierOffers(response.data[0].uuid)
    } catch (error) {
      handleLoading("end")
      console.log(error);
    }
  }


  const getInvoices = async (business_uuid: string) => {
    handleLoading("start")
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${cookie.mojeqr_access_token}`
    }
    try {
      const response = await axios.get(`${process.env.REACT_APP_MOJEQR_API_URL}/api/invoices/?business_uuid=${business_uuid}`, { headers })
      handleLoading("end")
      setInvoices(response.data.sort((a: any, b: any) =>
        new Date(a.due_date).getTime() - new Date(b.due_date).getTime()
      ))
      if (response.data.some((invoice: any, index: number) => invoice.status === "UNPAID" || invoice.status === "PENDING")) {
        setUnpaidInvoice(true)
      } else {
        setUnpaidInvoice(false)
      }
    } catch (error) {
      handleLoading("end")
      console.log(error);
    }
  }

  const getUserInfo = async (setUser: any) => {
    handleLoading("start")
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${cookie.mojeqr_access_token}`
    }
    try {
      const response = await axios.get(`${process.env.REACT_APP_MOJEQR_API_URL}/api/user-info/`, { headers })
      handleLoading("end")
      setUser(response.data);
    } catch (error) {
      handleLoading("end")
      console.log(error);
    }
  }

  const getSectionList = async (callback: any) => {
    handleLoading("start")
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${cookie.mojeqr_access_token}`
    }

    axios.get(`${process.env.REACT_APP_MOJEQR_API_URL}/api/offer-blocks/`, { headers }).then((response) => {
      handleLoading("end")
      setSectionList(response.data)

      prepareLayoutForUpdate(response.data)
      // check if there is something wrong with order
      /* setElementList(response.data.filter((section: any, index: number) => section.uuid === selectedSection?.uuid)[0].offer_list) */
    }).catch((error) => {
      handleLoading("end")
      console.log(error)
      setSectionList([])
    })
  }





  const handleUpdateElementBlockModal = async (data: any) => {
    handleLoading("start")
    const headers = {
      Authorization: `Bearer ${cookie.mojeqr_access_token}`, // No need for Content-Type; Axios handles it for FormData
    };
    try {
      const formData = new FormData()

      // Append all fields from data.data to FormData
      Object.keys(data.data).forEach((key) => {
        const value = data.data[key];
        if (
          value !== undefined &&
          value !== null &&
          key !== "offer_list" &&
          key !== (data.data.block_image.icon && "block_image")
        ) {
            formData.append(key, value)
        }
      });

      // Make the PATCH request with FormData
      await axios.patch(`${process.env.REACT_APP_MOJEQR_API_URL}/api/offer-blocks/?uuid=${data.uuid}`, formData, { headers })
      handleLoading("end")
      // Success alert and refresh section
      setUpdateElementBlockModal(false)
      invokeAlertComponent("INFORMATION_ALERT_COMPONENT", data.alert_text)
      getSectionList(selectedSection?.uuid)
    } catch (error) {
      console.error("Error updating element:", error);
      handleLoading("end")
      invokeAlertComponent(
        "INFORMATION_ALERT_COMPONENT",
        "Chyba pri aktualizácii položky. Skúste to znovu!",
        "ALERT"
      )
    }
  }


  const handleCreateOfferBlock = (newOfferBlock: any) => {
    const headers = {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${cookie.mojeqr_access_token}`
    }

    //@ts-ignore
    const offerBlockExistOnFullRow = sectionList.some(element => (element.block_position_row === newOfferBlock.block_position_row && element.block_position_column === "BOTH_COLUMNS"))
    //@ts-ignore
    const offerBlockExistOnRow = sectionList.some(element => (element.block_position_row === newOfferBlock.block_position_row))
    //@ts-ignore
    const offerBlockExistOnLeftColumn = sectionList.some(element => element.block_position_column === newOfferBlock.block_position_column)

    if (offerBlockExistOnFullRow) {
      invokeAlertComponent("INFORMATION_ALERT_COMPONENT",`Na tomto riadku už máte uloženú sekciu, skúste iný riadok!`, "ALERT")
      return
    }
    else if (offerBlockExistOnRow) {
      if (offerBlockExistOnLeftColumn) {
        invokeAlertComponent("INFORMATION_ALERT_COMPONENT",`Na tejto pozícií v riadku už máte uloženú sekciu, skúste inú pozíciu riadku!`, "ALERT")
        return
      }
    }
    handleLoading("start")
    // Make the POST request to create a new item
    axios.post(`${process.env.REACT_APP_MOJEQR_API_URL}/api/offer-blocks/`, newOfferBlock, { headers })
      .then(response => {
        handleLoading("end")
        invokeAlertComponent("INFORMATION_ALERT_COMPONENT",`Vytvorili ste sekciu ${newOfferBlock?.block_title_short}!`)
        // getSectionList(setSectionList)
        // get one list element
        getSectionList(setSectionList)

      })
      .catch(error => {
        handleLoading("end")
        const errors = Object.keys(error.response.data)
        if (errors.includes("block_image")) {
          invokeAlertComponent("INFORMATION_ALERT_COMPONENT",` Sekcia nebola vytvorená. Zabudli ste vybrať ikonku!`, "ALERT")
        } else {
          invokeAlertComponent("INFORMATION_ALERT_COMPONENT",`Pri vytváraní sekcie sa niečo pokazilo. Skúste to znovu!`, "ALERT")
        }
      })


    setCreateElementBlockModal(true)
  }

  const handleItemDragStart = (item: OfferElement | null) => {
    if (item) {
      setTemporaryReorderedElementList([...filteredElementList])
      /* console.log(`dragging item: ${item.item_name}`) */
    }
  }

  const handleItemDragEnd = (original_element: any) => {
    if(filteredElementList[draggingOverItemIndex].uuid) {
      //@ts-ignore
      handleSwitchElements(original_element.uuid, filteredElementList[draggingOverItemIndex].uuid, original_element.item_name)
      /* filteredElementList[draggingOverItemIndex].uuid */
    }
  }

  const handleMoveItemsTemporarily = (item_original_index: number, item_new_index: number) => {
    if (item_original_index !== -1 && item_new_index !== -1) {
      // Skontroluj, či sú indexy v rámci platného rozsahu
      if (item_original_index < 0 || item_original_index >= filteredElementList.length || item_new_index < 0 || item_new_index >= filteredElementList.length) {
        console.log("Jeden alebo oba indexy sú mimo platného rozsahu.");
        return;
      }

      // Ak sú indexy rovnaké, nič sa nezmení
      if (item_original_index === item_new_index) {
        return;
      }

      const newList = [...temporaryReorderedElementList];

      // Ak je nový index menší ako pôvodný (presúvame položku hore)
      if (item_new_index < item_original_index) {
        const [removedItem] = newList.splice(item_original_index, 1); // Odstránime pôvodnú položku
        newList.splice(item_new_index, 0, removedItem); // Vložíme ju na nové miesto
      }
      // Ak je nový index väčší ako pôvodný (presúvame položku nižšie)
      else if (item_new_index > item_original_index) {
        const [removedItem] = newList.splice(item_original_index, 1); // Odstránime pôvodnú položku
        newList.splice(item_new_index, 0, removedItem); // Vložíme ju na nové miesto
      }

      // Aktualizuj stav so zmeneným zoznamom
      // POYOR KED TO PRESUVAS TAK ZE TO VIDNO TAK SA TAM NIECO SERE
      /* setFilteredElementList(newList); */
    }
  };

  const handleSwitchItems = (item_original_index: number, item_new_index: number) => {

    if (item_original_index !== -1 && item_new_index !== -1) {
      // Ensure indexes are within the bounds of the array
      if (item_original_index < 0 || item_original_index >= filteredElementList.length || item_new_index < 0 || item_new_index >= filteredElementList.length) {
        throw new Error("One or both indexes are out of bounds.");
      }

      // Create a new array with the switched elements
      const newList = [...temporaryReorderedElementList];
      [newList[item_original_index], newList[item_new_index]] = [newList[item_new_index], newList[item_original_index]];

      // Update the state with the new list
      setFilteredElementList(newList);
    }
  }

  useEffect(() => {
    if (draggingOverItemIndex !== -1 && draggingOverItemIndex !== -1) {
      handleMoveItemsTemporarily(draggedItemIndex, draggingOverItemIndex)

    }
  }, [draggingOverItemIndex])
  useEffect(() => {
    handleMoveItemsTemporarily(draggedItemIndex, draggingOverItemIndex)
  }, [draggingOverItemIndex])



  useEffect(() => {
    if (selectedSection && sectionList) {
      /* I GOT ERROR HERE SO WHATEVER LOL */
      /* console.log("crashing here 0")
      console.log(sectionList)
      console.log(selectedSection)
      //@ts-ignore
      const elementList = sectionList.filter((section: any, index: number) => section.uuid === selectedSection.uuid)
      if (elementList) {
        console.log(elementList[0])
        console.log("crashing here 1")
        //@ts-ignore
        setElementList(elementList[0].offer_list)

      }
      console.log("crashing here 2")
      //@ts-ignore
      setFilteredElementList(sectionList.filter((section: any, index: number) => section.uuid === selectedSection.uuid)[0].offer_list.sort((a: any, b: any) => a.item_order - b.item_order)) */
    }
  }, [sectionList])



  useEffect(() => {
    // get user info
    // if is_super user get businessEstablishments
    getBusinessEstablishments(setClients)
    getUserInfo(setUser)
    getSectionList(setSectionList)
    getOfferBlockImages()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    document.body.addEventListener("dragover", (event) => {
      event.preventDefault();
    })


  }, [])

  useEffect(() => {
    if (selectedSection) {
      /* getElementsByListId(selectedSection?.list, setElementList) */
      setElementList(selectedSection.offer_list.sort((a: any, b: any) => a.item_order - b.item_order))

    }
      //@ts-ignore
      /* setElementList(sectionList[0].offer_list.sort((a: any, b: any) => a.item_order - b.item_order)) */
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[selectedSection, sectionList])



  useEffect(() => {
    // Zoradíme zoznam elementov podľa item_order
    const sortedList = elementList.sort((a, b) => a.item_order - b.item_order);

    // Skontrolujeme, či existujú duplicity v item_order
    const hasDuplicates = sortedList.some((item, index) => {
      return index > 0 && item.item_order === sortedList[index - 1].item_order;
    });

    if (hasDuplicates) {
      alert("Existuje viacero položiek s rovnakym item_order !!!");
    } else {
      console.log("Žiadne duplicitné item_order hodnoty");
    }

    // Nastavíme filtrovaný zoznam
    setFilteredElementList(sortedList);
    /* setFilteredElementList(elementList.sort((a, b) => a.item_order - b.item_order)) */
  }, [elementList])

  useEffect(() => {
    dynamicListHeightSetup()
  }, [openedTabIndex])
  useEffect(() => {
    const create_button_element = document.getElementById("CREATE_ELEMENT_BUTTON_ID")
    if (create_button_element && elementList.length === 0) {
      /* create_button_element.style.right = "15px"
      create_button_element.style.bottom = "15px" */
      /* create_button_element.style.right = "50vw"
      create_button_element.style.bottom = "50vh" */
      create_button_element.style.right = "25px"
      create_button_element.style.bottom = "25px"
      /* create_button_element.style.width = "500px" */



    } else if (create_button_element && elementList.length !== 0) {
      /* create_button_element.style.right = "100px"
      create_button_element.style.bottom = "100px" */

      /* create_button_element.style.right = "420px"
      create_button_element.style.bottom = "25px" */
      create_button_element.style.right = "25px"
      create_button_element.style.bottom = "25px"
    }
    let elementWithMaxOrder = elementList.reduce((maxElement, currentElement) => {
      return currentElement.item_order > maxElement.item_order ? currentElement : maxElement;
    }, { item_order: 0 })
    if (elementList.length === 0) {
      setLatestOrderNumber(elementWithMaxOrder.item_order)
    } else {
      setLatestOrderNumber(elementWithMaxOrder.item_order + 1)
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [elementList.length])




  const invoiceDetailsSection = clients[0] &&
  <div className="profile_row margin-sides-0">
    <div className="row_name_phone">
      <div className="primary_text">
        {
          //@ts-ignore
          clients[0].business_name
        }
        { //@ts-ignore
          clients[0].business_name &&
          <FaRegCopy
            className="copy_icon"
            size={"15px"}
            onClick={() => {
              //@ts-ignore
              const value = clients[0].business_name
              copyToClipboard(value)
              invokeAlertComponent("INFORMATION_ALERT_COMPONENT", `Skopírovali ste hodnotu: ${value}`)
            }}
          />
        }
      </div>

      { //@ts-ignore
        clients[0].business_phone_number &&
        <div className="secondary_text phone_number">
          <FaRegCopy
            className="copy_icon"
            size={"15px"}
            onClick={() => {
              //@ts-ignore
              const value = clients[0].business_phone_number
              copyToClipboard(value)
              invokeAlertComponent("INFORMATION_ALERT_COMPONENT", `Skopírovali ste hodnotu: ${value}`)
            }}
          />
          {
            //@ts-ignore
            clients[0].business_phone_number
          }
          <FaPhone
            size={"20px"}
          />
        </div>
      }
    </div>
    <div className="row_name_phone">
      <div className="secondary_text">
        Fakturačný email
        <FaRegCopy
          className="copy_icon"
          size={"15px"}
          onClick={() => {
            //@ts-ignore
            const value = clients[0].email_address
            copyToClipboard(value)
            invokeAlertComponent("INFORMATION_ALERT_COMPONENT", `Skopírovali ste hodnotu: ${value}`)
          }}
        />
      </div>
      { //@ts-ignore
        clients[0].email_address &&
        <div className="secondary_text phone_number">
          {
            //@ts-ignore
            clients[0].email_address
          }
          <MdEmail
            size={"20px"}
          />
        </div>
      }
    </div>
    <div className="row_name_phone" style={{marginTop: "15px"}}>
      {/* <div className="primary_text">
        Adresa
      </div> */}
    </div>
    <div className="row_name_phone">

      <div className="secondary_text">
      { //@ts-ignore
        clients[0].business_address_1 &&
        <div className="secondary_text phone_number">
          {
            //@ts-ignore
            clients[0].business_address_1
          }
          <FaRegCopy
            className="copy_icon"
            size={"15px"}
            onClick={() => {
              //@ts-ignore
              const value = clients[0].business_address_1
              copyToClipboard(value)
              invokeAlertComponent("INFORMATION_ALERT_COMPONENT", `Skopírovali ste hodnotu: ${value}`)
            }}
          />
        </div>
      }
      </div>
      { //@ts-ignore
        clients[0].business_address_2 &&
        <div className="secondary_text phone_number">
          {
            //@ts-ignore
            clients[0].business_address_2
          }
          <FaRegCopy
            className="copy_icon"
            size={"15px"}
            onClick={() => {
              //@ts-ignore
              const value = clients[0].business_address_2
              copyToClipboard(value)
              invokeAlertComponent("INFORMATION_ALERT_COMPONENT", `Skopírovali ste hodnotu: ${value}`)
            }}
          />
        </div>
      }
    </div>

    <div className="row_name_phone" style={{marginTop: "15px"}}>
      {/* <div className="primary_text">
        Daňové identifikátory
      </div> */}
    </div>
    <div className="row_name_phone">
      <div className="secondary_text">
        IČO
      </div>
      { //@ts-ignore
        clients[0].business_ico &&
        <div className="secondary_text phone_number">
          {
            //@ts-ignore
            clients[0].business_ico
          }
          <FaRegCopy
            className="copy_icon"
            size={"15px"}
            onClick={() => {
              //@ts-ignore
              const value = clients[0].business_ico
              copyToClipboard(value)
              invokeAlertComponent("INFORMATION_ALERT_COMPONENT", `Skopírovali ste hodnotu: ${value}`)
            }}
          />
        </div>
      }
    </div>
    <div className="row_name_phone">
      <div className="secondary_text">
        DIČ
      </div>
      { //@ts-ignore
        clients[0].business_dic &&
        <div className="secondary_text phone_number">
          {
            //@ts-ignore
            clients[0].business_dic
          }
          <FaRegCopy
            className="copy_icon"
            size={"15px"}
            onClick={() => {
              //@ts-ignore
              const value = clients[0].business_dic
              copyToClipboard(value)
              invokeAlertComponent("INFORMATION_ALERT_COMPONENT", `Skopírovali ste hodnotu: ${value}`)
            }}
          />
        </div>
      }
    </div>
    <div className="row_name_phone">
      <div className="secondary_text">
        IČDPH
      </div>
      { //@ts-ignore
        clients[0].business_icdph &&
        <div className="secondary_text phone_number">
          {
            //@ts-ignore
            clients[0].business_icdph
          }
          <FaRegCopy
            className="copy_icon"
            size={"15px"}
            onClick={() => {
              //@ts-ignore
              const value = clients[0].business_icdph
              copyToClipboard(value)
              invokeAlertComponent("INFORMATION_ALERT_COMPONENT", `Skopírovali ste hodnotu: ${value}`)
            }}
          />
        </div>
      }
    </div>

  </div>




  return(
    <>
      {
        confirmationModal.isOpen &&
        <ConfirmationModal
          modalText={confirmationModal.modalText}
          onClose={() => setConfirmationModal({...confirmationModal, isOpen: false, type: "Item"})}
          onConfirm={() => {
            if (confirmationModal.type === "OfferBlock") {
              handleDeleteOfferBlock()
            } else {
              handleDeleteItem()
            }
          }}
        />
      }
      {
        createElementModal &&
        <CreateElementModal
          type="create_modal"
          businessEstablishment={clients[0]}
          latestOrderNumber={latestOrderNumber}
          sectionElements={elementList}
          onClose={() => setCreateElementModal(false)}
          onConfirm={handleCreateElement}
        />
      }
      {
        createElementBlockModal &&
        <CreateElementBlockModal
          businessEstablishment={clients[0]}
          latestOrderNumber={latestOrderNumber}
          sectionElements={elementList}
          offerBlockImages={offerBlockImages}
          onClose={() => setCreateElementBlockModal(false)}
          onConfirm={(e) => {handleCreateOfferBlock(e)}}
          selectedElement={selectedSection || null}
        />
      }

      {
        updateElementModal &&
        <CreateElementModal
          type="update_modal"
          businessEstablishment={clients[0]}
          latestOrderNumber={latestOrderNumber}
          sectionElements={elementList}
          onClose={() => setUpdateElementModal(false)}
          onConfirm={(data) => handleUpdateElement({
            uuid: data?.uuid,
            name: data?.item_name,
            alert_text: `Upravili ste položku ${data.item_name}`,
            data: data
          })}
          selectedElement={selectedItemElement || null}
        />
      }


      {
        updateElementBlockModal &&
        <CreateElementBlockModal
          businessEstablishment={clients[0]}
          latestOrderNumber={latestOrderNumber}
          sectionElements={elementList}
          offerBlockImages={offerBlockImages}
          onClose={() => setUpdateElementBlockModal(false)}
          onConfirm={(data) => handleUpdateElementBlockModal({
            uuid: data?.uuid,
            //@ts-ignore
            name: data?.block_title_short,
            //@ts-ignore
            alert_text: `Upravili ste sekciu ${data.block_title_short}`,
            data: data
          })}
          selectedElement={selectedSection || null}
        />
      }
     {/*  <AlertComponent
        id="INFORMATION_ALERT_COMPONENT"
      /> */}
      {
        (openedTabIndex === 0 && sectionList.length > 0) &&
        <CreateElementButton
          isEditView={openedTabIndexAnimationInvoker === 0}
          onClick={() => {
            setCreateElementModal(true)
            setSelectedItemElement(null)
          }}
        />
      }
      <div className="col left_col" id="LEFT_COL_ADMIN">
        <div className="card nav_card">
          <NavigationCard
            businessEstablishment={clients[0]}
            onPriceEditClick={handlePriceEditClick}
            onLayoutEditClick={handleLayoutEditClick}
            onImageGalleryClick={handleImageGalleryClick}
            onContactEditClick={handleContactEditClick}
            onInvoiceSubscriptionClick={handleInvoicesSubscriptionClick}
            onHowToClick={handleHowToClick}
            activeTabNumber={openedTabIndexAnimationInvoker}
            isUnpaidInvoice={unpaidInvoice}
          />
        </div>
        <div className="card logout_card">
          <div className="subtitle logout_message_for_phones">
            Toto zariadenie je príliš malé, skúste to prosím na počítači alebo notebooku.
          </div>
          <LogoutCard
            onLogout={handleLogout}
          />
        </div>
      </div>
      <div className={`col mid_col`} id="MID_COL_ADMIN">
        {
          openedTabIndex === 0 &&
          <div
            className={`card content_card`}
            style={{
              transform: `${openedTabIndexAnimationInvoker !== 0 ? "translateY(100vh)" : "translateY(0vh)"}`
            }}
          >
            <PriceEditHeaderCard
              sectionList={sectionList}
              setSelectedSection={(section) => {
                setSelectedSection(section)
                getSectionById(section?.uuid)
                getSectionByIdForSelector(section?.uuid)
                /* if (section) {
                  console.log(section)
                  setElementList(section.offer_list)
                  setSelectedSection((prevState: any) => ({
                    ...prevState,
                    offer_list: section.offer_list
                  }))
                } */

              }}
              onSearch={handleSearch}
              clearSearch={() => handleSearch("")}
              onCreateElementBlock={(e) => {
                setSelectedSection({
                  ...layoutEditCol,
                  /* block_image: "null", */
                  offer_list: selectedSection?.offer_list || [],
                  block_image_width: "50",
                  block_title_long: "",
                  block_title_short: "",
                  block_position_row: layoutEditRows.length + 1, // biggest row number + 1
                  block_position_column: "BOTH_COLUMNS",
                  block_is_visible: true,
                  //@ts-ignore
                  belongs_to: clients[0].uuid
                })
                setCreateElementBlockModal(e)
              }}
              onMoveItems={() => {setMovingMode(prev => !prev)}}
            />
            <ItemElementHeader />
            <DndContext
              collisionDetection={closestCorners}
              onDragEnd={handleDragEnd}
            >
              <div className="list_row" id="LIST_ROW_ID">

                <SortableContext
                  //@ts-ignore
                  items={filteredElementList.map(element => element.uuid)}
                  strategy={verticalListSortingStrategy}
                >
                  {
                    filteredElementList.map((element, elementIndex) =>
                      <ItemElementRowDragable
                        id={element.uuid}
                        index={elementIndex}
                        key={element.uuid}
                        /* movingMode={movingMode} */
                        offerElement={element}
                        businessEstablishment={clients[0]}
                        onDragStart={(item) => {
                          /* handleItemDragStart(item)
                          setDraggedItemIndex(elementIndex) */
                        }}
                        onDragEnd={(item) => {
                          /* handleItemDragEnd(item)
                          setDraggingOverItemIndex(-1)
                          setDraggedItemIndex(-1) */
                        }}
                        onDragEnter={(item) => {  }}
                        onDragLeave={(item) => {  }}
                        onDragOver={(item) => {/* setDraggingOverItemIndex(elementIndex) */}}
                        onDelete={(selectedElement) => {
                          handleRequestDeleteItemConfirmation(selectedElement)
                        }}
                        onOpen={(selectedElement) => {
                          /* invokeAlertComponent("INFORMATION_ALERT_COMPONENT",`${selectedElement.item_name} element click event`) */
                          setUpdateElementModal(true)
                          setSelectedItemElement(selectedElement)

                        }}
                        /* onUpdateElement={(data) => { handleUpdateElement(data) }} */
                        onUpdateElement={(data) => {
                          handleUpdateElement(data)
                        }}
                      />
                    )
                  }
                </SortableContext>


                {
                  filteredElementList.length === 0 &&
                  <div className="no_elements_info" id="NO_ELEMENTS_INFO" key={selectedSection?.block_title_short}>
                    {
                      elementListLoading ?
                        <AiOutlineLoading
                          size={"45px"}
                          color="#8E62EA"
                          /* color="white" */
                          className="loading_icon_local"
                        />
                      :
                        <TbAlertTriangle
                          id="ALERT_ICON_ID"
                          size={"55px"}
                          color="#2C2C2C"
                        />
                    }
                    {
                      elementList.length === 0 ?
                        elementListLoading
                        ? `Načítavam...`
                        : selectedSection?.block_title_short
                          ? `V sekcii ${selectedSection?.block_title_short} je prázdno.`
                            : `Zatiaľ tu nič nieje, skús vytvoriť sekciu tlačidlom v pravom hornom rohu.` :
                              `Vyhľadávaná položka sa nenachádza v zozname.`
                    }

                  </div>
                }
              </div>
            </DndContext>
          </div>
        }
        {
          openedTabIndex === 1 &&
          <div
            /* className="card" */
            style={{
              transform: `${openedTabIndexAnimationInvoker !== 1 ? "translateY(100vh)" : "translateY(0vh)"}`
            }}
          >
            <div className="card layout_edit_title_card">
              <div className="title">
                Editácia rozloženia
              </div>
              <div className="action">
                {/* { sectionList.length > 0 &&
                  <div
                    className="action_element"
                    onClick={() => {setOfferBlockRemoveMode(prev => !prev)}}
                  >
                    <TbTrash
                      size={"20px"}
                    />
                    Zmazať
                  </div>
                } */}
                { sectionList.length > 0 &&
                  <div
                    className="action_element"
                    onClick={() => {
                      setOfferBlockRemoveMode(prev => !prev)
                      setOfferBlockEditMode(prev => !prev)
                    }}
                  >
                    <FaEdit
                      size={"20px"}
                    />
                    Editovať
                  </div>
                }
                  <div
                    className="action_element"
                    onClick={() => {
                      setSelectedSection({
                        ...layoutEditCol,
                        /* block_image: "null", */
                        offer_list: [],
                        block_image_width: "50",
                        block_title_long: "",
                        block_title_short: "",
                        block_position_row: layoutEditRows.length + 1, // biggest row number + 1
                        block_position_column: "BOTH_COLUMNS",
                        block_is_visible: true,
                        //@ts-ignore
                        belongs_to: clients[0].uuid
                      })
                      setCreateElementBlockModal((prev) => !prev)
                      /* setOpen(prev => !prev) */
                    }}
                  >
                    <IoMdAdd
                      className="icon"
                      size={"20px"}
                    />
                    Vytvoriť sekciu
                  </div>
              </div>
            </div>
            <div className="card_transparent">
              {/* <NotAvailableComponent
                text="Editácia rozloženia momentálne nie je k dispozícií."
              /> */}
              { sectionList.length <= 0 ?
                <div className="no_offer_blocks_info">
                  <TbAlertTriangle
                    id="ALERT_ICON_ID"
                    size={"55px"}
                    color="#2C2C2C"
                  />
                  Zatiaľ tu nič nieje, skús vytvoriť sekciu tlačidlom v pravom hornom rohu.
                </div>
                :
                <div className="layout_wrap">
                  <DndContext onDragEnd={handleDragLayoutEnd} collisionDetection={pointerWithin}>
                    <div className="layout_elements_wrap card">
                      {
                        sectionList.map((section: any, index: number) =>
                          <DraggableBlock
                            key={section.uuid}
                            block={section}
                            disabled={layoutEditRows.some((col) => col.some((element: any) => element.block_title_short === section.block_title_short))}
                          >
                            <div
                              key={index}
                              className={
                                `offer_block_card
                                ${section.block_image.icon_type === "WHITE" && "offer_block_card_dark"}
                                ${layoutEditRows.some((col) => col.some((element: any) => element.block_title_short === section.block_title_short)) && " offer_block_card_disabled"}`}
                              style={{margin: "0px 0px",}}
                              onClick={() => {handleOfferBlockSelect(section)}}
                            >
                              <img src={`${process.env.REACT_APP_MOJEQR_API_URL}${section.block_image.icon}`} alt={section.block_title_short} />
                              <span>
                                <HtmlParser>
                                  {section.block_title_short}
                                </HtmlParser>
                              </span>
                              <RiSettings4Fill
                                className={`edit_section_icon ${!offerBlockEditMode && "edit_section_icon_hidden"}`}
                                size={"20px"}
                                onClick={
                                  (event) => {
                                    /* event.stopPropagation() */
                                    setUpdateElementBlockModal(true)
                                    setSelectedSection(section)
                                  }
                                }
                              />
                              <TbTrash
                                className={`remove_section_icon ${!offerBlockRemoveMode && "remove_section_icon_hidden"}`}
                                size={"20px"}
                                onClick={
                                  (event) => {
                                    event.stopPropagation()
                                    /* setUpdateElementBlockModal(true) */
                                    handleRequestDeleteOfferBlockConfirmation(section)
                                  }
                                }
                              />
                              {
                                layoutEditRows.some((col) => col.some((element: any) => element.block_title_short === section.block_title_short)) &&
                                <FaCheckCircle
                                  className="selected_section"
                                  size={"20px"}
                                />
                              }
                            </div>
                          </DraggableBlock>
                        )
                      }
                    </div>
                    <div className="layout_preview_wrap">
                      <MobileViewCard>
                        {
                          layoutEditRows.map((row, row_index) =>
                            <div
                              className="mobile_layout_row"
                              key={row_index}
                            >
                              {
                                row.map((col:OfferSectionElement, col_index: number) =>
                                  <DroppableCol
                                    id={col.uuid}
                                    className="mobile_layout_col"
                                    key={col_index}
                                    onClick={() => {
                                      console.log(col)
                                      setSelectedSection(null)
                                      setSelectedSection(col.block_title_short !== "Vložte sekciu" ? {
                                        ...col,
                                        /* block_image: "null", */
                                        /* block_position_row: 2,
                                        block_position_column: "LEFT_COLUMN", */
                                      } :
                                      {
                                        ...col,
                                        /* block_image: "null", */
                                        offer_list: [],
                                        block_image_width: "50",
                                        block_title_long: "",
                                        block_title_short: "",
                                        /* block_position_row: 2,
                                        block_position_column: "LEFT_COLUMN", */
                                        block_is_visible: true,
                                        //@ts-ignore
                                        belongs_to: clients[0].uuid
                                      })
                                      if (col.block_title_short !== "Vložte sekciu" ) {
                                        setUpdateElementBlockModal(true)
                                      } else {
                                        setCreateElementBlockModal(true)
                                      }

                                      /* setCreateElementBlockModal((prev) => !prev) */
                                      /* setOpen(prev => !prev) */
                                    }}
                                  >
                                    {
                                      /* (col.block_position_column === "BOTH_COLUMNS" || col.block_position_column === "RIGHT_COLUMN") && */
                                      <TbReplaceFilled
                                        className="change_type"
                                        size={"18px"}
                                        onClick={(event) => {
                                          event.stopPropagation()
                                          if (col.block_position_column === "BOTH_COLUMNS") {
                                            setLayoutEditRows((prevRows) =>
                                              [
                                                ...prevRows.filter((row, local_index) => row_index !== local_index),
                                                [
                                                  {
                                                    /* ...layoutEditCol, */
                                                    ...col,
                                                    uuid: uuid(),
                                                    block_position_row: col.block_position_row,
                                                    block_position_column: "LEFT_COLUMN"
                                                  },
                                                  {
                                                    ...layoutEditCol,
                                                    uuid: uuid(),
                                                    block_position_row: col.block_position_row,
                                                    block_position_column: "RIGHT_COLUMN"
                                                  },
                                                ]
                                              ].sort((a, b) => {
                                                // Získanie hodnoty block_position_row z prvého objektu v každom vnútornom poli
                                                const rowA = a[0]?.block_position_row || 0;
                                                const rowB = b[0]?.block_position_row || 0;

                                                return rowA - rowB;
                                              })
                                            )
                                          }
                                          if (col.block_position_column === "RIGHT_COLUMN") {
                                            setLayoutEditRows((prevRows) =>
                                              prevRows.map((row, index) =>
                                                // Pre každý rad, ak je rad pole (skupina LEFT a RIGHT), upravíme hodnoty
                                                index === row_index && Array.isArray(row)
                                                ? row
                                                    .filter(column => column.block_position_column !== "RIGHT_COLUMN") // Odstránime pravý stĺpec
                                                    .map(col => ({
                                                      ...col,
                                                      block_position_column: "BOTH_COLUMNS"
                                                    })) // Premenovanie na BOTH_COLUMNS
                                                : row // Ak to nie je cieľové pole, ponecháme ho nezmenené
                                              )
                                            );
                                          }
                                        }}
                                      />
                                    }
                                    <FiRefreshCcw
                                      className="refresh_col"
                                      size={"15px"}
                                      onClick={(event) => {
                                        // find current element in layoutEditRows
                                        // replace element with default
                                        // pay attention to rows and columns
                                        event.stopPropagation()
                                        if (col.block_position_column === "BOTH_COLUMNS") {
                                          setLayoutEditRows((prevRows) =>
                                            [
                                              ...prevRows.filter((row, local_index) => row_index !== local_index),
                                              [
                                                {
                                                  /* ...layoutEditCol, */
                                                  ...layoutEditCol,
                                                  uuid: uuid(),
                                                  block_position_row: col.block_position_row,
                                                  block_position_column: col.block_position_column
                                                },
                                              ]
                                            ].sort((a, b) => {
                                              // Získanie hodnoty block_position_row z prvého objektu v každom vnútornom poli
                                              const rowA = a[0]?.block_position_row || 0;
                                              const rowB = b[0]?.block_position_row || 0;

                                              return rowA - rowB;
                                            })
                                          )
                                        } else {
                                          setLayoutEditRows((prevRows) => {
                                            return [
                                              ...prevRows.filter((row, local_index) => row_index !== local_index),
                                              [
                                                ...(
                                                  col.block_position_column === "LEFT_COLUMN"
                                                    ? [
                                                      {
                                                        ...layoutEditCol,
                                                        uuid: uuid(),
                                                        block_position_row: col.block_position_row,
                                                        block_position_column: col.block_position_column
                                                      },
                                                      //@ts-ignore
                                                      ...row.filter((col, local_col_index) => col_index !== local_col_index)
                                                    ]
                                                    : [
                                                      //@ts-ignore
                                                      ...row.filter((col, local_col_index) => col_index !== local_col_index),
                                                      {
                                                        ...layoutEditCol,
                                                        uuid: uuid(),
                                                        block_position_row: col.block_position_row,
                                                        block_position_column: col.block_position_column
                                                      }
                                                    ]
                                                )
                                              ]
                                            ].sort((a, b) => {
                                              // Získanie hodnoty block_position_row z prvého objektu v každom vnútornom poli
                                              const rowA = a[0]?.block_position_row || 0;
                                              const rowB = b[0]?.block_position_row || 0;

                                              return rowA - rowB;
                                            });
                                          });
                                        }
                                      }}
                                    />
                                    <TbTrash
                                      className="remove_col"
                                      size={"18px"}
                                      onClick={(event) => {
                                        event.stopPropagation()
                                        if (col.block_position_column === "BOTH_COLUMNS") {
                                          setLayoutEditRows((prevRows) => {
                                            // Odstránime konkrétny riadok
                                            const updatedRows = prevRows.filter((_, index) => index !== row_index);

                                            // Aktualizujeme block_position_row vo všetkých riadkoch
                                            return updatedRows.map((row, newIndex) =>
                                              Array.isArray(row)
                                                ? row.map((column) => ({
                                                    ...column,
                                                    block_position_row: newIndex + 1, // Nastavíme nový block_position_row podľa poradia
                                                  }))
                                                : row
                                            );
                                          });
                                        } else {
                                          setLayoutEditRows((prevRows) =>
                                            prevRows.map((row, index) =>
                                              // Pre každý rad, ak je rad pole (skupina LEFT a RIGHT), upravíme hodnoty
                                              index === row_index && Array.isArray(row)
                                              ? row
                                                  .filter(column => column.block_position_column !== col.block_position_column) // Odstránime pravý stĺpec
                                                  .map(col => ({ ...col, block_position_column: "BOTH_COLUMNS" })) // Premenovanie na BOTH_COLUMNS
                                              : row // Ak to nie je cieľové pole, ponecháme ho nezmenené
                                            )
                                          );
                                        }
                                      }}
                                    />

                                    {
                                      col.block_image !== "null" ?
                                        <img src={
                                          //@ts-ignore
                                          `${process.env.REACT_APP_MOJEQR_API_URL}${col.block_image.icon}`
                                        } alt="" />
                                      : <FaImage className="icon" size={"30px"} />
                                    }

                                    <HtmlParser>
                                      {col.block_title_short}
                                    </HtmlParser>
                                    <span className="debug">
                                      {col.block_position_column === "LEFT_COLUMN" ? "L" : col.block_position_column === "RIGHT_COLUMN" ? "R" : "B"} <br /> {col.block_position_row}
                                    </span>
                                  </DroppableCol>
                                )
                              }
                              {/* {
                                row.block_position_column !== "BOTH_COLUMNS" &&
                                <DroppableCol
                                  id={row.uuid}
                                  className="mobile_layout_col"
                                >
                                  {
                                    row.block_position_column === "BOTH_COLUMNS" &&
                                    <TbReplaceFilled
                                      className="change_type"
                                      size={"20px"}
                                      onClick={() => {
                                        setLayoutEditRows((prevRows) =>
                                          prevRows.map((row, i) =>
                                            i === index ? { ...row, block_position_column: "COLUMN_RIGHT" } : row
                                          )
                                        );
                                      }}
                                    />
                                  }

                                  <TbTrash
                                    className="remove_col"
                                    size={"20px"}
                                    onClick={() => {

                                      setLayoutEditRows(layoutEditRows.filter((local_row) => local_row.uuid !== row.uuid))
                                    }}
                                  />
                                  <FaImage className="icon" size={"35px"} />
                                  <HtmlParser>
                                    {row.block_position_column}  {row.block_position_row}
                                  </HtmlParser>
                                </DroppableCol>
                              } */}
                              {/* {
                                row.has_two_cols &&
                                <div
                                  className="mobile_layout_col"
                                >
                                  <TbReplaceFilled
                                    className="change_type"
                                    size={"20px"}
                                    onClick={() => {
                                      setLayoutEditRows((prevRows) =>
                                        prevRows.map((row, i) =>
                                          i === index ? { ...row, has_two_cols: !row.has_two_cols } : row
                                        )
                                      );
                                    }}
                                  />
                                  <TbTrash
                                    className="remove_col"
                                    size={"20px"}
                                    onClick={() => {
                                      setLayoutEditRows(layoutEditRows.filter((local_row) => local_row.block_uuid !== row.block_uuid))
                                    }}
                                  />
                                  <FaImage className="icon" size={"35px"} />
                                  <span>{`block`}</span>
                                </div>
                              } */}
                            </div>
                          )
                        }

                        {/* <div className="mobile_layout_row">
                          <div className="mobile_layout_col">
                            <img
                              src={`${process.env.REACT_APP_MOJEQR_API_URL}/media/offer_block_icons/WHITE_beer.png`}
                            />
                            <span>Title</span>
                          </div>
                          <div className="mobile_layout_col">
                            <img
                              src={`${process.env.REACT_APP_MOJEQR_API_URL}/media/offer_block_icons/WHITE_beer.png`}
                            />
                            <span>Title</span>
                          </div>
                        </div> */}

                        {/* <NotAvailableComponent
                          text="Na interaktivite sa ešte pracuje"
                          customStyleImage={{
                            width: "140px",
                            textAlign: "center"
                          }}
                          customStyleTitle={{
                            fontSize: "18px",
                            lineHeight: "18px",
                            textAlign: "center"
                          }}
                        /> */}
                      </MobileViewCard>
                      <div className="action_bar">
                        <div
                          className="action"
                          onClick={() => {
                            setLayoutEditRows((prevRows) => {
                              // Získame najväčší block_position_row z predchádzajúcich riadkov
                              const maxRow = prevRows
                                .flat() // Ak sú riadky vnořené, rozbalíme ich
                                .reduce((max, row) => {
                                  // Zistíme najväčší block_position_row
                                  return row.block_position_row > max ? row.block_position_row : max;
                                }, 0); // Počiatočná hodnota je 0

                              // Pridáme nový riadok s block_position_row nastaveným na maxRow + 1
                              return [
                                ...prevRows,
                                [
                                  {
                                    ...layoutEditCol,
                                    block_position_row: maxRow + 1, // Nastavenie nové hodnoty
                                    block_position_column: "BOTH_COLUMNS"
                                  },
                                ]
                              ];
                            });
                          }}
                        >
                          <MdAddBox size={"22px"} /> Pridať riadok
                        </div>
                        <div
                          className="action"
                          onClick={() => {
                            handleLayoutSave()
                          }}
                        >
                          <FaSave size={"20px"} /> Uložiť
                        </div>
                        {/* <div
                          className="action"
                          onClick={() => {
                            setLayoutEditRows(layoutEditRowTemplate)
                          }}
                        >
                          <FiRefreshCcw size={"18px"} />
                        </div> */}
                      </div>
                     {/*  <div style={{fontSize: "14px"}}>
                        Na tejto funkcionalite zatial pracujeme.
                      </div> */}
                    </div>
                  </DndContext>
                </div>
              }
            </div>

          </div>
        }
        {
          openedTabIndex === 2 &&
          <div
            style={{
              transform: `${openedTabIndexAnimationInvoker !== 2 ? "translateY(100vh)" : "translateY(0vh)"}`
            }}
          >
            <div className="card">
              <div className="title">
                Ponuky
              </div>
            </div>
            {/* <div className="card">
              <NotAvailableComponent
                text="Ponuky momentálne nie su k dispozícií."
              />
            </div> */}
            {
              supplierOffers.length === 0 &&
              <div className="card">
                <NotAvailableComponent
                  text="Momentálne nemáme žiadne ponuky!"
                />
              </div>
            }
            <div className="supplier_offer_wrap">

              {
                supplierOffers.map((supplier_offer: SupplierOffer, index: number ) =>
                  <div className="card supplier_offer">
                    {
                      supplier_offer.image ?
                      <img src={`${process.env.REACT_APP_MOJEQR_API_URL}${supplier_offer.image}`} alt="" /> :
                      <img style={{padding: "15px", width: "150px"}} src={require("../../assets/images/logo.jpg")} alt="MojeQr" />
                    }
                    <div className="title_section">
                      <div className="company_name">
                        {supplier_offer.company_name}
                      </div>
                      <div className="title">
                        {supplier_offer.title}
                      </div>
                      <div className="descr">
                        {supplier_offer.descr}
                      </div>
                      <div className="contact">
                        {
                          supplier_offer.phone &&
                          <span>
                            <FaPhone
                              size={"20px"}
                            />
                            {supplier_offer.phone}
                          </span>
                        }
                        {
                          supplier_offer.email &&
                          <span>
                            <MdEmail
                              size={"20px"}
                            />
                            {supplier_offer.email}
                          </span>
                        }
                        {
                          (supplier_offer.link && supplier_offer.link.includes("instagram")) &&
                            <span
                              style={{cursor: "pointer"}}
                              onClick={() => { window.open(supplier_offer.link) }}
                            >
                              <RiInstagramFill
                                className="icon"
                                size={"20px"}
                              />
                              Instagram
                            </span>
                        }
                        {
                          (supplier_offer.link && supplier_offer.link.includes("facebook")) &&
                            <span
                              style={{cursor: "pointer"}}
                              onClick={() => { window.open(supplier_offer.link) }}
                            >
                              <FaFacebookSquare
                                className="icon"
                                size={"20px"}
                                onClick={() => { window.open(supplier_offer.link) }}
                              />
                              Facebook
                            </span>
                        }
                        {
                          (supplier_offer.link && !supplier_offer.link.includes("facebook") && !supplier_offer.link.includes("instagram")) &&
                            <span
                              style={{cursor: "pointer"}}
                              onClick={() => { window.open(supplier_offer.link) }}
                            >
                              <TbWorld
                                className="icon"
                                size={"20px"}
                                onClick={() => { window.open(supplier_offer.link) }}
                              />
                              Web
                            </span>
                        }
                      </div>
                    </div>
                  </div>
                )
              }
            </div>
          </div>
        }
        {
          openedTabIndex === 3 &&
          <div
            style={{
              transform: `${openedTabIndexAnimationInvoker !== 3 ? "translateY(100vh)" : "translateY(0vh)"}`
            }}
          >
            <div className="card">
              <div className="title">
                Štatistiky
              </div>
            </div>
            <div className="card">
              <NotAvailableComponent
                text="Štatistiky momentálne nie su k dispozícií."
              />
            </div>
          </div>
        }
        {
          openedTabIndex === 4 &&
          <InvoiceAndSubscriptionCard
            openedTabIndexAnimationInvoker={openedTabIndexAnimationInvoker}
            businessEstablishment={clients[0]}
            invoiceDetailsSection={invoiceDetailsSection}
            invoices={invoices}
          />
        }
        {
          openedTabIndex === 5 &&
          <div
            style={{
              transform: `${openedTabIndexAnimationInvoker !== 5 ? "translateY(100vh)" : "translateY(0vh)"}`
            }}
          >
            <div className="card">
              <div className="title">
                Ako na to
              </div>
            </div>
            <div className="how_to_wrap">
              <div className="how_to_in_row">
                <HowToCreateBlock />
                <HowToCreateElement />
              </div>
              <HowToMoveLastItem />
              <HowToKnowIcons />
            </div>
            {/* <div className="card">
              <NotAvailableComponent
                text="Toto je beta verzia admin systému. Po ukončení testovania a odstránení všetkých chýb bude doplnený podrobný návod na použitie."
                customStyleImage={{
                  textAlign: "center"
                }}
                customStyleTitle={{
                  fontSize: "22px",
                  lineHeight: "22px",
                  textAlign: "center",
                  maxWidth: "500px"
                }}
              />
            </div> */}
          </div>
        }
      </div>
      {/* <div className="col right_col" id="RIGHT_COL_ADMIN">
        <div className="card mobile_card">
          <MobileViewCard />
        </div>
        <div className="card stats_card">
          <StatsCard />
        </div>
      </div> */}
    </>
  )
}

export default AdminDashboardCard




