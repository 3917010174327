import uuid from "react-uuid"
import { BsCreditCard2BackFill } from "react-icons/bs"

export const pivo = [
  {
    type: "extra",
    icon: null,
    iconSize: "45px",
    item_uuid: uuid(),
    title: "Čapované",
    descr: "",
    volume: "",
    price: "",
    noDescr: true,
    divideAfter: true,
    lastElement: false
  },
  {
    item_uuid: uuid(),
    title: "Zlatý Bažant '73",
    descr: "/&nbsp12°&nbsp/",
    volume: "0,30 l",
    price: "1,90 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Zlatý Bažant '73",
    descr: "/&nbsp12°&nbsp/",
    volume: "0,50 l",
    price: "2,20 €",
    divideAfter: true
  },
  {
    type: "extra",
    icon: null,
    iconSize: "45px",
    item_uuid: uuid(),
    title: "Fľašové",
    descr: "",
    volume: "",
    price: "",
    noDescr: true,
    divideAfter: true,
    lastElement: false
  },
  {
    item_uuid: uuid(),
    title: "Pilsner Urquell",
    descr: "",
    volume: "0,50 l",
    price: "2,40 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Zlatý Bažant",
    descr: "/&nbspRadler / Nealko&nbsp/",
    volume: "0,50 l",
    price: "2,20 €",
    divideAfter: true
  },
  /* {
    item_uuid: uuid(),
    title: "Desperados",
    descr: "",
    volume: "0,33 l",
    price: "2,50 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Corona Extra",
    descr: "",
    volume: "0,335 l",
    price: "3,20 €",
    divideAfter: true
  }, */
  {
    type: "extra",
    icon: BsCreditCard2BackFill,
    iconSize: "25px",
    item_uuid: uuid(),
    title: "Možná platba kartou",
    descr: "",
    volume: "",
    price: "",
    noDescr: true,
    divideAfter: false,
    lastElement: true
  },
]