import { IoMdAdd } from "react-icons/io";
import { IoDocumentText } from "react-icons/io5";
import { FaStar, FaImage, FaQuestionCircle  } from "react-icons/fa";
import { CgFormatSeparator } from "react-icons/cg";
import { IoEyeOutline, IoEyeOffOutline  } from "react-icons/io5";
import { TbTrash } from "react-icons/tb";
import { RiSettings4Fill } from "react-icons/ri";
import { MdDragIndicator } from "react-icons/md";
/* import { FaInfo } from "react-icons/fa"; */

const HowToKnowIcons = () => {
  return (
    <div className="how_to_element_wrap">
      <div className="transparent_card">
        <div className="subtitle align-center">
          <FaQuestionCircle  size={"30px"} style={{marginRight: "10px"}} />
          Ako viem čo ktorá ikonka znamená ?
        </div>
      </div>
      <div className="card how_to">
        <div className="text align-center">
          <IoDocumentText
            size={"20px"}
            style={{marginRight: "8px"}}
            color={"#35BF64"}
          /> položka obsahuje popis
        </div>
        <div className="text align-center">
          <FaQuestionCircle
            size={"18px"}
            style={{marginRight: "10px"}}
            color={"#FF4500"}
          /> položka obsahuje rozšírený popis, ktorý sa zobrazí po zakliknutí položky
        </div>
        <div className="text align-center">
          <FaStar
            size={"20px"}
            style={{marginRight: "8px"}}
            color={"#F7C808"}
          /> označenie položky "Odporúčané podnikom"
        </div>
        <div className="text align-center">
          <FaImage
            size={"20px"}
            style={{marginRight: "8px"}}
            color={"#18A0FB"}
          /> položka obsahuje náhľad (obrázok)
        </div>
        <div className="text align-center">
          <CgFormatSeparator
            size={"20px"}
            style={{marginRight: "8px"}}
            color={"#ff4500"}
          /> položka má pod sebou oddeľovaciu čiaru
        </div>
        <div className="text align-center">
          <IoEyeOutline
            size={"20px"}
            style={{marginRight: "8px"}}
            color={"#2C2C2C"}
          /> položka je viditeľná v online menu
          <IoEyeOffOutline
            size={"20px"}
            style={{marginRight: "8px", marginLeft: "8px"}}
            color={"#2C2C2C"}
          /> položka je schovaná v online menu
        </div>
        <div className="text align-center">
          <RiSettings4Fill
            size={"22px"}
            style={{marginRight: "6px"}}
            color={"#A4A4A4"}
          /> otvorí nastavenia pre úpravu položky
        </div>
        <div className="text align-center">
          <TbTrash
            size={"22px"}
            style={{marginRight: "6px"}}
            color={"#FF2D55"}
          /> zmaže položku
        </div>
        <div className="text align-center">
          <MdDragIndicator
            size={"22px"}
            style={{marginRight: "6px"}}
            color={"#2C2C2C"}
          /> umožňuje presúvanie položky v zozname - stačí zakliknúť ľavým tlačidlom myši, presunúť na požadované miesto a pustiť tlačidlo
        </div>
      </div>
    </div>
  )
}

export default HowToKnowIcons