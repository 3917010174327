import axios from 'axios';
// replace with production url
const API_URL = `${process.env.REACT_APP_MOJEQR_API_URL}/api`;



const authService = {

  login: async (
    username: string,
    password: string,
    callback_success: (access: string, refresh: string, setPending: boolean) => void,
    callback_error: (error: any) => void
  ) => {
    try {
      const response = await axios.post(`${API_URL}/token/`, { username, password });
      callback_success(response.data.access, response.data.refresh, false);
    } catch (error) {
      callback_error(error);
    }
  },


  refresh: (callback: any) => {
    const refreshToken = localStorage.getItem('refresh_token')
    axios.post(`${API_URL}/token/refresh/`, {refresh: refreshToken})
    .then((response) => {
      // set access token
      if (callback) { callback() }
    })
    .catch((error) => {
      console.error('Refresh error:', error.response ? error.response.data : error.message);
    })

  },

  logout: (callback: any) => {
    // remove access and refresh token
    if (callback) { callback() }
  },


}

export default authService;