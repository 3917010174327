import uuid from "react-uuid"
/* import {
  BsPlusCircleFill
} from "react-icons/bs" */
/* import {
  CiBeerMugFull
} from "react-icons/ci" */

export const nieco_k_pivu = [
  /* {
    type: "title",
    icon: CiBeerMugFull,
    iconSize: "45px",
    item_uuid: uuid(),
    title: "Niečo k pivu",
    descr: "",
    volume: "",
    price: "",
    divideAfter: true,
    lastElement: false
  }, */
  {
    item_uuid: uuid(),
    title: "Pomazánka",
    descr: "nivová / oškvarková / bryndzová / hrianky 5 ks (alergény: 1, 3, 7, 10)",
    volume: "150 g",
    price: "6,00 €",
    divideAfter: false,
    lastElement: false
  },
  {
    item_uuid: uuid(),
    title: "Huspenina s cibuľou",
    descr: "podávané s chlebom 3 ks (alergény: 1, 3, 9)",
    volume: "400 g",
    price: "5,00 €",
    divideAfter: false,
    lastElement: false
  },
  {
    item_uuid: uuid(),
    title: "Gazdovské denko",
    descr: "Slanina, klobása, tlačenka, cibuľa, baranie rohy, feferónky, kyslé uhorky, horčica, chlieb 6 ks (alergény: 1, 7, 10)",
    volume: "600 g",
    price: "12,90 €",
    divideAfter: false,
    lastElement: false
  },
  /* {
    item_uuid: uuid(),
    title: "Syrové denko",
    descr: "hermelín, niva, udený syr, olivy (alergény: 1, 7)",
    volume: "400 g",
    price: "7,20 €",
    divideAfter: false,
    lastElement: false
  }, */
  {
    item_uuid: uuid(),
    title: "Tlačenka s cibuľou",
    descr: "Podávané s chlebom 3 ks (alergény: 1)",
    volume: "200 g",
    price: "4,90 €",
    divideAfter: false,
    lastElement: false
  },
  {
    item_uuid: uuid(),
    title: "Utopence",
    descr: "Podávané s chlebom 2 ks (alergény: 1)",
    volume: "200 g",
    price: "4,90 €",
    divideAfter: false,
    lastElement: false
  },
  {
    item_uuid: uuid(),
    title: "Nakladaný hermelín",
    descr: "Podávaný s chlebom 2 ks (alergény: 1, 7)",
    volume: "110 g",
    price: "4,90 €",
    divideAfter: true,
    lastElement: false
  },
  {
    item_uuid: uuid(),
    title: "Hranolky",
    descr: "",
    volume: "200 g",
    price: "2,50 €",
    divideAfter: false,
    lastElement: false
  },
  {
    item_uuid: uuid(),
    title: "Batátové hranolky",
    descr: "",
    volume: "200 g",
    price: "4,20 €",
    divideAfter: false,
    lastElement: false
  },
  {
    item_uuid: uuid(),
    title: "Cibuľové krúžky",
    descr: "",
    volume: "180 g",
    price: "4,50 €",
    divideAfter: true,
    lastElement: false
  },
  {
    item_uuid: uuid(),
    title: "Tatárska omáčka, kečup, horčica",
    descr: "",
    volume: "40 g",
    price: "1,50 €",
    divideAfter: false,
    lastElement: false
  },
  {
    item_uuid: uuid(),
    title: "Chili, cesnaková omáčka, salsa, barbeque",
    descr: "(alergény: 3, 7, 10)",
    volume: "40 g",
    price: "1,50 €",
    divideAfter: true,
    lastElement: false,
    typeAfter: "description"
  },
  {
    item_uuid: uuid(),
    title: "Hrianky",
    descr: "(alergény: 1, 3, 7)",
    volume: "1 ks",
    price: "0,30 €",
    divideAfter: false,
    lastElement: false
  },
  {
    item_uuid: uuid(),
    title: "Chlieb",
    descr: "(alergény: 1, 3, 7)",
    volume: "1 ks",
    price: "0,20 €",
    divideAfter: true,
    lastElement: false
  },
  // POCHUTINY
  {
    type: "title",
    item_uuid: uuid(),
    title: "Pochutiny",
    descr: "",
    volume: "",
    price: "",
    divideAfter: true,
    lastElement: false
  },
  {
    item_uuid: uuid(),
    title: "Chipsy",
    descr: "",
    volume: "77 g",
    price: "1,80 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Arašidy",
    descr: "",
    volume: "100 g",
    price: "1,50 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Tyčinky",
    descr: "",
    volume: "35 g",
    price: "1,50 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Pistácie",
    descr: "",
    volume: "80 g",
    price: "2,50 €",
    divideAfter: false,
    lastElement: true
  },
]