
import { useEffect, useState } from "react";
import {Outlet, useNavigate} from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import AlertComponent from "../components/adminComponents/AlertComponent"

const bgImage = `<svg width="4859" height="3139" viewBox="0 0 4859 3139" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="3" width="4856" height="3139" fill="white"/>
<path d="M0 1219.57V20.5882C0 9.21767 9.21775 0 20.5883 0H2036.74C2050.7 0 2060.5 13.5577 2054.86 26.335C2017.08 111.97 1915.75 244.384 1753.09 301.618C1530.74 379.853 1492.65 485.882 1430.88 578.529C1367.79 673.17 1246.62 784.412 893.529 780.294C540.441 776.176 544.559 1099.41 348.971 1229.12C199.53 1328.22 63.861 1277.46 7.43079 1235.03C2.58852 1231.39 0 1225.62 0 1219.57Z" fill="url(#paint0_linear_942_2847)"/>
<rect width="61" height="57" fill="#7139E5"/>
<path d="M2030.5 0H2067L2054 28C2045.84 20.2562 2031.3 0 2030.5 0Z" fill="#9B74EC"/>
<defs>
<linearGradient id="paint0_linear_942_2847" x1="124" y1="-236" x2="2862.5" y2="2351.5" gradientUnits="userSpaceOnUse">
<stop stop-color="#5379CA"/>
<stop stop-color="#6D34E4"/>
<stop offset="1" stop-color="#6D34E4" stop-opacity="0.24"/>
</linearGradient>
</defs>
</svg>
`


const AdminPanelLayout = () => {

  //@ts-ignore
  const { isLoggedIn } = useAuth()
  const navigate = useNavigate()
 /*  const bgImage = require("../assets/images/background_admin_svg.svg") */


  const [render, setRender] = useState(false)
  const currentYear = new Date().getFullYear()

  const hideLoginCard = (callback: any) => {
    const card = document.getElementById("LOGIN_CARD_WRAP")
    if (!card) { callback() }
    if(card) {
      setTimeout(() => {
        callback()
      }, 600);
      card.style.animation = "0.5s hideLogin ease-in-out"
      setTimeout(() => {
        card.style.display = "none"
      }, 400);
    }
  }

  const animateLogout = (callback: any) => {

    const leftCard = document.getElementById("LEFT_COL_ADMIN")
    const midCard = document.getElementById("MID_COL_ADMIN")
    const rightCard = document.getElementById("RIGHT_COL_ADMIN")
    if (!leftCard || !midCard || !rightCard) { callback() }
    if(leftCard && midCard && rightCard) {
      setTimeout(() => {
        callback()
      }, 600);
      leftCard.style.animation = "0.5s hideLeftColAnimation ease-in-out"
      midCard.style.animation = "0.5s hideMidColAnimation ease-in-out"
      rightCard.style.animation = "0.5s hideRightColAnimation ease-in-out"
      setTimeout(() => {
        leftCard.style.display = "none"
        midCard.style.display = "none"
        rightCard.style.display = "none"
      }, 400);
    }
  }

  useEffect(() => {
    document.body.style.backgroundImage = `url("data:image/svg+xml;utf8,${encodeURIComponent(bgImage)}")`;
    /* document.body.style.backgroundImage = `url(${bgImage})`; */
    document.body.style.backgroundRepeat = "no-repeat";
    document.body.style.backgroundSize = "cover";
    document.body.style.backgroundAttachment = "fixed";

    /* document.body.classList.remove("scale-background") */

    // Add the animation class to body
    document.body.classList.add("slide-background");
    /* document.body.classList.remove("scale-background"); */

    if (isLoggedIn) {
      hideLoginCard(() => navigate("/admin/prehlad"))
    } else {
      animateLogout(() => navigate("/admin/prihlasenie"))
    }

    setTimeout(() => {
      setRender(true)
    }, 700)
  }, [isLoggedIn, navigate])

  return (
    <div className="admin_panel_layout" id="ADMIN_PANEL_LAYOUT_ID">
        {
            render &&
            <>

              <AlertComponent
                id="INFORMATION_ALERT_COMPONENT"
              />
              <Outlet />
              {/* <span
                style={{
                  position: "static",
                  fontWeight: 500,
                  marginLeft: "5px",
                  marginBottom: "5px",
                  zIndex: 999,
                  color: "#2C2C2C"
                }}
              >
                © Tomáš Meždej {currentYear}
              </span> */}
            </>
        }
    </div>
  );
};

export default AdminPanelLayout;

