
import { useEffect, useState } from "react";
import {Outlet, useNavigate} from "react-router-dom";
import Header from '../components/global/Header';

const DefaultLayout = () => {

  const navigate = useNavigate()

  const [render, setRender] = useState(false)
  const currentYear = new Date().getFullYear()

  useEffect(() => {
    // remove the animation class from body for admin panel
    /* document.body.classList.remove("slide-background")
    document.body.classList.remove("hide-slide-background") */
    if (!render) {
      document.body.classList.add("scale-background")
    }

    setTimeout(() => {
      setRender(true)
      document.body.classList.remove("hide-slide-background")
      document.body.classList.remove("hide-scale-background")
      document.body.classList.add("scale-background")
    }, 400)
  }, [])

  return (
    <div className="default_layout" id="DEFAULT_LAYOUT_ID">
      {
        render &&
        <>
          <Header />
          <Outlet />
          <div
            style={{
              position: "static",
              fontWeight: 400,
              marginLeft: "5px",
              marginBottom: "5px",
              zIndex: 999,
              color: "#303030",
              display: "flex",
              gap: "15px"
            }}
          >
            <span>
              © Tomáš Meždej {currentYear}
            </span>

            <span
              style={{
                cursor: "pointer"
              }}
              onClick={() => {navigate("/cookies")}}
            >
              Cookies
            </span>
          </div>
        </>
      }
    </div>
  );
};

export default DefaultLayout;

