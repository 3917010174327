import { useEffect } from "react"

const WinterAnimation = () => {



  useEffect(()=> {
    // UPDATE COLORS OF BACKGROUNDS


    switch (window.location.pathname) {
      case "/panorama":
        const panorama = document.getElementById("panorama_ID")
        panorama!.style.background = "linear-gradient(#123, rgb(6, 16, 26))"
        break;
      case "/panorama_new":
        const panorama_new = document.getElementById("panorama_ID")
        panorama_new!.style.background = "linear-gradient(#123, rgb(6, 16, 26))"
        break;
      case "/obyvacka_bar":
        const obyvacka_bar = document.getElementById("WINTER_ANIMATION_ID")
        obyvacka_bar!.style.background = "linear-gradient(rgba(17, 34, 51, 0.33), rgba(0, 0, 0, 0))"
        break;
      case "/jazz_cafe":
        const jazz_cafe = document.getElementById("WINTER_ANIMATION_ID")
        jazz_cafe!.style.background = "linear-gradient(#0000008d, rgba(0, 0, 0, 0))"
        break;
      case "/predna_hora":

        break;

      default:
        break;
    }
  },[])


  // 80 snowflakes
  return (
    <div id="WINTER_ANIMATION_ID" className="winter_animation">
      <div className="snowflake"></div>
      <div className="snowflake"></div>
      <div className="snowflake"></div>
      <div className="snowflake"></div>
      <div className="snowflake"></div>
      <div className="snowflake"></div>
      <div className="snowflake"></div>
      <div className="snowflake"></div>
      <div className="snowflake"></div>
      <div className="snowflake"></div>
      <div className="snowflake"></div>
      <div className="snowflake"></div>
      <div className="snowflake"></div>
      <div className="snowflake"></div>
      <div className="snowflake"></div>
      <div className="snowflake"></div>
      <div className="snowflake"></div>
      <div className="snowflake"></div>
      <div className="snowflake"></div>
      <div className="snowflake"></div>
      <div className="snowflake"></div>
      <div className="snowflake"></div>
      <div className="snowflake"></div>
      <div className="snowflake"></div>
      <div className="snowflake"></div>
      <div className="snowflake"></div>
      <div className="snowflake"></div>
      <div className="snowflake"></div>
      <div className="snowflake"></div>
      <div className="snowflake"></div>
      <div className="snowflake"></div>
      <div className="snowflake"></div>
      <div className="snowflake"></div>
      <div className="snowflake"></div>
      <div className="snowflake"></div>
      <div className="snowflake"></div>
      <div className="snowflake"></div>
      <div className="snowflake"></div>
      <div className="snowflake"></div>
      <div className="snowflake"></div>
      <div className="snowflake"></div>
      <div className="snowflake"></div>
      <div className="snowflake"></div>
      <div className="snowflake"></div>
      <div className="snowflake"></div>
      <div className="snowflake"></div>
      <div className="snowflake"></div>
      <div className="snowflake"></div>
      <div className="snowflake"></div>
      <div className="snowflake"></div>
      <div className="snowflake"></div>
      <div className="snowflake"></div>
      <div className="snowflake"></div>
      <div className="snowflake"></div>
      <div className="snowflake"></div>
      <div className="snowflake"></div>
      <div className="snowflake"></div>
      <div className="snowflake"></div>
      <div className="snowflake"></div>
      <div className="snowflake"></div>
      <div className="snowflake"></div>
      <div className="snowflake"></div>
      <div className="snowflake"></div>
      <div className="snowflake"></div>
      <div className="snowflake"></div>
      <div className="snowflake"></div>
      <div className="snowflake"></div>
      <div className="snowflake"></div>
      <div className="snowflake"></div>
      <div className="snowflake"></div>
      <div className="snowflake"></div>
      <div className="snowflake"></div>
      <div className="snowflake"></div>
      <div className="snowflake"></div>
      <div className="snowflake"></div>
      <div className="snowflake"></div>
      <div className="snowflake"></div>
      <div className="snowflake"></div>
      <div className="snowflake"></div>
      <div className="snowflake"></div>
    </div>
  )
}

export default WinterAnimation