import React from "react";
import { CreateElementModalProps, OfferElement } from "../../../PropTypes/AdminDashboardProps"
import { IoClose } from "react-icons/io5";
import { IoEyeOutline, IoEyeOffOutline  } from "react-icons/io5";
import { CgFormatSeparator } from "react-icons/cg";
import { FaStar  } from "react-icons/fa";
import { CiImageOn } from "react-icons/ci";
import { FaFlag } from "react-icons/fa";
import { invokeAlertComponent } from "../../../services/userInteractionService";
import { useEffect, useState } from "react";
import SelectorElement from "../SelectorElement";
import { item_icons } from "../../../config/itemIcons";
import { FaTrashAlt } from "react-icons/fa";
import { FaLocationArrow } from "react-icons/fa";
import { MdReadMore } from "react-icons/md";


const CreateElementModal = (props: CreateElementModalProps) => {

  const elementTemplate = {
    belongs_to: "string",
    divide_after: false,
    flag_behind_title: false,
    item_description: "",
    item_expanded_description: "",
    item_expanded_image: "",
    item_is_recommended: false,
    item_flags: [], // Replace 'string' with the type of item flags
    item_is_visible: true,
    item_name: "",
    item_order: 0,
    item_price: "",
    item_type: "ITEM_REGULAR",
    item_volume: "",
    item_without_description: false,
    item_icon: "",
    uuid: "",
    is_recommended: false,
    icon: "",
    icon_size_px: "50px",
    icon_color: "white"
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [image, setLocalImage] = useState("")
  const [iconOfferModal, setIconOfferModal] = useState(false)
  const [extended, showExtended] = useState(false)
  const [selectedIcon, setSelectedIcon] = useState(null)

  const [localSelectedElement, setLocalSelectedElement] =  useState<OfferElement>(elementTemplate)
  const [element, setElement] = useState<OfferElement>(elementTemplate)
  const [localElement, setLocalElement] = useState<any>(null)

  const getElementIcon = (icon: string) => {
    //@ts-ignore
    if (icon && item_icons[icon] && icon !== "item_icon") {
      //@ts-ignore
      return item_icons[icon]; // Return the icon component based on the name
    }
  }

  const handleClose = () => {
    props.onClose()
  }

  const handleConfirm = () => {
    if (element.item_name && element.item_name.trim() !== "") {
      props.onConfirm(element)
    } else {
      invokeAlertComponent("INFORMATION_ALERT_COMPONENT", "Prosím vypĺňte názov položky!", "ALERT");
    }
  }

  const handleSelectIcon = () => {
    setIconOfferModal((prev) => !prev)
  }

  const handleUploadImage = () => {
    // Open file input to select an image
    const input = document.createElement("input");
    input.type = "file";
    input.accept = "image/*"; // Allow only image files

    input.addEventListener("change", (event) => {
      //@ts-ignore
      const selectedFile = event.target.files[0];
      console.log(selectedFile)

      if (selectedFile) {
        // Update the state with the File object
        setElement({ ...element, item_expanded_image: selectedFile });
        setLocalImage(URL.createObjectURL(selectedFile))
        invokeAlertComponent("INFORMATION_ALERT_COMPONENT", "Obrázok bol úspešne vybraný!");
      }
    });

    input.click(); // Trigger file input to open
  };


  useEffect(() => {
    if (element.item_type !== "HEADLINE_REGULAR") {
      setIconOfferModal(false)
    }
  }, [element.item_type])


  useEffect(() => {
    //@ts-ignore
    setElement({...element, order: props.latestOrderNumber})
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.latestOrderNumber])
  useEffect(() => {
    if (props.selectedElement) {
      setElement(props.selectedElement)
      setLocalSelectedElement(props.selectedElement)
      if (props.selectedElement.item_expanded_image !== null) {
        //@ts-ignore
        setLocalImage(`${process.env.REACT_APP_MOJEQR_API_URL}${props.selectedElement.item_expanded_image}`)
      }
    }


  }, [props.selectedElement])

  /* useEffect(() => {
    if(props.type === "update_modal") {
      console.log("update_modal")
      if (props.selectedElement) {
        const { item_expanded_image, ...updatedElement } = props.selectedElement; // Remove item_expanded_image
        //@ts-ignore
        setElement(updatedElement);
      }
    }
  },[]) */

  useEffect(() => {
    if (props.type === "update_modal") {
      console.log("update_modal");
      if (props.selectedElement) {
        const { item_expanded_image, ...updatedElement } = props.selectedElement; // Remove item_expanded_image
        //@ts-ignore
        setElement(updatedElement);
        setLocalElement(updatedElement);

      }
    }
  }, [props.selectedElement]);

  /* useEffect(() => {
    if (!element.item_type) {
      setElement({...element, item_type: "ITEM_REGULAR"})
    }
  }, [element]) */

  const handleFocus = (event: any) => {
    event.target.select();
  }

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === "Escape") { handleClose() }
    }

    window.addEventListener("keydown", handleKeyDown);
    const name_input = document.getElementById("ELEMENT_ID_NAME_INPUT")
    if (name_input) {
      name_input.focus()
    }

    const modal = document.getElementById("CREATE_ELEMENT_MODAL_ID")
    const icon_wrap = document.getElementById("ITEM_ICON_CARD_ID")

    if (modal && icon_wrap) {
      const modalWidth = modal.offsetWidth; // Získaj šírku modalu
      icon_wrap.style.width = `${modalWidth - 90}px`; // Prirad ju druhému elementu
      console.log(icon_wrap.style.width)
    }


    // Cleanup listener when component unmounts
    return () => { window.removeEventListener("keydown", handleKeyDown) }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const modal = document.getElementById("CREATE_ELEMENT_MODAL_ID")
    const icon_wrap = document.getElementById("ITEM_ICON_CARD_ID")

    setTimeout(() => {
      if (modal && icon_wrap) {
        const modalWidth = modal.offsetWidth; // Získaj šírku modalu
        icon_wrap.style.width = `${modalWidth - 90}px`; // Prirad ju druhému elementu
        console.log(icon_wrap.style.width)
      }
    }, 300);
  }, [extended])

  return(
    <div
      className="confirmation_modal create_element_modal card_element"
      /* onClick={() => props.onClose()} */
    >
      <div id="CREATE_ELEMENT_MODAL_ID" className="modal" onClick={(event) => {event.stopPropagation()}}>
        <IoClose
          className="close_icon"
          onClick={(event) => {
            event.stopPropagation()
            handleClose()
          }}
          size={"25px"}
          color="#2C2C2C"
        />
        <div className="content">
          <div className="title_row">
            {
              props.type === "create_modal" ? "Vytvorte novú položku" : "Upravte položku"
            }
            <MdReadMore
              className="icon"
              size={"25px"}
              style={{
                transform: extended ? "rotate(180deg)" : "rotate(0deg)"
              }}
              onClick={() => { showExtended((prev: any) => !prev)}}
            />
          </div>
          {/* <div className="field_element add_after_element_selector_wrap">
            <label>Umiestnenie položky</label>
            <SelectorElement
              list={sectionList}
              onSelect={(selected) => { handleOrderChange(selected) }}
            />
          </div> */}
          <div className="section_row">
          {/*   section selector */}
          </div>
          <div className="fields_wrap">
            <div className="fields_col_1">
              <div className="field_element">
                <label htmlFor="ELEMENT_ID_NAME">Názov</label>
                <div className="field card_element">
                  <input
                    id="ELEMENT_ID_NAME_INPUT"
                    value={element.item_name}
                    type="text"
                    placeholder="Zadajte názov"
                    onFocus={handleFocus}
                    onChange={(e) => {
                      setElement({...element, item_name: e.target.value})
                    }}
                  />
                </div>
              </div>
              <div className="field_element">
                <label htmlFor="ELEMENT_ID_DESCR">Popis</label>
                <div className={`field description_card_element card_element ${(element.item_type === "ITEM_IMAGE") && "description_card_element_disabled" }`}>
                  <textarea
                    id="ELEMENT_ID_DESCR"
                    value={element.item_description}
                    placeholder="Zadajte popis"
                    disabled={element.item_type === "ITEM_IMAGE"}
                    onFocus={handleFocus}
                    rows={6}
                    onChange={(e) => {setElement({...element, item_description: e.target.value})}}
                  />
                </div>
              </div>
              <button
                className="button"
                onClick={(event) => {
                  event.stopPropagation()
                  handleConfirm()
                }}
              >
                { props.selectedElement !== null ? "Uložiť položku": "Potvrdiť" }
              </button>
            </div>
            <div className="fields_col_2">
              <div className="row_1">
                <div className="field_element"
                >
                  <label htmlFor="ELEMENT_ID_PRICE">Cena</label>
                  <div className="price_field card_element">
                    <input
                      value={element.item_price}
                      onFocus={handleFocus}
                      type="text"
                      id="ELEMENT_ID_PRICE"
                      placeholder="Zadajte cenu"
                      onChange={(e) => {setElement({...element, item_price: e.target.value})}}
                    />
                  </div>
                </div>
                <div className="field_element"
                >
                  <label htmlFor="ELEMENT_ID_VOLUME">Objem</label>
                  <div className="volume_field card_element">
                    <input
                      value={element.item_volume}
                      onFocus={handleFocus}
                      type="text"
                      id="ELEMENT_ID_VOLUME"
                      placeholder="Zadajte objem"
                      onChange={(e) => {setElement({...element, item_volume: e.target.value})}}
                    />
                  </div>
                </div>
              </div>
              <div className="row_2">
                <div className="checkbox_wrap"
                  style={{
                    opacity: (element.item_type !== "HEADLINE_REGULAR" && element.item_type !== "ITEM_IMAGE") ? 1 : 0.2,
                    cursor: (element.item_type !== "HEADLINE_REGULAR" && element.item_type !== "ITEM_IMAGE") ? "pointer" : "default",
                    transition: "0.3s"
                  }}
                >
                  <div
                    className="card_element checkbox"
                    style={{
                      cursor: (element.item_type !== "HEADLINE_REGULAR" && element.item_type !== "ITEM_IMAGE") ? "pointer" : "default"
                    }}
                    onClick={
                      () => { (element.item_type !== "HEADLINE_REGULAR" && element.item_type !== "ITEM_IMAGE") && setElement({...element, item_is_recommended: !element.item_is_recommended}) }
                    }
                  >
                    {
                      <FaStar
                        className="flag_icon"
                        size={"15px"}
                        color={
                          element.item_is_recommended ? "#F7C808" : "#A4A4A4"
                        }
                      />
                    }
                  </div>
                  Odporúčané podnikom
                </div>
                <div className="checkbox_wrap"
                  style={{
                    opacity: (element.item_type !== "HEADLINE_REGULAR" && element.item_type !== "ITEM_IMAGE") ? 1 : 0.2,
                    cursor: (element.item_type !== "HEADLINE_REGULAR" && element.item_type !== "ITEM_IMAGE") ? "pointer" : "default",
                    transition: "0.3s"
                  }}
                >

                  <div
                    className="card_element checkbox"
                    style={{
                      cursor: (element.item_type !== "HEADLINE_REGULAR" && element.item_type !== "ITEM_IMAGE") ? "pointer" : "default"
                    }}
                    onClick={
                      () => { (element.item_type !== "HEADLINE_REGULAR" && element.item_type !== "ITEM_IMAGE") && setElement({...element, flag_behind_title: !element.flag_behind_title}) }
                    }
                  >
                    <FaFlag
                      className="flag_icon"
                      size={"15px"}
                      color={ element.flag_behind_title ? "#ff4500" : "#A4A4A4"}
                    />
                  </div>
                  Vlajka za názvom
                </div>
                <div className="checkbox_wrap"
                  style={{
                    opacity: (element.item_type !== "HEADLINE_REGULAR" && element.item_type !== "ITEM_IMAGE") ? 1 : 0.2,
                    cursor: (element.item_type !== "HEADLINE_REGULAR" && element.item_type !== "ITEM_IMAGE") ? "pointer" : "default",
                    transition: "0.3s"
                  }}
                >
                  <div
                    className="card_element checkbox"
                    onClick={
                      (element.item_type !== "HEADLINE_REGULAR" && element.item_type !== "ITEM_IMAGE") ? () => { setElement({...element, divide_after: !element.divide_after}) } : () => {}
                    }

                    style={{
                      cursor: (element.item_type !== "HEADLINE_REGULAR" && element.item_type !== "ITEM_IMAGE") ? "pointer" : "default"
                    }}
                  >
                    <CgFormatSeparator
                      className="flag_icon"
                      size={"15px"}
                      color={ element.divide_after ? "#ff4500" : "#A4A4A4"}
                    />
                  </div>
                  Posledná položka podsekcie
                </div>
                <div className="checkbox_wrap">

                  <div
                    className="card_element checkbox"
                    onClick={
                      () => { setElement({...element, item_is_visible: !element.item_is_visible}) }
                    }
                  >
                    {
                    !element.item_is_visible ?
                      <IoEyeOffOutline className="icon" size={"15px"} color="#2C2C2C" onClick={
                        () => { setElement({...element, item_is_visible: !element.item_is_visible}) }
                      }/>
                      :
                      <IoEyeOutline className="icon" size={"15px"} color="#2C2C2C" onClick={
                        () => { setElement({...element, item_is_visible: !element.item_is_visible}) }
                      }/>
                    }
                  </div>
                  { element.item_is_visible ? "Schovať položku" : "Zobraziť položku v ponuke"}
                </div>
              </div>
            </div>
          <div className="fields_col_3">
            <div className="field_element" style={{ maxWidth: "210px"}}>
              <label>Typ</label>
              <SelectorElement
                selectedElement={props.selectedElement?.item_type}
                list={
                  [
                    {
                      title: "Položka sekcie",
                      value: "ITEM_REGULAR"
                    },
                    {
                      title: "Obrázok",
                      value: "ITEM_IMAGE"
                    },
                    {
                      title: "Nadpis sekcie",
                      value: "HEADLINE_REGULAR"
                    },
                    /* {
                      title: "Názov, popis, objem, cena, ikonka",
                      value: "ITEM_REGULAR_WITH_ICON"
                    },
                    {
                      title: "Obrázok bez textu a náhľadu",
                      value: "ITEM_IMAGE"
                    },

                    {
                      title: "Oddeľovač sekcií",
                      value: "ITEM_WITH_ICON"
                    }, */
                    /* {
                      title: "Nadpis sekcie, ikonka",
                      value: "HEADLINE_REGULAR_WITH_ICON"
                    },
                    {
                      title: "Nadpis s popisom",
                      value: "HEADLINE_DESCRIPTION"
                    },
                    {
                      title: "Nadpis s ikonou (bez ceny a objemu)",
                      value: "HEADLINE_ICON"
                    },
                    {
                      title: "Nadpis s popisom a ikonou (bez ceny a objemu)",
                      value: "HEADLINE_ICON_DESCRIPTION"
                    } */
                  ]
                }
                onSelect={(new_type) => { setElement({...element, item_type: new_type}) }}
              />

            </div>

            <div className="field_element">
              <div
                className="field card_element item_preview_wrap"
                onClick={
                  element.item_type === "ITEM_REGULAR" || element.item_type === "ITEM_IMAGE" || !element.item_type
                    ? handleUploadImage
                    : handleSelectIcon

                }
              >
                {
                  (localSelectedElement && getElementIcon(localSelectedElement.item_icon)) ?
                  React.createElement(getElementIcon(localSelectedElement.item_icon), {
                    className: "icon",
                    color:  "white",
                    size: "55px",
                  })
                  :
                  (element.item_icon && getElementIcon(element.item_icon)) ?
                  React.createElement(getElementIcon(element.item_icon), {
                    className: "icon",
                    color:  "white",
                    size: "55px",
                  })
                  :
                  image
                    ?
                      <img src={image} alt="Preview" className="item_preview"/>
                    :
                      <CiImageOn
                        className="icon"
                        size={"55px"}
                        color="white"
                      />
                }
                {
                  element.item_type === "HEADLINE_REGULAR" ?
                    (localSelectedElement &&
                     getElementIcon(localSelectedElement.item_icon)) ||
                     (element.item_icon && getElementIcon(element.item_icon)
                    )
                      ? "Zmeniť ikonu"
                      : "Vybrať ikonu" :
                        image
                          ? "Zmeniť obrázok"
                          : "Nahrať obrázok"
                }
                {
                  ((localSelectedElement && getElementIcon(localSelectedElement.item_icon)) || (element.item_icon && getElementIcon(element.item_icon)) || image) &&
                  <FaTrashAlt
                    className="remove_image_icon"
                    size={"20px"}
                    onClick={
                      (event) => {
                        event.stopPropagation()
                        if (image) {
                          setElement({ ...element, item_expanded_image: "" })
                          setLocalImage("")
                          invokeAlertComponent("INFORMATION_ALERT_COMPONENT", "Obrázok bol odstránený - stačí uložiť položku alebo vybrať nový obrázok!");
                        } else {
                          setElement({ ...element, item_icon: "" })
                          setLocalSelectedElement({ ...element, item_icon: "" })

                        }
                      }
                    }
                  />
                }
              </div>
              </div>

            </div>

            <div className={`extended_section ${extended ? "extended_section_visible" : "extended_section_hidden"}`}>
              <div className="field_element">
                <label htmlFor="ELEMENT_ID_DESCR">Rozšírený popis</label>
                <div className={`field description_card_element card_element ${(element.item_type === "ITEM_IMAGE") && "description_card_element_disabled" }`}>
                  <textarea
                    id="ELEMENT_ID_DESCR"
                    value={element.item_expanded_description}
                    placeholder="Zadajte popis ktorý sa otvorí ako obrázok po kliknutí na položku"
                    disabled={element.item_type === "ITEM_IMAGE" || !!element.item_expanded_image}
                    rows={6}
                    onChange={(e) => {setElement({...element, item_expanded_description: e.target.value})}}
                  />
                </div>
              </div>
              <div className="checkbox_wrap"
                style={{
                  marginTop: "10px",
                  opacity: (element.item_type === "HEADLINE_REGULAR" ) ? 1 : 0.2,
                  cursor: (element.item_type === "HEADLINE_REGULAR") ? "pointer" : "default",
                  transition: "0.3s"
                }}
              >
                <div
                  className="card_element checkbox"
                  style={{
                    cursor: (element.item_type === "HEADLINE_REGULAR") ? "pointer" : "default"
                  }}
                  onClick={
                    () => { (element.item_type === "HEADLINE_REGULAR") && setElement({...element, item_redirect_target: !element.item_redirect_target}) }
                  }
                >
                  {
                    <FaLocationArrow
                      className="flag_icon"
                      size={"15px"}
                      color={
                        element.item_redirect_target ? "#CF966E" : "#A4A4A4"
                      }
                    />
                  }
                </div>
                {element.item_redirect_target ? "Odstrániť presmerovanie" : "Pridať presmerovanie"}

              </div>
              {
                (props.businessEstablishment?.item_flag_1 || props.businessEstablishment?.item_flag_2 || props.businessEstablishment?.item_flag_3) &&
                <div className="field_element ">
                  <div
                    style={{
                      opacity: (element.item_type !== "HEADLINE_REGULAR" && element.item_type !== "ITEM_IMAGE") ? 1 : 0.2,
                      transition: "0.3s"
                    }}
                  >
                    Vybrať vlajky
                  </div>
                  <div className="select_flag_wrap">
                    {
                      props.businessEstablishment?.item_flag_1 &&
                      <div
                        className="admin_flag_preview"
                        onClick={() => {
                          (element.item_type !== "HEADLINE_REGULAR" && element.item_type !== "ITEM_IMAGE") && setElement({...element, use_flag_1: !element.use_flag_1})}}
                        style={{
                          opacity: (element.item_type !== "HEADLINE_REGULAR" && element.item_type !== "ITEM_IMAGE") ? 1 : 0.2,
                          cursor: (element.item_type !== "HEADLINE_REGULAR" && element.item_type !== "ITEM_IMAGE") ? "pointer" : "default",
                        }}
                      >
                        <img alt="flag_1" className={`${element.use_flag_1 && "flag_selected"}`} src={`${process.env.REACT_APP_MOJEQR_API_URL}/${props.businessEstablishment?.item_flag_1}`} />
                      </div>
                    }
                    {
                      props.businessEstablishment?.item_flag_2 &&
                      <div
                        className="admin_flag_preview"
                        onClick={() => {(element.item_type !== "HEADLINE_REGULAR" && element.item_type !== "ITEM_IMAGE") && setElement({...element, use_flag_2: !element.use_flag_2})}}
                        style={{
                          opacity: (element.item_type !== "HEADLINE_REGULAR" && element.item_type !== "ITEM_IMAGE") ? 1 : 0.2,
                          cursor: (element.item_type !== "HEADLINE_REGULAR" && element.item_type !== "ITEM_IMAGE") ? "pointer" : "default",
                        }}
                      >
                        <img alt="flag_2" className={`${element.use_flag_2 && "flag_selected"}`} src={`${process.env.REACT_APP_MOJEQR_API_URL}/${props.businessEstablishment?.item_flag_2}`} />
                      </div>
                    }
                    {
                      props.businessEstablishment?.item_flag_3 &&
                      <div
                        className="admin_flag_preview"
                        onClick={() => {(element.item_type !== "HEADLINE_REGULAR" && element.item_type !== "ITEM_IMAGE") && setElement({...element, use_flag_3: !element.use_flag_3})}}
                        style={{
                          opacity: (element.item_type !== "HEADLINE_REGULAR" && element.item_type !== "ITEM_IMAGE") ? 1 : 0.2,
                          cursor: (element.item_type !== "HEADLINE_REGULAR" && element.item_type !== "ITEM_IMAGE") ? "pointer" : "default",
                        }}
                      >
                        <img alt="flag_3" className={`${element.use_flag_3 && "flag_selected"}`} src={`${process.env.REACT_APP_MOJEQR_API_URL}/${props.businessEstablishment?.item_flag_3}`} />
                      </div>
                    }
                  </div>
                </div>
              }
            </div>
          </div>


        </div>
      </div>
      <div
        id="ITEM_ICON_CARD_ID"
        className={`item_icon_card card_element  ${iconOfferModal ? "offer_block_icon_card_visible" : "offer_block_icon_card_hidden"}`}
      >
      {
        Object.keys(item_icons).map((key: string) => {
          //@ts-ignore
          const Icon = item_icons[key]; // Get the icon component by key

          return (
            <div
              key={key}
              className="icon"
              onClick={() => {
                setSelectedIcon(prev => Icon); // Set the selected icon
                setElement({ ...element, item_icon: key }); // Update the element with the icon's name (key)

              }}
            >
              <Icon
                size={"24px"}
                color={"white"}
              />
            </div>
          );
        })
      }
      </div>
    </div>
  )
}

export default CreateElementModal