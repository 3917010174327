import uuid from "react-uuid"
import { BsCreditCard2BackFill } from "react-icons/bs"

export const alkoholicke_napoje = [
  {
    item_uuid: uuid(),
    title: "Absolut vodka",
    descr: "40 %",
    volume: "0,04 l",
    price: "2,70 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Russian Standard original",
    descr: "40 %",
    volume: "0,04 l",
    price: "2,80 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Havana Club Añejo 7 Años",
    descr: "40 %",
    volume: "0,04 l",
    price: "3,70 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Brugal 1888 Gran Reserva",
    descr: "40 %",
    volume: "0,04 l",
    price: "5,50 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Diplomático Reserva Exclusiva",
    descr: "40 %",
    volume: "0,04 l",
    price: "5,40 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Plantation XO 20th Anniversary",
    descr: "40 %",
    volume: "0,04 l",
    price: "6,30 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Jameson",
    descr: "40 %",
    volume: "0,04 l",
    price: "3,20 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Jack Daniels",
    descr: "40 %",
    volume: "0,04 l",
    price: "3,90 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Tullamore Dew",
    descr: "40 %",
    volume: "0,04 l",
    price: "3,10 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Chivas Regal 12 YO",
    descr: "40 %",
    volume: "0,04 l",
    price: "3,90 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Ararat Akhtamar Aged 10 Years Brandy",
    descr: "40 %",
    volume: "0,04 l",
    price: "3,60 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "BVD Marhuľovica",
    descr: "45 %",
    volume: "0,04 l",
    price: "5,00 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "BVD Čerešňovica",
    descr: "45 %",
    volume: "0,04 l",
    price: "5,00 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Jägermeister",
    descr: "35 %",
    volume: "0,04 l",
    price: "2,30 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Becherovka",
    descr: "38 %",
    volume: "0,04 l",
    price: "2,30 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Fernet Stock Original",
    descr: "38 %",
    volume: "0,04 l",
    price: "2,30 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Fernet Stock Citrus",
    descr: "27 %",
    volume: "0,04 l",
    price: "2,30 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Borovička Spišská",
    descr: "40 %",
    volume: "0,04 l",
    price: "2,20 €",
    divideAfter: true
  },
  {
    type: "extra",
    icon: BsCreditCard2BackFill,
    iconSize: "25px",
    item_uuid: uuid(),
    title: "Možná platba kartou",
    descr: "",
    volume: "",
    price: "",
    noDescr: true,
    divideAfter: false,
    lastElement: true
  },

]