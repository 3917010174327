import ItemElementOnline from "./ItemElementOnline"
import { useEffect, useState } from "react"
import HtmlParser from "../../services/htmlParser"
//@ts-ignore
import defaultSectionIcon from "../../assets/images/clients/icons/panorama/coffee-cup.svg"
import React from "react";
import { item_icons } from "../../config/itemIcons";
interface localProps {
  id: string
  icon: string,
  iconWidth: string,
  iconMarginRight?: string,
  page_uuid: string,
  title: string,
  list: Array<any>,
  isOpen: boolean,
  recommended_flag: string,
  rowIndex: number,
  blockIndex: number,
  closePage: (current_page_uuid: string, rowIndex: number, blockIndex: number) => void,
  style: object,
  localConfig: any

}

const PageElementOnline = (props: localProps) => {

  const [blocks] = useState(props.list.filter(element => element.item_redirect_target && element.item_is_visible))
  const [blockAnimationClass, setBlockAnimationClass] = useState("")
  const handlePageOpen = (page_uuid: string) => {
    props.closePage(page_uuid, props.rowIndex, props.blockIndex)
  }

  const removeBreak = (title: string) => {
    return props.localConfig.alignTitleToLeft ? title.replace(/<.*>/, ' ') : title.replace(/<.*>/, ' / ')
  }

  const getElementIcon = (icon: string) => {
      //@ts-ignore
      if (icon && item_icons[icon] && icon !== "item_icon") {
        //@ts-ignore
        return item_icons[icon]; // Return the icon component based on the name
      }
    };

  const handleBlockClick = (id: string) => {
    setTimeout(() => {
      const element = document.getElementById(id)!
      var headerOffset = 10
      var elementPosition = element.getBoundingClientRect().top
      var offsetPosition = elementPosition + window.pageYOffset - headerOffset

      window.scrollTo({
          top: offsetPosition,
          behavior: "smooth"
      })

    }, 200)
  }

  useEffect(() => {
    console.log(props.list)
      setTimeout(() => {
        setTimeout(() => {
          setBlockAnimationClass("loaded_animation")
        }, 200*props.rowIndex);
        /* intro screen load time */
      }, 1300);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
  return (
    <div
      className={
        `panorama_page_element ${props.isOpen
          ? "panorama_page_element_open"
          : props.localConfig.alignTitleToLeft ? "panorama_page_element_close_title_in_row" : "panorama_page_element_close"} ${blockAnimationClass}`}
      id={props.id}
      style={
        {
          ...props.style,
          /* animationDelay: `${0.2*props.rowIndex}s` */
        }
      }
    >
      <div
        className="title_wrap"
        onClick={() => handlePageOpen(props.page_uuid)}
      >
        <div className="img_wrap">
          <img
            src={!props.icon.includes("null") ? props.icon : defaultSectionIcon}
            alt=""
            style={{
              width: props.iconWidth,
              marginBottom: (props.title.includes("<br") && !props.isOpen) ? "-5px" : "0px",
              marginRight: props.iconMarginRight || "0px"
            }}
          />
        </div>
        <h1
          style={{
            marginBottom: (props.title.includes("<br") && !props.isOpen) ? "5px" : "15px",
            textAlign: (props.isOpen) ? "left" : "center"
          }}
        >
          <HtmlParser>
            {
              ((props.title.includes("<br") && props.isOpen) || props.localConfig.alignTitleToLeft)
                ? removeBreak(props.title)
                : props.title
            }
          </HtmlParser>
        </h1>
      </div>
      <div
        className="items_wrap"
        style={{
          maxHeight: (props.list && props.isOpen) ? "500000px" : "0px",
          maxWidth: (props.list && props.isOpen) ? "500000px" : "0px"
        }}
      >

        <div className="blocks_wrap">
          {
            blocks && blocks.map((element, index) =>
              <div
                className="block_item"
                key={index}
                onClick={() => {
                  handleBlockClick(`${element.uuid}_ID`)
                }}
              >
                {React.createElement(getElementIcon(element.item_icon), {
                  className: "icon",
                  color: "white",
                  size: "45px",
                })}
                {/* <element.item_icon
                  className="icon"
                  size={element.iconSize || "30px"}
                /> */}
                  { element.item_redirect_target_title }
                {/* { element.blockTitle } */}
              </div>
            )

          }
        </div>
        {
          props.list && props.list.map((element, index) =>
            element.item_type === "ITEM_IMAGE"
              ? element.item_is_visible &&
              <img
                style={{width: "100%", borderRadius: "8px"}}
                src={`${process.env.REACT_APP_MOJEQR_API_URL}${element.item_expanded_image}`} alt="MojeQR - Obrázok"
              />
              : element.item_is_visible &&
              <ItemElementOnline
                key={index}
                recommended_flag={props.recommended_flag}
                element={element}
                localConfig={props.localConfig}
              />

          )
        }
      </div>
    </div>
  )
}

export default PageElementOnline
