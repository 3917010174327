import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TiThMenuOutline } from "react-icons/ti";
import { AiOutlineCloseCircle } from "react-icons/ai"

import { useNavigate, useLocation  } from "react-router-dom";


const Header = () => {
  const { t,i18n } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()

  const [headerOpen, setHeaderOpen] = useState(false)
  const [firstRender, setFirstRender] = useState(true)

  const languageFlag = require(i18n.language === "en" ? "../../assets/images/language_flags/sk.png" : "../../assets/images/language_flags/en.png")


  const logo = require("../../assets/images/logo.jpg")
  /* const logo = require("../../assets/images/logo_christmas.png") */
  const animationDuration = "0.5s"
  const headerHeight = "490px"

  const changeLanguage = (lng: "sk" | "en") => {
      i18n.changeLanguage(lng);
  };

  const scrollTo = (elementId: string) => {
    if (
      location.pathname === "/kontakt" ||
      location.pathname === "/config" ||
      location.pathname === "/blog" ||
      location.pathname === "/cookies"
    ) {
      navigate("/")
    }
    setHeaderOpen(false)
    setTimeout(() => {
      // document.getElementById(elementId)?.scrollIntoView({ top: 50, behavior: "smooth" })
      window.scrollTo({
        behavior: 'smooth',
        top:
          document.querySelector(`#${elementId}`)!.getBoundingClientRect().top -
          document.body.getBoundingClientRect().top - 60,
      })
    }, 100);
  }

  const handleMenuOpen = () => {
    setFirstRender(false)
    setHeaderOpen(prev => {
      return !prev
    })
  }

  window.addEventListener("resize", () => {
    setHeaderOpen(false)
  })

  useEffect(() => {
    if (window.location.pathname === "/sluzby" || window.location.pathname === "/klienti" || window.location.pathname === "/cennik") {
      document.getElementById("HEADER_ID")!
          .style
          .backgroundColor = `#6E57DA`
    }
  },[])


  return (
    <div className='header'
    id='HEADER_ID'>
      <div
        className="header_wrap"
        key={i18n.language}
        style={{
          overflow: "hidden",
          transition: "0.5s",
          backgroundColor: headerOpen ? "rgba(0, 0, 0, 0.9)" : "transparent",
          animation: headerOpen
              ? `headerOpenAnimation ${animationDuration}`
              : firstRender ? "none" :`headerCloseAnimation ${animationDuration}`,
          height: headerOpen ? headerHeight : "100px"
        }}
      >
        <div className="logo">
          <img
            src={logo}
            alt=""
            onClick={() => {scrollTo("INTRO_CARD_ID")}}
          />
        </div>
        <ul
        >
          <li
            className='header_home'
            onClick={() => {scrollTo("INTRO_CARD_ID")}}
          >
            { t("header.home") }
          </li>
          <li
            className='header_service'
            onClick={() => {scrollTo("SERVICE_CARD_ID")}}
          >
            { t("header.service") }
          </li>
          <li
            className='header_pricing'
            onClick={() => {scrollTo("CLIENTS_CARD_ID")}}
          >
            { t("header.clients") }
          </li>
          <li
            className='header_pricing'
            onClick={() => {scrollTo("PRICING_CARD_ID")}}
          >
            { t("header.pricing") }
          </li>
          <li
            className='header_contact'
            onClick={() => {scrollTo("CONTACT_CARD_ID")}}
          >
          { t("header.contact") }
          </li>

          <li
            className='header_login'
            onClick={() => {
              document.body.classList.add("hide-scale-background")
              document.body.classList.remove("scale-background")
              setTimeout(() => {
                navigate("/admin/prihlasenie")
              }, 500);
            }}
          >
          { t("header.login") }
          </li>
          <li
            className='header_language'
            onClick={() => {changeLanguage(i18n.language === "sk" ? "en" : "sk")}}
          >
            {/* { t("header.language") } */}
            <img src={languageFlag} alt="" />
          </li>

        </ul>
        <div
          className='header_hamburger'
          style={{
            transform: !headerOpen ? "rotate(0deg)" : "rotate(180deg)"
          }}
        >
          <AiOutlineCloseCircle
            onClick={handleMenuOpen}
            style={{
              borderRadius: "8px"
            }}
            size={"60px"}
          />
          <TiThMenuOutline
            onClick={handleMenuOpen}
            style={{
              borderRadius: "8px"
            }}
            size={"60px"}
          />
        </div>
      </div>
    </div>
  )
}

export default Header