import uuid from "react-uuid"
import { BsCreditCard2BackFill } from "react-icons/bs"
import sk_flag from "../../../assets/images/language_flags/sk.png"
import new_flag from "../../../assets/images/clients/icons/panorama/new_flag.png"

export const limonady = [
  {
    type: "extra",
    icon: null,
    iconSize: "35px",
    iconColor: "white",
    item_uuid: uuid(),
    title: "Limonády",
    noDescr: true,
    descr: "",
    volume: "",
    price: "",
    divideAfter: true,
  },
  {
    item_uuid: uuid(),
    title: "Baza-Citrón",
    descr: "",
    flag: sk_flag,
    flagHeight: "12px",
    volume: "0,45 l",
    price: "4,50 €",
    /* img: require("../../../assets/images/clients/items/Panorama/baza-citron.jpg"), */
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Grep-Bazalka",
    descr: "",
    flag: sk_flag,
    flagHeight: "12px",
    volume: "0,45 l",
    price: "4,50 €",
    /* img: require("../../../assets/images/clients/items/Panorama/grep-bazalka.jpg"), */
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Levanduľa-Arónia",
    descr: "",
    flag: sk_flag,
    flagHeight: "12px",
    volume: "0,45 l",
    price: "4,50 €",
    /* img: require("../../../assets/images/clients/items/Panorama/levandula-aronia.jpg"), */
    divideAfter: false
  },
  /* {
    item_uuid: uuid(),
    title: "Ruža-Liči",
    descr: "",
    flag: sk_flag,
    flagHeight: "12px",
    volume: "0,45 l",
    price: "3,90 €",
    img: require("../../../assets/images/clients/items/Panorama/limonady/ruza-lici.jpg"),
    divideAfter: false
  }, */
  /* {
    item_uuid: uuid(),
    title: "Uhorka-Kafírové listy",
    descr: "",
    flag: sk_flag,
    flagHeight: "12px",
    volume: "0,45 l",
    price: "3,90 €",
    img: require("../../../assets/images/clients/items/Panorama/limonady/uhorka-kafirove-listy.jpg"),
    divideAfter: false
  }, */
  {
    item_uuid: uuid(),
    title: "Passionfruit-Mandarínka",
    descr: "",
    flag: new_flag,
    volume: "0,45 l",
    price: "4,50 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Citronáda",
    descr: "",
    volume: "0,45 l",
    price: "4,00 €",
    divideAfter: true
  },
  {
    type: "extra",
    icon: null,
    iconSize: "35px",
    iconColor: "white",
    item_uuid: uuid(),
    title: "Džbán",
    noDescr: true,
    descr: "",
    volume: "",
    price: "",
    divideAfter: true,
  },
  {
    item_uuid: uuid(),
    title: "Citrón-Mäta",
    descr: "",
    volume: "1,00 l",
    price: "4,40 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Grep-Rozmarín",
    descr: "",
    volume: "1,00 l",
    price: "4,40 €",
    divideAfter: true
  },
  {
    type: "extra",
    icon: null,
    iconSize: "35px",
    iconColor: "white",
    item_uuid: uuid(),
    title: "Smoothie",
    noDescr: true,
    descr: "",
    volume: "",
    price: "",
    divideAfter: true,
  },
  {
    item_uuid: uuid(),
    title: "Čučoriedka-Malina",
    descr: "",
    volume: "0,25 l",
    price: "3,00 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Ananás-Kokos",
    descr: "",
    volume: "0,25 l",
    price: "3,00 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Mango-Pomaranč",
    descr: "",
    volume: "0,25 l",
    price: "3,00 €",
    divideAfter: true
  },
  {
    type: "extra",
    icon: BsCreditCard2BackFill,
    iconSize: "25px",
    item_uuid: uuid(),
    title: "Možná platba kartou",
    descr: "",
    volume: "",
    price: "",
    noDescr: true,
    divideAfter: false,
    lastElement: true
  },
]