import uuid from "react-uuid"
import { BsCreditCard2BackFill } from "react-icons/bs"
import sk_flag from "../../../assets/images/language_flags/sk.png"


export const gin = [
  {
    item_uuid: uuid(),
    title: "Lúčky Gin",
    descr: "40 %",
    flag: sk_flag,
    flagHeight: "12px",
    volume: "0,04 l",
    price: "2,60 €",
    divideAfter: false
  },
  /* {
    item_uuid: uuid(),
    title: "Bombay Sapphire",
    descr: "40 %",
    volume: "0,04 l",
    price: "1,40 €",
    divideAfter: false
  }, */
  {
    item_uuid: uuid(),
    title: "Gin Mare",
    descr: "42,7 %",
    volume: "0,04 l",
    price: "4,80 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Hendricks",
    descr: "41,4 %",
    volume: "0,04 l",
    price: "4,10 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Plymouth",
    descr: "41,2 %",
    volume: "0,04 l",
    price: "3,60 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Plymouth Navy Strength Gin",
    descr: "57 %",
    volume: "0,04 l",
    expandableDescr: [
      "Dôvod, prečo dostal Plymouth Navy Strength gin svoj názov, siaha až do 18. storočia.",
      "V britskom námorníctve sa gin skladoval na lodiach vedľa pušného prachu.",
      "Ak by sa sudy s ginom rozliali a dostali do pušného prachu, 57% obsah alkoholu by zabezpečil, že pušný prach by sa neznehodnotil a stále by bol výbušný.",
      "Tento gin, si však nezachoval len vysoký obsah alkoholu, ale aj pôvodný tvar fľaše."
    ],
    price: "4,80 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Tanqueray",
    descr: "43,1 %",
    volume: "0,04 l",
    price: "3,50 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "The Botanist Islay Dry",
    descr: "46 %",
    volume: "0,04 l",
    price: "4,30 €",
    divideAfter: true
  },
  /* {
    item_uuid: uuid(),
    title: "Beefeater London Dry Gin",
    descr: "40 %",
    volume: "0,04 l",
    price: "3,00 €",
    divideAfter: true
  }, */
  /* {
    item_uuid: uuid(),
    title: "Beefeater 24 Gin",
    descr: "45 %",
    volume: "0,04 l",
    price: "3,40 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Beefeater London Dry Gin",
    descr: "40 %",
    volume: "0,04 l",
    price: "2,30 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Beefeater Pink Strawberry Gin",
    descr: "37,5 %",
    volume: "0,04 l",
    price: "2,40 €",
    divideAfter: false
  }, */
  /* {
    item_uuid: uuid(),
    title: "Malfy gin Rosa/ Con Limone/ Con Arancia",
    descr: "41 %",
    volume: "0,04 l",
    price: "3,30 €",
    divideAfter: false
  }, */
  {
    type: "extra",
    icon: null,
    iconSize: "45px",
    item_uuid: uuid(),
    title: "FLAVOURED",
    descr: "",
    volume: "",
    price: "",
    noDescr: true,
    divideAfter: true,
    lastElement: false
  },
  {
    item_uuid: uuid(),
    title: "Lúčky Pink Gin",
    descr: "37,5 %",
    flag: sk_flag,
    flagHeight: "12px",
    volume: "0,04 l",
    price: "2,90 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Beefeater Pink Strawberry Gin",
    descr: "37,5 %",
    volume: "0,04 l",
    price: "2,80 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Malfy Gin Rosa",
    descr: "41 %",
    volume: "0,04 l",
    price: "3,80 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Malfy Gin Con Limone ",
    descr: "41 %",
    volume: "0,04 l",
    price: "3,80 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Malfy Gin Con Arancia",
    descr: "41 %",
    volume: "0,04 l",
    price: "3,80 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Tanqueray Blackcurrant Royale",
    descr: "41,3 %",
    volume: "0,04 l",
    price: "3,80 €",
    divideAfter: true
  },
  {
    type: "extra",
    icon: BsCreditCard2BackFill,
    iconSize: "25px",
    item_uuid: uuid(),
    title: "Možná platba kartou",
    descr: "",
    volume: "",
    price: "",
    noDescr: true,
    divideAfter: false,
    lastElement: true
  },
]