import uuid from "react-uuid"
import { FaInfoCircle } from "react-icons/fa";
export const vino = [
  {
    item_uuid: uuid(),
    type: "extra",
    title: "Biele víno",
    descr: "Vinárstva z malokarpatského kraja",
    volume: "",
    price: "",
    noDescr: false,
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Veltínske zelené",
    descr: "r. 2023, suché",
    volume: "0,1 l",
    price: "2,90 €",
    expandableDescr: [
      "Víno žlto-zelenej farby s výraznou ovocnou vôňou.",
      "Má sviežu a vyváženú chuť, ktorá je podporená šťavnatými kyselinami.",
      "Vegánske, obsah histamínu je menej ako 0,25 mg/l."
    ],
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Muškát moravský",
    descr: "r. 2023, suché",
    volume: "0,1 l",
    price: "2,90 €",
    expandableDescr: [
      "Víno zlato-žltej farby s ovocno-kvetinovou vôňou.",
      "Chuť jemne korenistá a zároveň príjemne osviežujúca. Vegánske, obsah histamínu je menej ako 0,25 mg/l.",
    ],
    divideAfter: true
  },
  {
    item_uuid: uuid(),
    type: "extra",
    title: "Ružové víno",
    descr: "Vinárstva z malokarpatského kraja",
    volume: "",
    price: "",
    noDescr: false,
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Cabernet Sauvignon",
    descr: "r. 2023, polosladké",
    volume: "0,1 l",
    price: "2,90 €",
    expandableDescr: [
      "Ružové víno s výraznou jahodovo-ruťovou farbou so sviežou ovocnou arómou, kde dominuje čierna ríbezľa a žihľava",
      "Chuť príjemná, podporená pevnými kyselinami. Označenie BIO, vegánske a nízkohistamínové.",
    ],
    divideAfter: true
  },
  {
    item_uuid: uuid(),
    type: "extra",
    title: "Červené víno",
    descr: "Vinárstva z malokarpatského kraja",
    volume: "",
    price: "",
    noDescr: false,
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Frankovka modrá",
    descr: "r. 2021, suché",
    volume: "0,1 l",
    price: "2,90 €",
    expandableDescr: [
      "Víno rubínovo-červenej farby s vôňou čerešní.",
      "Chuť ovocná, stredne plná."
    ],
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Alibernet",
    descr: "r. 2018, suché",
    volume: "0,1 l",
    price: "3,90 €",
    expandableDescr: [
      "Víno intenzívnej fialovočervenej farby s vôňou černíc a moruše čiernej.",
      "Plnú chuž s tónmi čierneho bobuľového ovocia umocnil dvojročný pobyt v barikových sudoch."
    ],
    divideAfter: true
  },
  {
    item_uuid: uuid(),
    title: "Prosecco",
    descr: "",
    volume: "0,1 l / 1 fľ.",
    price: "3,60 € / 19,10 €",
    divideAfter: true
  },
  {
    type: "extra",
    icon: FaInfoCircle,
    iconColor: "#201F1D",
    iconSize: "25px",
    item_uuid: uuid(),
    title: "Pri objednávke so sebou",
    descr: `
    Účtujeme si poplatok za obaly v sume<br/>
    - 0,10 € - pohár<br/>
    - 0,60 € - malá krabička<br/>
    - 1,10 € - veĺká krabica<br/>`,
    volume: "",
    price: "",
    noDescr: false,
    divideAfter: false,
    lastElement: false
  }
]