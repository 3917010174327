import { IoMdAdd } from "react-icons/io";
import { FaQuestionCircle } from "react-icons/fa";

const HowToCreateElement = () => {
  return (
    <div className="how_to_element_wrap">
      <div className="transparent_card">
        <div className="subtitle align-center">
          <FaQuestionCircle size={"30px"} style={{marginRight: "10px"}} />
          Ako vytvoriť položku v sekcii ?
        </div>
      </div>
      <div className="card how_to">
        <div className="text">
          1. Na ľavej strane v menu sa prepnite do <b>Editácia ponuky</b>
        </div>
        <div className="text align-center">
          2. Vyberte si sekciu v pravom hornom rohu, naľavo od tlačidla <IoMdAdd size={"24px"} style={{marginLeft: "5px"}} />
        </div>
        <div className="text align-center">
          3. V pravom <b>&nbsp;dolnom&nbsp;</b> rohu zakliknite tlačidlo <IoMdAdd size={"24px"} style={{marginLeft: "5px"}} /> vypĺňte formulár a zakliknite tlačidlo Uložiť položku
        </div>{/*
        <div style={{fontSize: "12px"}}>
          pridať popis čo ktorý field znamená
        </div> */}
      </div>
    </div>
  )
}

export default HowToCreateElement