import NotAvailableComponent from "./NotAvailableComponent"

const MobileViewCard = ({ children }: { children?: React.ReactNode }) => {
  return (
    <div className="mobile_view_card">
      <div className="mobile_frame">

        <div className="sound_button"></div>
        <div className="sound_button"></div>
        <div className="lock_button"></div>

        <div className="camera_cut_wrap">
          <div className="camera_cut">
            <div className="speaker"></div>
            <div className="camera"></div>
          </div>
        </div>

        <div className="mobile_viewport">
          <div id="MOBILE_VIEWPORT">{children}</div>
        </div>
      </div>
    </div>
  )
}

export default MobileViewCard

