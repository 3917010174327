import uuid from "react-uuid"

import { FaInfoCircle } from "react-icons/fa";
export const pivo = [
  {
    item_uuid: uuid(),
    type: "extra",
    title: "Remeselné pivo z pivovaru Kaltenecker v Rožňave",
    descr: "",
    volume: "",
    price: "",
    noDescr: true,
    divideAfter: true
  },
  {
    item_uuid: uuid(),
    title: "Kaltenecker 11°",
    descr: "nefiltrovaný, ľahký, svieži ležiak",
    volume: "330 ml",
    price: "3,55 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Kaltenecker Brokát 13°",
    descr: "tmavý ležiak, príjemne horká chuť, s tónmi horkej čokolády a kávy",
    volume: "330 ml",
    price: "3,85 €",
    divideAfter: true
  },
  {
    type: "extra",
    icon: FaInfoCircle,
    iconColor: "#201F1D",
    iconSize: "25px",
    item_uuid: uuid(),
    title: "Pri objednávke so sebou",
    descr: `
    Účtujeme si poplatok za obaly v sume<br/>
    - 0,10 € - pohár<br/>
    - 0,60 € - malá krabička<br/>
    - 1,10 € - veĺká krabica<br/>`,
    volume: "",
    price: "",
    noDescr: false,
    divideAfter: false,
    lastElement: false
  }
]