import { IoMdAdd } from "react-icons/io";
import { CreateElementButtonProps } from "../../../PropTypes/AdminDashboardProps";
import { useEffect, useState } from "react";

const CreateElementButton = (props: CreateElementButtonProps) => {
  const [isSpacePressed, setIsSpacePressed] = useState(false);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.ctrlKey && event.code === "Space") {
        setIsSpacePressed(true);
      }
    };

    const handleKeyUp = (event: KeyboardEvent) => {
      if (event.code === "Space" || event.key === "Control") {
        setIsSpacePressed(false);
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("keyup", handleKeyUp);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("keyup", handleKeyUp);
    };
  }, []);

  return (
    <div
      className={`create_element_button_wrap ${
        !props.isEditView && "create_element_button_hidden"
      }`}
      id="CREATE_ELEMENT_BUTTON_ID"
      style={{
        transform: isSpacePressed ? "translateX(300px)" : "translateX(0px)"
      }}
    >
      <span>Vytvoriť položku</span>
      <div className="create_element_button">
        <IoMdAdd
          onClick={() => {
            props.onClick();
          }}
          className="icon"
          size={"35px"}
        />
      </div>
    </div>
  );
};

export default CreateElementButton;
