import uuid from "react-uuid"
import {
  FaWineBottle,
  FaWineGlassAlt,
} from "react-icons/fa"
import{
  FaGlassCheers
} from "react-icons/fa"

export const vino = [
  {
    type: "title",
    icon: FaWineBottle,
    iconSize: "45px",
    item_uuid: uuid(),
    title: "Biele víno",
    descr: "",
    volume: "",
    price: "",
    divideAfter: true,
    lastElement: false
  },
  {
    item_uuid: uuid(),
    title: "Heideboden",
    descr: "Suché 12 % vol. alk. 2018",
    volume: "0,7 l",
    price: "15,00 €",
    divideAfter: false,
    lastElement: false
  },
  {
    item_uuid: uuid(),
    title: "Weissburgunder",
    descr: "Polosuché 12,5 % vol. alk. 2017",
    volume: "0,7 l",
    price: "15,00 €",
    divideAfter: false,
    lastElement: false
  },
  {
    item_uuid: uuid(),
    title: "Gemischter Satz",
    descr: "Polosuché 11,5 / vol. alk. 2018",
    volume: "0,7 l",
    price: "15,00 €",
    divideAfter: false,
    lastElement: false
  },
  {
    item_uuid: uuid(),
    title: "Muskat Ottonel \"Töchterl\"",
    descr: "Polosladké 10,5 % vol. alk. 2015",
    volume: "0,7 l",
    price: "15,00 €",
    divideAfter: false,
    lastElement: false
  },
  {
    item_uuid: uuid(),
    title: "Sämling 88 Spätlese",
    descr: "Sladké 10% vol. alk. 2015",
    volume: "0,7 l",
    price: "15,00 €",
    divideAfter: false,
    lastElement: false
  },
  {
    item_uuid: uuid(),
    title: "Cuvée Spätlese",
    descr: "Sladké 10 % vol. alk. 2017",
    volume: "0,7 l",
    price: "15,00 €",
    divideAfter: true,
    lastElement: false
  },
  {
    type: "title",
    icon: FaWineBottle,
    iconSize: "45px",
    item_uuid: uuid(),
    title: "Ružové víno",
    descr: "",
    volume: "",
    price: "",
    divideAfter: true,
    lastElement: false
  },
  {
    item_uuid: uuid(),
    title: "Pink Kiss Rose",
    descr: "Polosuché 12 % vol. alk. 2017",
    volume: "0,7 l",
    price: "15,00 €",
    divideAfter: true,
    lastElement: false
  },
  {
    type: "title",
    icon: FaWineBottle,
    iconSize: "45px",
    item_uuid: uuid(),
    title: "Červené víno",
    descr: "",
    volume: "",
    price: "",
    divideAfter: true,
    lastElement: false
  },
  {
    item_uuid: uuid(),
    title: "Golser No Zweigelt",
    descr: "Polosuché 12,5 vol. alk. 2017",
    volume: "0,7 l",
    price: "15,00 €",
    divideAfter: false,
    lastElement: false
  },
  {
    item_uuid: uuid(),
    title: "Merlot - Cabernet Spätlese",
    descr: "Sladké 10% vol. alk. 2017",
    volume: "0,7 l",
    price: "15,00 €",
    divideAfter: true,
    lastElement: false
  },
  // VINO PODLA PONUKY ROZLIEVANE

  {
    type: "title",
    icon: FaWineGlassAlt,
    iconSize: "45px",
    item_uuid: uuid(),
    title: "Rozlievané víno",
    descr: "",
    volume: "",
    price: "",
    divideAfter: true,
    lastElement: false
  },
  {
    item_uuid: uuid(),
    title: "Biele víno suché",
    descr: "Chardonnay",
    volume: "0,1 l",
    price: "1,90 €",
    divideAfter: false,
    lastElement: false
  },
  {
    item_uuid: uuid(),
    title: "Červené víno polosuché",
    descr: "Zweigelt",
    volume: "0,1 l",
    price: "1,90 €",
    divideAfter: false,
    lastElement: false
  },
  {
    item_uuid: uuid(),
    title: "Prosecco",
    descr: "11 %",
    volume: "0,1 l",
    price: "2,20 €",
    divideAfter: true,
    lastElement: false
  },

  {
    type: "title",
    icon: FaGlassCheers,
    iconSize: "45px",
    item_uuid: uuid(),
    title: "Šampanské / Prosecco",
    descr: "",
    volume: "",
    price: "",
    divideAfter: true,
    lastElement: false
  },
  {
    item_uuid: uuid(),
    title: "Prosecco Mionetto",
    descr: "11,50 %",
    volume: "0,75 l",
    price: "18,00 €",
    divideAfter: false,
    lastElement: false
  },
  {
    item_uuid: uuid(),
    title: "Prosecco Martiny",
    descr: "11,50 %",
    volume: "0,75 l",
    price: "25,00 €",
    divideAfter: false,
    lastElement: false
  },
  {
    item_uuid: uuid(),
    title: "Prosecco Martiny Rosé",
    descr: "11,50 %",
    volume: "0,75 l",
    price: "25,00 €",
    divideAfter: false,
    lastElement: false
  },
  {
    item_uuid: uuid(),
    title: "Šampanské Hubert De Luxe",
    descr: "7,00 %",
    volume: "0,75 l",
    price: "15,00 €",
    divideAfter: false,
    lastElement: false
  },
  {
    item_uuid: uuid(),
    title: "Šampanské Hubert Club Brut",
    descr: "11,50 %",
    volume: "0,75 l",
    price: "15,00 €",
    divideAfter: false,
    lastElement: false
  },
  /* {
    item_uuid: uuid(),
    title: "Šampanské Hubert Grand",
    descr: "(Medium Dry, Rosé Medium Dry)",
    volume: "0,75 l",
    price: "15,00 €",
    divideAfter: false,
    lastElement: false
  }, */
  {
    item_uuid: uuid(),
    title: "Šampanské Moët & Chadon Brut Impérial",
    descr: "12 %",
    volume: "0,75 l",
    price: "90,00 €",
    divideAfter: false,
    lastElement: false
  },
]