import uuid from "react-uuid"
import new_flag from "../../../assets/images/clients/icons/panorama/new_flag.png"
import recommended_flag from "../../../assets/images/clients/icons/panorama/recommended_flag.png"
import { BsCreditCard2BackFill } from "react-icons/bs"

export const nealko = [

  {
    item_uuid: uuid(),
    title: "Cappy",
    descr: "/&nbspjahoda, multivitamín, pomaranč 100%&nbsp/",
    volume: "0,25 l",
    price: "2,20 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Coca-Cola",
    descr: "",
    volume: "0,20 l",
    price: "2,20 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Coca-Cola Zero",
    descr: "",
    volume: "0,20 l",
    price: "2,20 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Fanta",
    descr: "/&nbsppomaranč&nbsp/",
    volume: "0,20 l",
    price: "2,20 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Fuzetea",
    descr: "",
    volume: "0,25 l",
    price: "2,20 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Kinley",
    descr: "/&nbsptonic, aromatic berry, ginger ale&nbsp/",
    volume: "0,25 l",
    price: "2,20 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Römerquelle",
    descr: "/&nbspsýtená, jemne sýtená, nesýtená&nbsp/",
    volume: "0,33 l",
    price: "2,10 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Römerquelle",
    descr: "/&nbspcitrónová tráva&nbsp/",
    volume: "0,33 l",
    price: "2,30 €",
    divideAfter: false
  },



/*
  {
    item_uuid: uuid(),
    title: "Mattoni",
    descr: "/&nbspjemne perlivá&nbsp/",
    volume: "0,75 l",
    price: "3,10 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Mattoni",
    descr: "/&nbspneperlivá, jemne perlivá, perlivá&nbsp/",
    volume: "0,33 l",
    price: "1,80 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Mattoni Cedrata",
    descr: "",
    volume: "0,33 l",
    price: "1,90 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Pepsi Cola / Pepsi Max",
    descr: "",
    volume: "0,25 l",
    price: "1,90 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Schweppes",
    descr: "/&nbspIndian Tonic, Ginger Ale, Pink Tonic&nbsp/",
    volume: "0,25 l",
    price: "1,90 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Lipton",
    descr: "/&nbspzelený čaj, broskyňa&nbsp/",
    volume: "0,25 l",
    price: "1,90 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Granini džús",
    descr: "/&nbsppodľa ponuky&nbsp/",
    volume: "0,20 l",
    price: "1,90 €",
    divideAfter: false
  }, */
  {
    item_uuid: uuid(),
    title: "Vinea",
    descr: "/&nbspbiela, červená&nbsp/",
    volume: "0,25 l",
    price: "2,20 €",
    divideAfter: false
  },
  /* {
    item_uuid: uuid(),
    title: "Fentimans",
    descr: "/&nbspTonic Water, Tonic Water Light&nbsp/",
    volume: "0,45 l",
    price: "2,30 €",
    divideAfter: false
  }, */
  /* {
    item_uuid: uuid(),
    title: "Fentimans Lemonade",
    descr: "/&nbsppodľa ponuky&nbsp/",
    volume: "0,275 l",
    price: "3,30 €",
    divideAfter: false
  }, */
  {
    item_uuid: uuid(),
    title: "Redbull",
    descr: "",
    volume: "0,25 l",
    price: "3,10 €",
    divideAfter: true
  },
  {
    type: "extra",
    icon: BsCreditCard2BackFill,
    iconSize: "25px",
    item_uuid: uuid(),
    title: "Možná platba kartou",
    descr: "",
    volume: "",
    price: "",
    noDescr: true,
    divideAfter: false,
    lastElement: true
  },
 /*  {
    item_uuid: uuid(),
    title: "Stego 100% Lemonade",
    descr: "/&nbsppodľa ponuky&nbsp/",
    volume: "0,45 l",
    price: "2,50 €",
    divideAfter: false
  }, */

]